<template>
    <div>
        <Button :label="label" @click="onClick" class="button button-outlined" :disabled="disabled"/>
    </div>
</template>

<script>
    import Button from "primevue/button";

    export default {
        name: "DeleteButton",
        props: {
            onClick: {
                type: Function,
            },
            label: {
                type: String,
                default: "USUŃ KONTO",
                required: false,
            },
            disabled: {
                type: Boolean,
                default: false,
                required: false,
            },
        },
        components: {Button},
    };
</script>

<style lang="less" scoped>
.delete-account-button-container {
    margin-top: 100px;
}
</style>
