<template>
    <div class="organization-list">
        <DataTable ref="OrganizationTable" :value="organizations" :lazy="true" :paginator="true"
                   v-model:rows="searchParams.page.limit" v-model:first="searchParams.page.offset"
                   removableSort :totalRecords="totalRecords" :loading="loading" @page="onPage"
                   @sort="onPage" :rowsPerPageOptions="[5,10,20,50]">
            <Column field="name" header="Logo">
                <template #body="slotProps">

                    <img v-if="slotProps.data.logoUrl !== null" :alt="`logo organizacji`" class="organization-list-logo"
                         :src="slotProps.data.logoUrl"/>
                </template>
            </Column>
            <Column field="name" header="Nazwa" :sortable="true">
                <template #body="slotProps">
                    <router-link :to="{name: 'organization', params: {'id': slotProps.data.id}}"
                                 style="text-decoration: none;">
                        <Button class="p-button-outlined">
                            {{ slotProps.data.name }}
                        </Button>
                    </router-link>
                </template>
            </Column>
            <Column field="contact" header="Kontakt"></Column>
            <Column field="email" header="Email" :sortable="true"></Column>
            <Column field="phoneNumber" header="Telefon" :sortable="true"></Column>
            <Column field="accepted" header="zatwierdzona" :sortable="true">
                <template #body="slotProps">
                    <span v-if="slotProps.data.accepted">tak</span>
                    <span v-else>nie</span>
                </template>
            </Column>
            <Column field="id" header="ID" :sortable="true"></Column>
            <Column header="Edycja">
                <template #body="slotProps">
                    <router-link :to="{name: 'organizationEdit', params: {'id': slotProps.data.id}}">
                        <Button icon="pi pi-pencil"
                                class="p-button-rounded p-button-text p-button-lg add-user-button"/>
                    </router-link>
                    <span/>
                </template>
            </Column>
            <template #empty>
                Nie znaleziono członków organizacji.
            </template>
        </DataTable>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {
        searchOrganizationCountUsingPOST as searchOrganizationCount,
        searchOrganizationUsingPOST as searchOrganization,
    } from "@/swagger/vue-api-client";

    export default {
        name: "OrganizationTable",
        components: {DataTable, Column, Button},

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        emits: ["update:searchCriteria"],

        data() {
            return {
                first: 0,
                totalRecords: 0,
                organizations: [],
            };
        },

        computed: {
            searchParams: {
                get() {
                    return this.searchCriteria;
                },
                set(value) {
                    this.$emit("update:searchCriteria", value);
                },
            },
        },

        mounted() {
            this.updateTotalRecords();
            this.onPage(this.getFirstPage());
        },

        beforeMount() {
            this.totalRecords = this.organizations.length;
        },

        methods: {

            search() {
                this.onPage(this.getFirstPage());
                this.updateTotalRecords();
            },

            onPage(event) {
                this.loading = true;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = event.sortField;
                this.searchParams.page.sortOrder = event.sortOrder;

                searchOrganization({searchCriteria: this.searchCriteria}).then((response) => {
                    this.organizations = response.data;
                    this.loading = false;
                });
            },

            updateTotalRecords() {
                searchOrganizationCount({searchCriteria: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                });
            },

            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                };
            },
        },

    };
</script>

<style lang="less">
@import "src/assets/theme/variable";

.organization-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    button {
        font-size: 16px;
    }
    .organization-list-logo {
        max-width: 150px;
    }
}

.p-paginator {
    display: flex;
    justify-content: center;
}
</style>
