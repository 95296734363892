<template>
    <div class="p-fluid custom-input-text" :style="'width: ' + width + 'px;'">
        <span v-if="noIcon" class="p-float-label">
            <InputText :id="name" type="text" v-model="internalValue" :disabled="disabled"
                       :class="computedClasses" class="input" @input="handleChange" @blur="handleBlur"
                       :placeholder="internalValue ? '' : label" :aria-label="name"/>
        </span>
        <span v-if="!noIcon" class="p-float-label p-input-icon-left input-outer-icon">
            <i :class="icon" />
            <InputText :id="name" type="text" v-model="internalValue" :disabled="disabled"
                       :class="computedClasses + 'input'" @input="handleChange" @blur="handleBlur"
                       :placeholder="internalValue ? '' : label" :aria-label="name"/>
        </span>
        <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
    </div>
</template>

<script>
    import InputText from "primevue/inputtext";
    import Tooltip from "primevue/tooltip";
    import {useField} from "vee-validate";

    export default {
        name: "CustomInputText",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            icon: {
                type: String,
                default: "pi pi-search",
            },
            noIcon: {
                type: Boolean,
                default: false,
            },
            width: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            rules: undefined,
            modelValue: null,
        },

        emits: ["update:modelValue"],

        directives: {
            tooltip: Tooltip,
        },

        watch: {
            modelValue() {
                this.inputValue = this.modelValue;
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },

            computedClasses() {
                return this.errorMessage ? "p-invalid" : "";
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

        components: {InputText},
    };
</script>

<style lang="less" scoped>
    @import "../../assets/theme/variable";

    .custom-input-text {

        .p-float-label input:focus ~ label,
        .p-float-label input.p-filled ~ label {
            background: unset;
            color: @color-gray3;
            margin-bottom: 35px;
        }

        .p-float-label > label {
            margin-bottom: 14px;
        }

        .pi {
            font-size: 20px;
        }
    }
</style>
