<template>
    <div class="first-paragraph user-list-view" >
        <div class="user-list-view-header">
            <div class="user-list-view-header-text">
                <CustomTitle title="Użytkownicy"/>
            </div>
        </div>
        <div class="general-container user-list-view-table">
            <div class="add-user">
                <Button icon="pi pi-plus" @click="createUser" label="Dodaj użytkownika"
                        class="button button-filled add-user-button" />
            </div>
            <UserSearchCriteria ref="userSearchCriteria" v-model="searchCriteria"/>
            <div class="user-list-view-buttons">
                <Button label="Szukaj" icon="pi pi-search" class="button button-filled" @click="search"/>
                <Button label="Wyczyść" icon="pi pi-times" class="button button-outlined" @click="clear"/>
            </div>
            <UserTable ref="userTable" :search-criteria="searchCriteria" />
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Tooltip from "primevue/tooltip";
    import Dialog from "primevue/dialog";
    import {SystemRole} from "../../utils/SystemRole";
    import {SearchCriteria} from "../../utils/SearchCriteria";
    import UserSearchCriteria from "./components/UserSearchCriteria";
    import UserTable from "./components/UserTable";
    import CustomTitle from "@/components/CustomTitle";

    export default {
        name: "UserListView",
        components: {
            CustomTitle,
            UserTable,
            UserSearchCriteria,
            Button,
            Dialog,
        },

        directives: {
            tooltip: Tooltip,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
                displayFiltersDialog: false,
                Role: SystemRole,
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);
                this.$refs.userTable.search();
                this.displayFiltersDialog = false;
            },

            createUser() {
                this.$router.push({name: "userCreate"});
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
            },

            getClearSearchCriteria() {
                return {
                    firstName: "",
                    lastName: "",
                    email: "",
                    phoneNumber: "",
                    emailVerified: null,
                    userDistricts: undefined,
                    favoriteActivitiesIds: undefined,
                    systemRole: "USER",
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
            openFiltersDialog() {
                this.displayFiltersDialog = true;
            },
            closeFiltersDialog() {
                this.displayFiltersDialog = false;
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "src/views/assets/less/user-list-view";
    .add-user {
        margin-bottom: 50px;
    }
</style>
