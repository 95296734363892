<template>
    <div class="organization-search-criteria md:flex xs:flex-wrap" @keyup.enter="search">
        <CustomInputText name="eventName" label="Nazwa wydarzenia" v-model="internalValue.eventName" fluid width="250"/>
        <CustomInputText name="organizationName" label="Nazwa organizacji"
                         v-model="internalValue.organizationName" width="250"/>
        <DesignedSelectEventStatus name="status" label="Status wydarzenia"
                                   v-model="internalValue.eventStatus" width="250"/>
    </div>
</template>

<script>
    import CustomInputText from "../../../components/form/CustomInputText";
    import DesignedSelectEventStatus from "@/components/form/selectOne/DesignedSelectEventStatus";

    export default {
        name: "EventEditionViewSearchCriteria",

        props: {
            modelValue: null,
        },

        methods: {
            search() {
                this.$emit("search");
            },
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        components: {DesignedSelectEventStatus, CustomInputText},
    };
</script>

<style lang="less" scoped>

</style>
