/* eslint-disable */
export const messagesPL = {
    login: {
        title: "Logowanie",
        email: "Email",
        password: "Hasło",
        homeReturn: "Powrót na stronę główną",
        action: {
            login: "Zaloguj się",
            remindPassword: "Nie pamiętasz hasła?",
            registerLabel: "Jesteś nowy?",
            register: "Zarejestruj się",
        },
    },
    register: {
        title: "Rejestracja",
        name: "Imię",
        surname: "Nazwisko",
        email: "Email",
        email2: "Powtórz email",
        phone: "Numer telefonu",
        password: "Hasło",
        password2: "Powtórz hasło",
        user: "Użytkownik",
        organizer: "Organizator",
        district: "Dzielnica",
        organization: "Nazwa organizacji",
        action: {
            register: "Zarejestruj się",
            chooseEventsLabel: "Wybierz co najmniej 3 aktywności, które Cię interesują",
            chooseDistrictsLabel: "Wybierz co najmniej 1 dzielnicę",
            chooseEvents: "Wybierz aktywności",
            loginLabel: "Masz już konto?",
            login: "Zaloguj się",
        },
    },
    passwordRemind: {
        title: "Przypomnienie hasła",
        email: "Email",
        action: {
            send: "Wyślij",
        },
    },
    menu: {
        users: "Użytkownicy",
    },
    other: {
        emptyTable: "Brak wyników",
    },
    validations: {
        required: "Pole jest wymagane",
        email: "Niepoprawny format adresu e-mail",
        emailLength: "Wprowadzony adres email jest za długi",
        addressLength: "Wprowadzony adres jest za długi",
        firstNameLength: "Wprowadzone imię jest za długie",
        lastNameLength: "Wprowadzone nazwisko jest za długie",
        contactPersonNameLength: "Wprowadzone dane kontaktowe są za długie",
        urlLength: "Wprowadzony adres strony jest za długi",
        eventNameLength: "Wprowadzona nazwa wydarzenia jest za długa",
        organizationNameLength: "Wprowadzona nazwa organizacji jest za długa",
        eventTypeNameLength: "Wprowadzona nazwa aktywności jest za długa",
        additionalInfoLength: "Wprowadzone informacje dodatkowe są za długie",
        confirmed: "Wartości nie są takie same",
        dateAfter: "Godzina zakończenia nie może być wcześniejszą od godziny rozpoczęcia",
        min: "Wymagana długość: 0:{min}",
        max: "Maksymalna długość: 0:{max}",
        min_value: "Minimalna wartość: 0:{min_value}",
        max_value: "Maksymalna wartość: 0:{max_value}",
        is: "",
        regex: "",
        validPassword: "Hasło musi mieć długość co najmniej 8 znaków, zawierać małą i dużą literę, cyfrę, oraz znak specjalny",
        validOptionalPassword: "Hasło musi mieć długość co najmniej 8 znaków, zawierać małą i dużą literę, cyfrę, oraz znak specjalny",
        url: "Niepoprawny format adresu url. Stronę należy podać w formacie http://strona.com/",
        badWords: "Pole zawiera w sobie słowa obraźliwe",
        size: "Zbyt duży rozmiar pliku. Maksymalny rozmiar pliku to: ",
    },
};
