<template>
    <div>
        <div class="confirm-email-panel-header">
<!--TODO logo            <img src="../assets/UActiv_logo/PION/UACTIV_PION_kolor-RGB.png" alt="logo"/>-->
        </div>
        <div class="confirm-email-panel-content">
            <div v-if="!verificationCompleted" class="center">
                <div>
                    <div>
                        <progress-spinner/>
                    </div>
                    <div>Proszę czekać...</div>
                </div>
            </div>
            <div v-else style="height: 100%" class="center">
                <div v-if="emailAlreadyVerified" >
                    <div class="thankYouRegistration">To konto zostało już aktywowane</div>
                    <br/>
                    <router-link :to="{name: 'login'}">Zaloguj się</router-link>
                </div>
                <div v-else>
                    <div class="thankYouRegistration">Dziękujemy za rejestrację</div>
                    <br/>
                    <router-link :to="{name: 'login'}">Zaloguj się</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ProgressSpinner from "primevue/progressspinner";
    import {verifyEmailUsingPOST} from "@/swagger/vue-api-client";

    export default {
        name: "ConfirmEmailView",
        components: {
            ProgressSpinner,
        },
        data() {
            return {
                token: this.$route.query.verificationToken,
                verificationCompleted: false,
                emailAlreadyVerified: false,
            };
        },
        mounted() {
            verifyEmailUsingPOST({token: this.token}).then((response) => {
                this.emailAlreadyVerified = response.data.emailAlreadyVerified;
                this.verificationCompleted = true;
            }).catch(() => {
                this.$router.push({name: "error"});
            });
        },
    };
</script>

<style lang="less" scoped>
@import "assets/less/confirm-email.less";
    .center {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    .thankYouRegistration {
        font-size: 40px;
    }
</style>
