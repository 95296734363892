<template>
    <div class="p-fluid custom-input-text">
        <label class="input-text-label" :for="name">{{ label }}</label>
        <Editor v-model="internalValue" :editorStyle="heightStyle" :disabled="disabled" :id="name">
            <template #toolbar>
                <span class="ql-formats">
                    <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                    <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                    <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                    <select class="ql-header" v-tooltip.bottom="'Header'"></select>
                    <select class="ql-color" v-tooltip.bottom="'Color'"></select>
                    <select class="ql-background" v-tooltip.bottom="'Background'"></select>
                    <button class="ql-list" value="ordered"></button>
                    <button class="ql-list"  value="bullet"></button>
                    <select class="ql-align" v-tooltip.bottom="'Align'"></select>
                    <button class="ql-link" ></button>
                </span>
            </template>
        </Editor>
        <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
    </div>
</template>

<script>
    import Editor from "primevue/editor";
    import Tooltip from "primevue/tooltip";
    import {useField} from "vee-validate";

    export default {
        name: "CustomEditor",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            height: {
                type: Number,
                default: 320,
                required: false,
            },
            rules: undefined,
            modelValue: null,
        },

        emits: ["update:modelValue"],

        directives: {
            tooltip: Tooltip,
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.handleChange(value);
                    this.$emit("update:modelValue", value);
                },
            },

            computedClasses() {
                return this.errorMessage ? "p-invalid" : "";
            },

            heightStyle() {
                return "height: " + this.height + "px";
            },
        },
        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

        components: {Editor},
    };
</script>

<style lang="less">
    @import "../../assets/theme/variable";

    .custom-input-text {
        width: 100%;
        margin-bottom: 10px;
        text-align: left;
    }
    .input-text-label {
        margin-left: 0.5em;
        font-weight: 300;
        color: rgba(0, 0, 0, 0.7);
        font-size: 18px;
    }
    .ql-editor{
        font-size: 18px;
        strong {
            font-weight: bold;
        }
    }
</style>
