<template>
    <div class="form-view form-readonly-view" v-if="loaded">
        <Form v-slot="{ validate, errors }">
            <div class="form-readonly-view-header">
                <div class="title-container">
                    <div class="title-text">
                        {{create ? "Tworzenie aktywności" : "Edycja aktywności"}}
                    </div>
                    <Button label="Zapisz" @click="validate(); resetPublishAndSaveEvent(validate);"
                            class="button button-filled"/>
                    <Button v-if="create || event.status === 'NEW'"
                            label="Zapisz i Opublikuj" @click="validate(); saveAndPublishEvent(validate);"
                            class="button button-filled"/>
                    <Button v-if="!create" label="Podgląd" class="button button-filled"
                            icon="pi pi-eye" @click="goToEventView"/>
                </div>
                <div class="header-text">
                    <CustomInputText name="name" v-model="event.name" noIcon
                                     label="Nazwa wydarzenia"
                                     width="500"
                                     v-tooltip.top="'Nazwa wydarzenia'"
                                     rules="required|eventNameLength|badWords"/>
                </div>
            </div>
            <div class="general-container">
                <div class="form-readonly-view-left">
                    <span v-if="Role.isOrganizer() && event.status === 'BLOCKED'" class="p-invalid">
                        To wydarzenie zostało zablokowane przez administratora
                    </span>
                    <div class="data">
                        <div class="row-outer-short-info">
                            <div class="row" v-if="Role.isAdmin()">
                                <div class="row-text">
                                    <div class="label">Promocja</div>
                                    <div class="value">
                                        <CustomCheckbox name="promoted" v-model="event.promoted" class="checkbox"
                                                        label="wydarzenie promowane"
                                                        style="margin-top: 14px; font-size:16px"/>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="Role.isAdmin()">
                                <div class="row-text">
                                    <div class="label">Organizator</div>
                                    <DesignedSelectOrganization name="organization" label="organizator wydarzenia"
                                                                v-model="event.organizationId" width="500"
                                                                rules="required"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="label">Typ aktywności</div>
                                <DesignedSelectOneEventType v-model="event.eventTypeId" label="typ aktywności"
                                                            name="eventType" width="500" rules="required"/>
                            </div>
                            <div class="row">
                                <div class="label">Strona</div>
                                <CustomInputText name="page" v-model="event.eventPageUrl" no-icon
                                                 label="adres strony internetowej" width="500"
                                                 rules="required|urlLength|url"/>
                            </div>
                            <div class="row">
                                <div class="label">Kontakt</div>
                                <CustomInputText name="contact" v-model="event.contactPersonName" no-icon
                                                 label="osoba kontaktowa" width="500"
                                                 rules="contactPersonNameLength|badWords"/>
                            </div>
                            <div class="row">
                                <div class="label">Email</div>
                                <CustomInputText name="email" v-model="event.email" no-icon
                                                 label="kontaktowy adres email" width="500"
                                                 rules="email|emailLength|badWords"/>
                            </div>
                            <div class="row">
                                <div class="label">Telefon</div>
                                <CustomInputMask name="phoneNumber" mask="(+48) 999 999 999" v-model="event.phoneNumber"
                                                 label="telefon kontaktowy" width="500"
                                                 rules="phoneNumberLength"/>
                            </div>
                            <div class="row" v-if="!create">
                                <div class="label">Status</div>
                                <DesignedSelectEventStatus name="status" label="status wydarzenia"
                                                           v-model="event.status" width="500"
                                                           :disabled="Role.isOrganizer()
                                                               && event.status === 'IMPORTED'"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="label">Termin</div>
                            <div class="many-values">
                                <DesignedSelectFrequency name="frequency" v-model="event.periodical"
                                                         label="częstotliwość" width="200"
                                                         :rules="computedFrequencyRules"
                                                         :disabled="!create"/>
                                <CustomCalendar v-if="!event.periodical" name="startDate"
                                                v-model="event.startDate" label="data rozpoczęcia"
                                                width="250"
                                                rules="required" :showTime="true"/>
                                <CustomCalendar v-if="!event.periodical" name="endDate"
                                                v-model="event.endDate" label="data zakończenia"
                                                width="250" rules="required|dateAfter:@startDate"
                                                :showTime="true"/>
                                <div v-else class="flex flex-column">
                                    <DesignedSelectWeekDay style="width:100%" name="dayWeekNew"
                                                           v-model="calendarForm.dayWeek"
                                                           label="dzień tygodnia" width="200"/>
                                    <DesignedSelectWeekDay v-for="(item, index) in event.calendarEntry"
                                                           :dropdown-class="'width90'"
                                                           :name="'day-week-' + index" :key="'day-week-' + index"
                                                           v-model="item.dayWeek"
                                                           label="dzień tygodnia" width="200"
                                                           rules="required"/>
                                </div>
                                <div v-if="event.periodical" class="flex flex-column">
                                    <CustomSelectTime name="timeBeginNew" v-model="calendarForm.timeBegin"
                                                      label="godzina rozpoczęcia" width="200"/>
                                    <CustomSelectTime v-for="(item, index) in event.calendarEntry"
                                                      :name="'time-begin' + index" :key="'time-begin' + index"
                                                      v-model="item.timeBegin"
                                                      label="godzina rozpoczęcia" width="200"
                                                      rules="required"/>
                                </div>
                                <div v-if="event.periodical" class="flex flex-column">
                                    <CustomSelectTime name="timeEndNew" v-model="calendarForm.timeEnd"
                                                      label="godzina zakończenia" width="200"/>
                                    <CustomSelectTime v-for="(item, index) in event.calendarEntry"
                                                      :name="'time-end' + index" :key="'time-end' + index"
                                                      v-model="item.timeEnd"
                                                      label="godzina zakończenia" width="200"
                                                      :rules="'required|dateAfter:@time-begin' + index"/>
                                </div>
                                <div v-if="event.periodical" class="button flex flex-column">
                                    <Button label="Dodaj termin" @click="addNewDate"
                                            class="button ml-2 button-filled"/>
                                    <Button v-for="(item, index) in event.calendarEntry"
                                            :key="'button-remove' + index"
                                            label="Usuń" @click="removeDate(index)"
                                            class="button ml-2 button-filled"/>
                                </div>
                                <div class="flex flex-column" v-if="displayEmptyCalendarEntryMessage">
                                    <span class="p-invalid">Należy dodać co najmniej jeden termin
                                        przez wypełnienie pól i naciśnięcie przycisku "Dodaj termin"</span>
                                </div>
                            </div>
                            <div class="many-values" v-if="event.periodical">
                                <div class="label">Czas trwania od:</div>
                                <CustomCalendar name="periodicalStartDate"
                                                v-model="event.periodicalStartDate"
                                                label="data rozpoczęcia"
                                                width="250"
                                                rules="required" :showTime="false"
                                                v-tooltip.top="'Początkowa data okresu w którym' +
                                                    'wydarzenie odbywa się cyklicznie'"/>
                                <div class="label">do:</div>
                                <CustomCalendar name="periodicalEndDate"
                                                v-model="event.periodicalEndDate"
                                                label="data zakończenia"
                                                width="250"
                                                rules="required"
                                                :showTime="false"
                                                v-tooltip.top="'Końcowa data okresu w którym' +
                                                    'wydarzenie odbywa się cyklicznie'"/>
                            </div>

                            <div class="label">Regulamin</div>
                            <div class="many-values">
                                <CustomInputText name="conditions1" v-model="event.termsFirstUrl"
                                                 no-icon
                                                 label="link do regulaminu" width="500"
                                                 rules="urlLength|url"/>
                                <CustomInputText name="conditions2" v-model="event.termsSecondUrl"
                                                 no-icon
                                                 label="drugi link do regulaminu (opcjonalnie)"
                                                 width="400"
                                                 rules="urlLength|url"/>
                            </div>

                            <div class="label">Dzielnica</div>
                            <DesignedSelectDistrict v-model="event.district" name="district"
                                                    label="dzielnica" width="500" rules="required"/>
                        </div>
                        <div v-if="event.district !== 'ONLINE'">
                            <div class="row">
                                <div class="label">Adres</div>
                                <div class="form-view-address">
                                    <CustomInputText name="address" v-model="event.address" no-icon
                                                     label="adres i wskazówki lokalizacyjne" width="500"
                                                     rules="required|addressLength|badWords" @keyup.enter="showOnMap"/>
                                    <div class="search-button">
                                        <Button label="Znajdź na mapie" @click="showOnMap"
                                                class="button ml-2 button-filled" />
                                    </div>
                                    <div class="selected-coordinates"
                                         v-if="event.latitude !== undefined && event.longitude !== undefined">
                                        <div>Wybrane koordynaty:</div>
                                        <div>
                                            {{computedCoordinates}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="event.latitude && event.longitude" class="row">
                                <div class="change-map-marker-label">
                                    <div>Jeśli pinezka źle wskazuje adres, wybierz miejsce klikając na mapie.</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="label">Współrzędne geograficzne</div>
                                <div class="form-view-address">
                                    <CustomInputNumber v-model="event.latitude" mode="decimal"
                                                       :minFractionDigits="7" :maxFractionDigits="10"
                                                       name="lattitude-input" style="margin-right:10px"
                                                       width="160" suffix=" N" placeholder="szerokość"
                                                       rules="required"/>
                                    <CustomInputNumber v-model="event.longitude" mode="decimal"
                                                       :minFractionDigits="7" :maxFractionDigits="10"
                                                       name="longitude-input" width="160" suffix=" E"
                                                       placeholder="długość" rules="required"/>
                                </div>
                            </div>
                            <div class="map-container"><EventInputPlace :event="event"/></div>
                        </div>
                        <div class="row">
                            <div class="label" style="align-items: flex-start">Opis</div>
                            <CustomInputTextarea name="description" v-model="event.description"
                                                 label="opis wydarzenia" width="500"
                                                 rules="required|badWords" :autoResize="true"
                                                 :rows="15"/>
                        </div>
                        <div class="row">
                            <div class="label mb-2" style="align-items: flex-start">Zdjęcie główne</div>
                            <div v-if="browsedCoverImage.url !== null"
                                 class="upload-image__cropper-wrapper">
                                <Cropper :key="browsedCoverImage.url" ref="mainImageCropper"
                                         class="upload-image__cropper"
                                         check-orientation :src="browsedCoverImage.url"
                                         :default-size="defaultSize"
                                         :stencil-props="{
                                             aspectRatio: 185/213.3,
                                         }"/>
                                <div class="button-clear-photo"
                                     title="Zresetuj" @click="reset(true)">
                                    <i class="pi pi-trash"/>
                                </div>
                                <VerticalButtons>
                                    <SquareButton title="Flip Horizontal"
                                                  @click="flip(true, false, $refs.mainImageCropper)">
                                        <img class="upload-image__reset-button-size"
                                             :src="require('@/assets/icons/flip-horizontal.svg')" />
                                    </SquareButton>
                                    <SquareButton title="Flip Vertical"
                                                  @click="flip(false, true, $refs.mainImageCropper)">
                                        <img class="upload-image__reset-button-size"
                                             :src="require('@/assets/icons/flip-vertical.svg')" />
                                    </SquareButton>
                                    <SquareButton title="Rotate Clockwise"
                                                  @click="rotate(90, $refs.mainImageCropper)">
                                        <img class="upload-image__reset-button-size"
                                             :src="require('@/assets/icons/rotate-clockwise.svg')" />
                                    </SquareButton>
                                    <SquareButton title="Rotate Counter-Clockwise"
                                                  @click="rotate(-90, $refs.mainImageCropper)">
                                        <img class="upload-image__reset-button-size"
                                             :src="require('@/assets/icons/rotate-counter-clockwise.svg')" />
                                    </SquareButton>
                                </VerticalButtons>
                            </div>
                            <div class="upload-image__buttons-wrapper">
                                <input type="file" v-show="false" ref="mainImage"
                                       :accept="acceptedFileFormats"
                                       @change="loadImage($event, true)"
                                       @click="resetImageUploader($refs.mainImage)"/>
                                <Button class="button ml-2 button-filled" label="Dodaj"
                                        @click="$refs.mainImage.click()">
                                </Button>
                                <Button class="button ml-2 button-filled" label="Zapisz"
                                        v-if="browsedCoverImage.url"
                                        @click="cropAndSave($refs.mainImageCropper, true)"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="label" style="align-items: flex-start">Informacje dodatkowe</div>
                            <CustomInputTextarea name="additionalInfo" v-model="event.additionalInfo"
                                                 label="informacje dodatkowe" width="500"
                                                 rules="badWords|additionalInfoLength" :autoResize="true"
                                                 :rows="5"/>
                        </div>
                        <div class="row">
                            <div class="label" style="align-items: flex-start">Galeria</div>
                            <div :key="galleryImage" v-if="galleryImage.url"
                                 class="upload-image__cropper-wrapper">
                                <Cropper ref="galleryImageCropper" class="upload-image__cropper"
                                         check-orientation :src="galleryImage.url"/>
                                <div class="button-clear-photo"
                                     title="Zresetuj" @click="reset(false)">
                                    <i class="pi pi-trash"/>
                                </div>
                                <VerticalButtons>
                                    <SquareButton title="Flip Horizontal"
                                                  @click="flip(true, false, $refs.galleryImageCropper)">
                                        <img class="upload-image__reset-button-size"
                                             :src="require('@/assets/icons/flip-horizontal.svg')" />
                                    </SquareButton>
                                    <SquareButton title="Flip Vertical"
                                                  @click="flip(false, true, $refs.galleryImageCropper)">
                                        <img class="upload-image__reset-button-size"
                                             :src="require('@/assets/icons/flip-vertical.svg')" />
                                    </SquareButton>
                                    <SquareButton title="Rotate Clockwise"
                                                  @click="rotate(90, $refs.galleryImageCropper)">
                                        <img class="upload-image__reset-button-size"
                                             :src="require('@/assets/icons/rotate-clockwise.svg')" />
                                    </SquareButton>
                                    <SquareButton title="Rotate Counter-Clockwise"
                                                  @click="rotate(-90, $refs.galleryImageCropper)">
                                        <img class="upload-image__reset-button-size"
                                             :src="require('@/assets/icons/rotate-counter-clockwise.svg')" />
                                    </SquareButton>
                                </VerticalButtons>
                            </div>
                            <div>
                                <ProgressBar v-if="showProgressBar"
                                             mode="indeterminate" style="height: .5em" />
                            </div>
                            <div class="upload-image__buttons-wrapper">
                                <input type="file" v-show="false" ref="galleryImage"
                                       :accept="acceptedFileFormats"
                                       @change="loadImage($event, false)"
                                       @click="resetImageUploader($refs.galleryImage)"/>
                                <Button class="button ml-2 button-filled" label="Dodaj"
                                        @click="$refs.galleryImage.click()"/>
                                <Button v-if="galleryImage.url" class="button ml-2 button-filled"
                                        label="Zapisz"
                                        @click="cropAndSave($refs.galleryImageCropper, false)"/>
                            </div>
                            <Draggable :key="event.galleryFiles" ref="galleryImages"
                                       :sort="false"
                                       v-model="event.galleryFiles"
                                       class="grid justify-content-center gallery-item"
                                       item-key="id" tag="transition-group">
                                <template #item="{item}">
                                    <div class="container px-3 py-2 mx-auto lg:pt-12 lg:px-16">
                                        <div class="flex flex-wrap -m-1 md:-m-2">
                                            <div class="flex flex-wrap md:w-1/4 w-1/2">
                                                <div class="p-1 md:p-2">
                                                    <img class="img block object-cover object-center h-full rounded-lg"
                                                         alt="gallery" :src="item.url"/>
                                                    <div class="button-container row">
                                                        <div class="button-edit-photo " aria-label="Edytuj zdjęcie"
                                                             @click="editGalleryPhoto(item.id)">
                                                            <i class="pi pi-pencil"/>
                                                        </div>
                                                        <div class="row"></div>
                                                        <div class="button-edit-photo" aria-label="Usuń zdjęcie"
                                                             style="position: absolute;right:0;"
                                                             @click="deleteGalleryPhoto(item.id)">
                                                            <i class="pi pi-trash"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template #footer>
                                    <button @click="addPeople">Add</button>
                                </template>
                            </Draggable>
                        </div>
                    </div>
                    <div v-if="hasErrors(errors)" class="row">
                        <span class="p-invalid">Formularz zawiera błędy</span>
                    </div>
                </div>
                <div class="event-form-buttons">
                    <Button label="ZAPISZ" @click="validate(); resetPublishAndSaveEvent(validate);"
                            class="button button-filled"/>
                    <Button v-if="create || event.status == 'NEW'"
                            label="ZAPISZ I OPUBLIKUJ" @click="validate(); saveAndPublishEvent(validate);"
                            class="button ml-2 button-filled" />
                    <DeleteButton v-if="!create" label="USUŃ WYDARZENIE"
                                  @click="deleteEvent(event.id, $event)"
                                  on-bottom/>
                </div>
            </div>
        </Form>
        <Dialog></Dialog>

        <Dialog header="Błąd dodawania plików" v-model:visible="showGalleryValidationDialog"
                :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span class="p-invalid">{{galleryValidationErrorMessage}}</span>
            </div>
            <template #footer>
                <Button label="OK" icon="pi pi-check" @click="showGalleryValidationDialog=false"
                        class="button button-filled" autofocus />
            </template>
        </Dialog>
    </div>
</template>

<script>
    import {Cropper} from "vue-advanced-cropper";
    import "vue-advanced-cropper/dist/style.css";
    import Button from "primevue/button";
    import {Form} from "vee-validate";
    import Dialog from "primevue/dialog";
    import ProgressBar from "primevue/progressbar";
    import Draggable from "vue3-draggable";
    import Tooltip from "primevue/tooltip";
    import {
        addEventUsingPOST as addEvent,
        canEditUsingGET as canEdit,
        deleteEventUsingDELETE as deleteEvent,
        deleteMyEventUsingDELETE as deleteMyEvent,
        editEventUsingPOST as editEvent,
        getEventUsingGET as getEvent,
        uploadFilesUsingPOST as uploadFiles,
    } from "@/swagger/vue-api-client";
    import CustomInputText from "../../components/form/CustomInputText";
    import CustomInputTextarea from "@/components/form/CustomInputTextArea";
    import {SystemRole} from "@/utils/SystemRole";
    import {MapUtils} from "@/utils/MapUtils";
    import DesignedSelectOrganization from "@/components/form/selectOne/DesignedSelectOrganization";
    import DesignedSelectFrequency from "@/components/form/selectOne/DesignedSelectFrequency";
    import DesignedSelectWeekDay from "@/components/form/selectOne/DesignedSelectWeekDay";
    import EventInputPlace from "@/views/event/components/EventInputPlace";
    import CustomInputMask from "@/components/form/CustomInputMask";
    import SquareButton from "@/components/form/SquareButton";
    import VerticalButtons from "@/components/form/VerticalButtons";
    import DesignedSelectEventStatus from "@/components/form/selectOne/DesignedSelectEventStatus";
    import CustomSelectTime from "@/components/form/inner/CustomSelectTime";
    import CustomCalendar from "@/components/form/inner/CustomCalendar";
    import DesignedSelectOneEventType from "@/components/form/selectOne/DesignedSelectOneEventType";
    import {DateUtils} from "@/utils/DateUtils";
    import CustomCheckbox from "@/components/form/CustomCheckbox";
    import DesignedSelectDistrict from "@/components/form/selectOne/DesignedSelectDistrict";
    import {FileUtils} from "@/utils/FileUtils";
    import DeleteButton from "@/components/form/DeleteButton";
    import CustomInputNumber from "@/components/form/CustomInputNumber";

    export default {
        name: "EventFormView",

        components: {
            Cropper,
            CustomInputNumber,
            SquareButton,
            VerticalButtons,
            DesignedSelectDistrict,
            CustomCheckbox,
            DesignedSelectOneEventType,
            CustomCalendar,
            CustomSelectTime,
            DesignedSelectEventStatus,
            EventInputPlace,
            CustomInputMask,
            DesignedSelectWeekDay,
            DesignedSelectFrequency,
            DesignedSelectOrganization,
            CustomInputTextarea,
            CustomInputText,
            Button,
            Form,
            Dialog,
            DeleteButton,
            ProgressBar,
            Draggable,
        },

        directives: {
            Tooltip,
        },

        props: {
            value: {},
            create: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                calendarForm: this.getEmptyCalendar(),
                isPublished: false,
                event: {
                    galleryFiles: [],
                    newFiles: [],
                    calendarEntry: [],
                },
                browsedCoverImage: {
                    name: null,
                    url: null,
                },
                image: {
                    name: null,
                    url: null,
                },
                galleryImage: {
                    name: null,
                    url: null,
                },
                loaded: false,
                Role: SystemRole,
                file: undefined,
                acceptedFileFormats: FileUtils.acceptedImageFormats.map((val) => "." + val),
                newGalleryFiles: [],
                showGalleryValidationDialog: false,
                galleryValidationErrorMessage: "",
                showProgressBar: false,
                coverImageSize: null,
                drag: false,
                displayEmptyCalendarEntryMessage: false,
            };
        },

        beforeCreate() {
            if (SystemRole.isOrganizer() && !this.create) {
                canEdit({eventId: this.$route.params.id})
                    .then(() => {})
                    .catch(() => {
                        this.$router.push({name: "forbidden"});
                    });
            }
        },

        async beforeMount() {
            if (!this.create) {
                await this.fetchEventData(this.$route.params.id);
                this.loaded = true;
            } else {
                this.loaded = true;
            }
        },

        methods: {
            validateCalendarEntry() {
                if (this.event.periodical && this.event.calendarEntry.length < 1) {
                    this.displayEmptyCalendarEntryMessage = true;
                    return false;
                }
                this.displayEmptyCalendarEntryMessage = false;
                return true;
            },
            async handleFile() {
                const {files} = this.$refs.galleryFile;
                if (files !== undefined && files.length > 0) {
                    this.$emit("photosAdded", Object.values(files));
                } else {
                    this.handleChange(undefined);
                }
            },

            defaultSize({imageSize, visibleArea}) {
                return {
                    height: (visibleArea || imageSize).height - 1,
                };
            },

            goToEventView() {
                const routeData = this.$router.resolve({name: "event", params: {id: this.event.id}});
                window.open(routeData.href, "_blank");
            },

            async fetchEventData(id) {
                await getEvent({id, noCache: true}).then((response) => {
                    this.event = response.data;
                    this.newGalleryFiles = [];
                    if (this.event.coverImageUrl !== null) {
                        this.image = null;
                        this.browsedCoverImage.name = this.event.coverImageFile.filename;
                        this.browsedCoverImage.url = this.event.coverImageUrl;
                    }
                    this.getDates();
                }).catch((error) => {
                    if (error.response.status === 404) {
                        this.$router.push({name: "notFound"});
                    } else {
                        this.$router.push({name: "error"});
                    }
                });
            },

            async showOnMap() {
                const coordinates = await MapUtils.getCoordinates(this.event.address);

                if (!coordinates) {
                    return;
                }

                this.event.latitude = coordinates.lat.call();
                this.event.longitude = coordinates.lng.call();
            },

            async addNewDate() {
                this.validateCalendarEntry();
                const offset = this.calendarForm.timeBegin.getTimezoneOffset();

                const fixedTimeBegin = new Date(this.calendarForm.timeBegin.getTime() - (offset * 60 * 1000));
                const fixedTimeEnd = new Date(this.calendarForm.timeEnd.getTime() - (offset * 60 * 1000));
                this.calendarForm.timeBegin = fixedTimeBegin.toISOString().split("T")[1].substring(0, 5);
                this.calendarForm.timeEnd = fixedTimeEnd.toISOString().split("T")[1].substring(0, 5);

                this.event.calendarEntry.push(this.calendarForm);
                this.calendarForm = this.getEmptyCalendar();
                this.validateCalendarEntry();
            },

            removeDate(index) {
                this.event.calendarEntry.splice(index, 1);
            },

            getEmptyCalendar() {
                return {
                    dayWeek: null,
                    timeBegin: null,
                    timeEnd: null,
                };
            },

            setDates() {
                if (this.event.periodical) {
                    for (const item of this.event.calendarEntry) {
                        if (typeof item.timeBegin !== "string") {
                            item.timeBegin = this.getFixedTime(item.timeBegin);
                        }
                        if (typeof item.timeEnd !== "string") {
                            item.timeEnd = this.getFixedTime(item.timeEnd);
                        }
                    }
                    // eslint-disable-next-line max-len
                    this.event.periodicalStartDate = DateUtils.dateToString(this.event.periodicalStartDate, DateUtils.defaultDateFormat);
                    // eslint-disable-next-line max-len
                    this.event.periodicalEndDate = DateUtils.dateToString(this.event.periodicalEndDate, DateUtils.defaultDateFormat);
                } else {
                    const timeParams = {
                        year: null,
                        month: null,
                        day: null,
                        hour: null,
                        min: null,
                    };
                    this.retrieveDateFromDate(timeParams, this.event.startDate);
                    const startDate = new Date(timeParams.year, timeParams.month,
                                               timeParams.day, timeParams.hour, timeParams.min);

                    this.retrieveDateFromDate(timeParams, this.event.endDate);
                    const endDate = new Date(timeParams.year, timeParams.month,
                                             timeParams.day, timeParams.hour, timeParams.min);

                    this.event.eventStartDayTime = DateUtils.displayDateTime(startDate);
                    this.event.eventEndDayTime = DateUtils.displayDateTime(endDate);
                }
            },

            getFixedTime(time) {
                const offset = time.getTimezoneOffset();
                const fixedTimeBegin = new Date(time.getTime() - (offset * 60 * 1000));

                return fixedTimeBegin.toISOString().split("T")[1].substring(0, 5);
            },

            retrieveDateFromDate(timeParams, date) {
                if (date instanceof Date) {
                    timeParams.year = date.getFullYear();
                    timeParams.month = date.getMonth();
                    timeParams.day = date.getDate();
                    timeParams.hour = date.getHours();
                    timeParams.min = date.getMinutes();
                } else {
                    const [year, month, day] = date.split("T")[0].split("-");
                    const [hour, min] = date.split("T")[1].split(":");

                    timeParams.year = year;
                    timeParams.month = month - 1;
                    timeParams.day = day;
                    timeParams.hour = hour;
                    timeParams.min = min;
                }
            },

            getDates() {
                if (!this.event.periodical) {
                    this.event.startDate = new Date(this.event.eventStartDayTime);
                    this.event.endDate = new Date(this.event.eventEndDayTime);
                    this.event.timeBegin = new Date(this.event.eventStartDayTime);
                    this.event.timeEnd = new Date(this.event.eventEndDayTime);
                } else {
                    this.event.calendarEntry.forEach((item) => {
                        let hour = item.timeBegin.substring(0, 2);
                        let minutes = item.timeBegin.substring(3, 5);
                        item.timeBegin = new Date();
                        item.timeBegin.setHours(hour, minutes, 0);

                        hour = item.timeEnd.substring(0, 2);
                        minutes = item.timeEnd.substring(3, 5);
                        item.timeEnd = new Date();
                        item.timeEnd.setHours(hour, minutes, 0);
                    });
                }
            },

            saveAndPublishEvent(validate) {
                if (!this.validateCalendarEntry()) {
                    return;
                }
                validate().then((result) => {
                    if (result.valid) {
                        this.isPublished = true;
                        this.saveEvent();
                    }
                });
            },

            resetPublishAndSaveEvent(validate) {
                if (!this.validateCalendarEntry()) {
                    return;
                }
                validate().then((result) => {
                    if (result.valid) {
                        this.isPublished = false;
                        this.saveEvent();
                    }
                });
            },

            async saveEvent() {
                console.log("ZAPISZ");

                this.setDates();

                if (this.browsedCoverImage) {
                    if (!this.image) {
                        this.image = {
                            name: this.browsedCoverImage.name,
                            url: this.browsedCoverImage.url,
                        };
                    }
                    this.event.coverImageFile = {};
                    this.event.coverImageFile.filename = this.image.name;
                    this.event.coverImageFile.name = this.image.name;
                    this.event.coverImageUrl = this.image.url;
                } else {
                    this.event.coverImageFile = null;
                }

                if (this.newGalleryFiles && this.newGalleryFiles.length > 0) {
                    for (const newFile of this.newGalleryFiles) {
                        const tmpFile = {
                            filename: newFile.name,
                            name: newFile.name,
                        };
                        this.event.newFiles.push(tmpFile);
                    }
                }

                for (let i = 0; i < this.event.galleryFiles.length; i++) {
                    this.event.galleryFiles[i].orderId = i;
                }

                const func = this.create ? addEvent : editEvent;
                await func({eventDto: this.event, publish: this.isPublished}).then((response) => {
                    this.$toast.add({
                        severity: "success",
                        summary: "Sukces",
                        detail: this.create ? "Dodano nowe wydarzenie" : "Zaktualizowano wydarzenie",
                        life: 3000,
                    });
                    if (this.create) {
                        this.$router.push({name: "eventEdit", params: {id: response.data.id}});
                    }
                    this.fetchEventData(response.data.id);
                }).catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: "Nastąpił nieoczekiwany błąd. Skontaktuj się z administratorem systemu",
                        life: 5000,
                    });
                });
            },

            deleteEvent(id, event) {
                this.$confirm.require({
                    target: event.currentTarget,
                    header: "Czy na pewno chcesz usunąć wydarzenie?",
                    message: "Tej operacji nie będzie można cofnąć.",
                    acceptLabel: "Tak",
                    rejectLabel: "Nie",
                    accept: () => {
                        const func = SystemRole.isAdmin() ? deleteEvent({id}) : deleteMyEvent({id});
                        func.then(() => {
                            this.$toast.add({
                                severity: "success",
                                summary: "Sukces",
                                detail: "Pomyślnie usunięto wydarzenie",
                                life: 3000,
                            });
                            this.$router.push("/");
                        }).catch((error) => {
                            if (error.response.status === 403) {
                                this.$toast.add({
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Nie masz wystarczających uprawnień",
                                    life: 3000,
                                });
                            } else if (error.response.status === 401) {
                                this.$toast.add({
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Próbujesz usunąć wydarzenie nieprzypisane do Twojej organizacji",
                                    life: 3000,
                                });
                            } else if (error.response.status === 500) {
                                this.$toast.add({
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił błąd po stronie serwera. Skontaktuj się z administratorem",
                                    life: 3000,
                                });
                            } else {
                                this.$toast.add({
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd",
                                    life: 3000,
                                });
                            }
                        });
                    },
                });
            },

            resetImageUploader(cropperRef) {
                cropperRef.value = "";
            },

            editGalleryPhoto(id) {
                const cropperRef = this.$refs.galleryImageCropper;
                URL.revokeObjectURL(this.galleryImage.url);
                const foundPhotoById = this.event.galleryFiles.filter((f) => f.id === id).map((el) => ({
                    url: el.url,
                    name: el.name,
                    id: el.id,
                }));
                this.galleryImage = {
                    url: foundPhotoById[0].url,
                    name: foundPhotoById[0].name,
                    id: foundPhotoById[0].id,
                };
                cropperRef.scrollIntoView({behaviour: "smooth"});
                this.deleteGalleryPhoto(id);
            },

            deleteGalleryPhoto(id) {
                this.event.galleryFiles = this.event.galleryFiles.filter((f) => f.id !== id);
                this.newGalleryFiles = this.newGalleryFiles.filter((f) => f.id !== id);
            },

            handleNewGalleryPhotos(files) {
                if ((files.size + this.event.galleryFiles.size + this.event.newFiles.size) > 10) {
                    this.showGalleryValidationDialog = true;
                    this.galleryValidationErrorMessage = "Nie można dodać więcej niż 10 zdjęć";
                    return;
                }

                this.validateImage(files);

                // TODO: chyba lepiej po indeksie usuwać elementy
                const newPicsArray = files.map((el) => ({
                    name: el.name,
                    id: Math.floor(Math.random() * 10000) + 1,
                }));
                this.event.galleryFiles.push(...newPicsArray);

                this.reset(false);
                this.uploadImages(files, false);
            },

            validateImage(files) {
                for (const newFile of files) {
                    let errorMessage;
                    if (!FileUtils.validateFileSize(newFile)) {
                        errorMessage = "Maksymalny rozmiar pliku to: " + FileUtils.maxImagesSizeString();
                    } else if (!FileUtils.validateImageFormat(newFile)) {
                        errorMessage = "Niedozwolony format pliku. Dozwolone formaty to: "
                            + FileUtils.acceptedImageFormatsAsString();
                    }
                    if (errorMessage) {
                        this.showGalleryValidationDialog = true;
                        this.galleryValidationErrorMessage = errorMessage;
                        return;
                    }
                }
            },

            uploadImages(files, isMainImage) {
                this.validateImage(files);
                this.showProgressBar = true;
                const formData = new FormData();
                for (const file of files) {
                    formData.append("files", file);
                }
                const config = {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                };
                uploadFiles({files: formData, config}).then((response) => {
                    if (isMainImage) {
                        this.image = {
                            url: response.data[0],
                            name: response.data[0].substring(response.data[0].lastIndexOf("/") + 1),
                        };
                        this.browsedCoverImage = this.image;
                    } else {
                        const imagesToUpdate = this.event.galleryFiles.slice(-(files.length));
                        imagesToUpdate.forEach((element, index) => {
                            const tmpFile = {
                                url: response.data[index],
                                name: response.data[index]
                                    .substring(response.data[index].lastIndexOf("/") + 1),
                            };
                            element.url = tmpFile.url;
                            element.name = tmpFile.name;
                            this.newGalleryFiles.push(element);
                        });
                    }
                    this.showProgressBar = false;
                    // Kopiujemy dane galerii, żeby odświeżyc widok
                    this.event.galleryFiles = [...this.event.galleryFiles];
                }).catch((error) => {
                    if (error.response.status === 404) {
                        this.$router.push({name: "notFound"});
                    } else {
                        this.$router.push({name: "error"});
                    }
                });
            },

            hasErrors(errorsObj) {
                if (errorsObj) {
                    return Object.keys(errorsObj).length;
                }
                return false;
            },

            async cropAndSave(cropperRef, isMainImage) {
                if (cropperRef == null && isMainImage) {
                    this.image = null;
                    this.$toast.add({
                        severity: "success",
                        summary: "Sukces",
                        detail: "Główne zdjęcie zostało zapisane",
                        life: 3000,
                    });
                } else if (cropperRef != null) {
                    const {canvas} = cropperRef.getResult();
                    const blob = await new Promise((resolve) => canvas.toBlob(resolve));
                    const filename = isMainImage ? this.browsedCoverImage.name : this.galleryImage.name;
                    const file = new Array(new File([blob], filename, {type: blob.type}));
                    if (isMainImage) {
                        this.image = this.browsedCoverImage;
                        this.$toast.add({
                            severity: "success",
                            summary: "Sukces",
                            detail: "Główne zdjęcie zostało zapisane",
                            life: 3000,
                        });
                        this.uploadImages(file, isMainImage);
                    } else {
                        this.galleryImage = this.browsedCoverImage;
                        this.handleNewGalleryPhotos(file);
                    }
                }
            },

            reset(isMainImage) {
                if (isMainImage) {
                    this.browsedCoverImage = {
                        url: null,
                        name: null,
                    };
                } else {
                    this.galleryImage = {
                        url: null,
                        name: null,
                    };
                }
            },

            flip(x, y, cropperRef) {
                if (cropperRef.imageTransforms.rotate % 180 !== 0) {
                    cropperRef.flip(!x, !y, cropperRef);
                } else {
                    cropperRef.flip(x, y, cropperRef);
                }
            },

            rotate(angle, cropperRef) {
                cropperRef.rotate(angle);
            },

            loadImage(event, isMainImage) {
                const {files} = event.target;
                if (files && files[0]) {
                    if (isMainImage && this.browsedCoverImage.url) {
                        URL.revokeObjectURL(this.browsedCoverImage.url);
                    } else if (!isMainImage && this.galleryImage.url) {
                        URL.revokeObjectURL(this.galleryImage.url);
                    }

                    const blob = URL.createObjectURL(files[0]);
                    if (isMainImage) {
                        this.browsedCoverImage = {
                            url: blob,
                            name: files[0].name,
                        };
                    } else {
                        this.galleryImage = {
                            url: blob,
                            name: files[0].name,
                        };
                    }
                }
            },
        },

        computed: {
            computedFrequencyRules() {
                return this.event.periodical ? "required" : "";
            },

            computedCoordinates() {
                return parseInt(this.event.latitude, 10) + "°"
                    + parseInt((this.event.latitude % 1).toFixed(2).substring(2), 10) + "'N "
                    + parseInt(this.event.longitude, 10) + "°"
                    + parseInt((this.event.longitude % 1).toFixed(2).substring(2), 10) + "'E";
            },
        },
    };
</script>

<style lang="less" scoped>
@import "src/views/assets/less/form-view";
.gallery-item {
    display: flex;
    margin: 0 10px;
    text-align: center;

    .container {
        position: relative;

        .button-container {
            position: absolute;
            top: 1px;
            right: 45px;
            left: 45px;

            .button-edit-photo {
                background-color: var(--color-primary);
                color: var(--color-white);
                padding: 10px;
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                transition: all 0.2s;
                border: 1px solid var(--color-primary);
                &:hover,
                &:active,
                &:focus {
                    background-color: var(--color-white);
                    color: var(--color-primary);
                }
            }
        }
    }
}
.width90 {
    width: 90%;
}
.activities {
    margin-top: 100px;
}
.many-values {
    display: flex;
    flex: 1;
}
.map-container {
    margin-top: 50px;
}
.gallery {
    margin-top: 100px;
}
.img {
    width: 365px;
    height: 500px;
    object-fit: cover;
}
.selected-coordinates {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    color: var(--color-gray3);
}
.change-map-marker-label {
    display: flex;
    margin-left: 200px;
    color: var(--color-gray3);
}
.title-text {
    font-size: 25px;
    padding-right: 10px;
    font-weight: bold;
    color: var(--color-white);
}
.title-container {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    align-items: center;
}
.button-clear-photo {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: var(--color-primary);
    color: var(--color-white);
    padding: 10px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: all 0.2s;
    border: 1px solid var(--color-primary);
    &:hover,
    &:active,
    &:focus {
        background-color: var(--color-white);
        color: var(--color-primary);
    }
}
.upload-image {
    margin-top: 20px;
    margin-bottom: 20px;
    user-select: none;
    &__cropper {
        border: solid 1px #eee;
        min-height: 300px;
        max-height: 500px;
        width: 100%;
    }
    &__cropper-wrapper {
        position: relative;
    }
    &__reset-button {
        border-radius: 10px;
        position: absolute;
        right: 20px;
        bottom: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 42px;
        width: 42px;
        background: var(--color-primary);
        transition: background 0.5s;
        &:hover {
            background: var(--color-primary);
        }
        &-size {
            height: 42%;
            width: 50%;
            object-fit: cover;
        }
    }
    &__buttons-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 17px;
    }
    &__button {
        border: none;
        outline: solid transparent;
        color: white;
        font-size: 16px;
        padding: 10px 20px;
        background: #3fb37f;
        cursor: pointer;
        transition: background 0.5s;
        margin: 0 16px;
        &:hover,
        &:focus {
            background: #38d890;
        }
        input {
            display: none;
        }
    }
}
</style>
