<template>
    <div class="p-fluid custom-input" @click="$refs.file.click()">
        <img v-if="logoUrl" alt="Zdjęcie" :src="logoUrl" class="background"
             :style="containFile ? 'object-fit: contain' : 'object-fit: cover'"/>
        <i class="pi pi-plus icon-add" v-if="!logoUrl"/>
        <div class="label-add" v-if="!logoUrl">{{label}}</div>
        <i class="pi pi-pencil icon-edit" v-if="logoUrl"/>
        <div class="label-edit" v-if="logoUrl">{{label}}</div>
        <input type="file" ref="file" v-show="false" v-on:change="handleFile"
               :accept="acceptedFileFormats" :aria-label="name">
    </div>
    <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
</template>

<script>
    import {useField} from "vee-validate";

    export default {
        name: "CustomFileUpload",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            modelValue: null,
            class: {
                type: String,
            },
            icon: {
                type: String,
                default: null,
                required: false,
            },
            acceptedFileFormats: {
                type: Array,
                default: null,
                required: false,
            },
            logoUrl: {
                type: String,
            },
            containFile: {
                type: Boolean,
                default: false,
            },
            rules: undefined,
        },
        emits: ["update:modelValue", "update:logoUrl"],

        watch: {
            modelValue(val) {
                this.handleChange(val);
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },

            },

            getLabel() {
                if (this.internalValue) {
                    if (this.internalValue.name) {
                        return this.internalValue.name;
                    }
                    return this.internalValue;
                }
                return this.label ? this.label : this.$t("message.components.chooseFile");
            },

            computedClasses() {
                const classes = this.class ? this.class : "";
                return classes + (this.errorMessage ? "p-invalid" : "");
            },

        },

        methods: {
            async handleFile() {
                const file = this.$refs.file.files[0];
                if (file !== undefined) {
                    // eslint-disable-next-line prefer-destructuring
                    this.$emit("update:logoUrl", URL.createObjectURL(file));
                    this.internalValue = file;
                    this.handleChange(file);
                } else {
                    this.internalValue = undefined;
                    this.handleChange(undefined);
                }
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },
    };
</script>

<style lang="less" scoped>
  @import "../../../assets/theme/variable.less";
    .custom-input {
      position: relative;
      height: 500px;
      cursor: pointer;
      background-color: @color-white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .icon-add {
        position: absolute;
        font-size: 150px;
        color: var(--color-gray3);
        transition: all 1s;
      }

      .label-add {
        position: absolute;
        bottom: 60px;
        color: var(--color-gray3);
        opacity: 0;
        transition: all 1s;
      }

      .icon-edit {
        position: absolute;
        bottom: 10px;
        left: 10px;
        font-size: 30px;
        color: @primary-color;
        background-color: @color-white2;
        padding: 5px;
        border-radius: 5px;
        transition: all 1s;
      }

      .label-edit {
        position: absolute;
        bottom: 20px;
        left: 60px;
        color: @primary-color;
        opacity: 0;
        transition: all 1s;
      }

      .background {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        background-color: @color-white;
        border: solid 2px @color-gray2;
      }
    }
  .custom-input:hover {
    .icon-add {
      font-size: 170px;
    }

    .label-add {
      opacity: 1;
    }

    .icon-edit {
        padding-right: 130px;
    }

    .label-edit {
      opacity: 1;
    }
  }
</style>
