<template>
    <div>
        <div class="login-panel-header">
            <router-link :to="{name: 'search'}">
<!--TODO logo                <img src="../assets/UActiv_logo/PION/UACTIV_PION_kolor-RGB.png" alt="logo"/>-->
            </router-link>
        </div>
        <div class="login-panel-content">
            <div class="p-grid">
                <div class="p-col-12" @keyup.enter="login">
                    <CustomInputText name="login" v-model="loginForm.login" icon="pi pi-envelope"
                                     :label="$t('message.login.email')"/>
                    <CustomPassword name="password" v-model="loginForm.password"
                                    :label="$t('message.login.password')"/>
                    <span class="p-invalid" style="max-width: 400px">{{loginMessage}}</span>
                </div>
                <div class="p-col-12 login-submit">
                    <Button :label="$t('message.login.action.login')" @click="login" class="button button-filled"/>
                </div>
                <div class="p-col-12 login-view-links">
                    <router-link :to="{name: 'remindPassword'}">
                        {{ $t('message.login.action.remindPassword') }}
                    </router-link>
                    <br>
                    <br>
                    Jesteś nowy?
                    <router-link :to="{name: 'register'}">
                        {{ $t('message.login.action.register') }}
                    </router-link>
                    <br>
                    <br>
                    <router-link :to="{name: 'search'}">
                        {{ $t('message.login.homeReturn') }}
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import {loginUsingPOST as loginRequest} from "@/swagger/vue-api-client";
    import CustomPassword from "../components/form/CustomPassword";
    import CustomInputText from "../components/form/CustomInputText";

    export default {
        name: "LoginView",
        components: {CustomInputText, CustomPassword, Button},

        data() {
            return {
                loginForm: {
                    login: "",
                    password: "",
                },
                loginMessage: "",
            };
        },

        methods: {
            login() {
                loginRequest({authenticationRequest: this.loginForm})
                    .then((response) => {
                        localStorage.setItem("id", response.data.id);
                        localStorage.setItem("token", response.data.token);
                        localStorage.setItem("role", response.data.role);
                        localStorage.setItem("fullName", response.data.fullName);
                        localStorage.setItem("organizationId", response.data.organizationId);
                        this.$router.push({name: "search"});
                    })
                    .catch((error) => {
                        if (error.response.data && error.response.status === 403) {
                            this.loginMessage = error.response.data;
                        } else {
                            this.loginMessage = "Nastąpił nieoczekiwany błąd"
                        }
                    });
            },
        },
    };
</script>

<style lang="less">
    @import "assets/less/login.less";
</style>
