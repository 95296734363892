/* eslint-disable */
import axios from 'axios'
import qs from 'qs'
let domain = ''
export const getDomain = () => {
  return domain
}
export const setDomain = ($domain) => {
  domain = $domain
}
export const request = (method, url, body, queryParameters, form, config) => {
  method = method.toLowerCase()
  let keys = Object.keys(queryParameters)
  let queryUrl = url
  if (keys.length > 0) {
    queryUrl = url + '?' + qs.stringify(queryParameters)
  }
  // let queryUrl = url+(keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
  if (body) {
    return axios[method](queryUrl, body, config)
  } else if (method === 'get') {
    return axios[method](queryUrl, {
      params: form
    }, config)
  } else {
    return axios[method](queryUrl, qs.stringify(form), config)
  }
}
/*==========================================================
 *                    Api Documentation
 ==========================================================*/
/**
 * triggerArchiveEventTask
 * request: triggerArchiveEventTaskUsingPOST
 * url: triggerArchiveEventTaskUsingPOSTURL
 * method: triggerArchiveEventTaskUsingPOST_TYPE
 * raw_url: triggerArchiveEventTaskUsingPOST_RAW_URL
 */
export const triggerArchiveEventTaskUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/cron/archive'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const triggerArchiveEventTaskUsingPOST_RAW_URL = function() {
  return '/cron/archive'
}
export const triggerArchiveEventTaskUsingPOST_TYPE = function() {
  return 'post'
}
export const triggerArchiveEventTaskUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/cron/archive'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllDistricts
 * request: getAllDistrictsUsingGET
 * url: getAllDistrictsUsingGETURL
 * method: getAllDistrictsUsingGET_TYPE
 * raw_url: getAllDistrictsUsingGET_RAW_URL
 */
export const getAllDistrictsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/enum/districts'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllDistrictsUsingGET_RAW_URL = function() {
  return '/enum/districts'
}
export const getAllDistrictsUsingGET_TYPE = function() {
  return 'get'
}
export const getAllDistrictsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/enum/districts'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllEventStatuses
 * request: getAllEventStatusesUsingGET
 * url: getAllEventStatusesUsingGETURL
 * method: getAllEventStatusesUsingGET_TYPE
 * raw_url: getAllEventStatusesUsingGET_RAW_URL
 */
export const getAllEventStatusesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/enum/event-statuses'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllEventStatusesUsingGET_RAW_URL = function() {
  return '/enum/event-statuses'
}
export const getAllEventStatusesUsingGET_TYPE = function() {
  return 'get'
}
export const getAllEventStatusesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/enum/event-statuses'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingGET
 * url: errorHtmlUsingGETURL
 * method: errorHtmlUsingGET_TYPE
 * raw_url: errorHtmlUsingGET_RAW_URL
 */
export const errorHtmlUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingGET_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingGET_TYPE = function() {
  return 'get'
}
export const errorHtmlUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingHEAD
 * url: errorHtmlUsingHEADURL
 * method: errorHtmlUsingHEAD_TYPE
 * raw_url: errorHtmlUsingHEAD_RAW_URL
 */
export const errorHtmlUsingHEAD = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('head', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingHEAD_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingHEAD_TYPE = function() {
  return 'head'
}
export const errorHtmlUsingHEADURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingPOST
 * url: errorHtmlUsingPOSTURL
 * method: errorHtmlUsingPOST_TYPE
 * raw_url: errorHtmlUsingPOST_RAW_URL
 */
export const errorHtmlUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingPOST_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingPOST_TYPE = function() {
  return 'post'
}
export const errorHtmlUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingPUT
 * url: errorHtmlUsingPUTURL
 * method: errorHtmlUsingPUT_TYPE
 * raw_url: errorHtmlUsingPUT_RAW_URL
 */
export const errorHtmlUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingPUT_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingPUT_TYPE = function() {
  return 'put'
}
export const errorHtmlUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingDELETE
 * url: errorHtmlUsingDELETEURL
 * method: errorHtmlUsingDELETE_TYPE
 * raw_url: errorHtmlUsingDELETE_RAW_URL
 */
export const errorHtmlUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingDELETE_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingDELETE_TYPE = function() {
  return 'delete'
}
export const errorHtmlUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingOPTIONS
 * url: errorHtmlUsingOPTIONSURL
 * method: errorHtmlUsingOPTIONS_TYPE
 * raw_url: errorHtmlUsingOPTIONS_RAW_URL
 */
export const errorHtmlUsingOPTIONS = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('options', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingOPTIONS_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingOPTIONS_TYPE = function() {
  return 'options'
}
export const errorHtmlUsingOPTIONSURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingPATCH
 * url: errorHtmlUsingPATCHURL
 * method: errorHtmlUsingPATCH_TYPE
 * raw_url: errorHtmlUsingPATCH_RAW_URL
 */
export const errorHtmlUsingPATCH = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingPATCH_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingPATCH_TYPE = function() {
  return 'patch'
}
export const errorHtmlUsingPATCHURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * addEvent
 * request: addEventUsingPOST
 * url: addEventUsingPOSTURL
 * method: addEventUsingPOST_TYPE
 * raw_url: addEventUsingPOST_RAW_URL
 * @param eventDto - eventDTO
 * @param publish - publish
 */
export const addEventUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['eventDto'] !== undefined) {
    body = parameters['eventDto']
  }
  if (parameters['eventDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eventDto'))
  }
  if (parameters['publish'] !== undefined) {
    queryParameters['publish'] = parameters['publish']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addEventUsingPOST_RAW_URL = function() {
  return '/event'
}
export const addEventUsingPOST_TYPE = function() {
  return 'post'
}
export const addEventUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event'
  if (parameters['publish'] !== undefined) {
    queryParameters['publish'] = parameters['publish']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateEventTypeCategory
 * request: createOrUpdateEventTypeCategoryUsingPOST
 * url: createOrUpdateEventTypeCategoryUsingPOSTURL
 * method: createOrUpdateEventTypeCategoryUsingPOST_TYPE
 * raw_url: createOrUpdateEventTypeCategoryUsingPOST_RAW_URL
 * @param eventTypeCategoryDto - eventTypeCategoryDTO
 */
export const createOrUpdateEventTypeCategoryUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event-type-category/add-or-edit'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['eventTypeCategoryDto'] !== undefined) {
    body = parameters['eventTypeCategoryDto']
  }
  if (parameters['eventTypeCategoryDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eventTypeCategoryDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateEventTypeCategoryUsingPOST_RAW_URL = function() {
  return '/event-type-category/add-or-edit'
}
export const createOrUpdateEventTypeCategoryUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateEventTypeCategoryUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event-type-category/add-or-edit'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * addEventTypeToCategory
 * request: addEventTypeToCategoryUsingPOST
 * url: addEventTypeToCategoryUsingPOSTURL
 * method: addEventTypeToCategoryUsingPOST_TYPE
 * raw_url: addEventTypeToCategoryUsingPOST_RAW_URL
 * @param categoryId - categoryId
 * @param eventsId - eventsId
 */
export const addEventTypeToCategoryUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event-type-category/add-or-edit-eventList/{id}'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['categoryId'] !== undefined) {
    queryParameters['categoryId'] = parameters['categoryId']
  }
  if (parameters['categoryId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: categoryId'))
  }
  if (parameters['eventsId'] !== undefined) {
    body = parameters['eventsId']
  }
  if (parameters['eventsId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eventsId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addEventTypeToCategoryUsingPOST_RAW_URL = function() {
  return '/event-type-category/add-or-edit-eventList/{id}'
}
export const addEventTypeToCategoryUsingPOST_TYPE = function() {
  return 'post'
}
export const addEventTypeToCategoryUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event-type-category/add-or-edit-eventList/{id}'
  if (parameters['categoryId'] !== undefined) {
    queryParameters['categoryId'] = parameters['categoryId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllEventTypeCategories
 * request: getAllEventTypeCategoriesUsingGET
 * url: getAllEventTypeCategoriesUsingGETURL
 * method: getAllEventTypeCategoriesUsingGET_TYPE
 * raw_url: getAllEventTypeCategoriesUsingGET_RAW_URL
 */
export const getAllEventTypeCategoriesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event-type-category/all'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllEventTypeCategoriesUsingGET_RAW_URL = function() {
  return '/event-type-category/all'
}
export const getAllEventTypeCategoriesUsingGET_TYPE = function() {
  return 'get'
}
export const getAllEventTypeCategoriesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event-type-category/all'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteEventTypeCategory
 * request: deleteEventTypeCategoryUsingDELETE
 * url: deleteEventTypeCategoryUsingDELETEURL
 * method: deleteEventTypeCategoryUsingDELETE_TYPE
 * raw_url: deleteEventTypeCategoryUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteEventTypeCategoryUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event-type-category/delete'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteEventTypeCategoryUsingDELETE_RAW_URL = function() {
  return '/event-type-category/delete'
}
export const deleteEventTypeCategoryUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteEventTypeCategoryUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event-type-category/delete'
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * eventTypeCategoryExists
 * request: eventTypeCategoryExistsUsingGET
 * url: eventTypeCategoryExistsUsingGETURL
 * method: eventTypeCategoryExistsUsingGET_TYPE
 * raw_url: eventTypeCategoryExistsUsingGET_RAW_URL
 * @param name - name
 */
export const eventTypeCategoryExistsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event-type-category/event-type-category-exists'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const eventTypeCategoryExistsUsingGET_RAW_URL = function() {
  return '/event-type-category/event-type-category-exists'
}
export const eventTypeCategoryExistsUsingGET_TYPE = function() {
  return 'get'
}
export const eventTypeCategoryExistsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event-type-category/event-type-category-exists'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateEventType
 * request: createOrUpdateEventTypeUsingPOST
 * url: createOrUpdateEventTypeUsingPOSTURL
 * method: createOrUpdateEventTypeUsingPOST_TYPE
 * raw_url: createOrUpdateEventTypeUsingPOST_RAW_URL
 * @param eventTypeDto - eventTypeDTO
 */
export const createOrUpdateEventTypeUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event-type/add-or-edit'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['eventTypeDto'] !== undefined) {
    body = parameters['eventTypeDto']
  }
  if (parameters['eventTypeDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eventTypeDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateEventTypeUsingPOST_RAW_URL = function() {
  return '/event-type/add-or-edit'
}
export const createOrUpdateEventTypeUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateEventTypeUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event-type/add-or-edit'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllEventTypes
 * request: getAllEventTypesUsingGET
 * url: getAllEventTypesUsingGETURL
 * method: getAllEventTypesUsingGET_TYPE
 * raw_url: getAllEventTypesUsingGET_RAW_URL
 */
export const getAllEventTypesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event-type/all'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllEventTypesUsingGET_RAW_URL = function() {
  return '/event-type/all'
}
export const getAllEventTypesUsingGET_TYPE = function() {
  return 'get'
}
export const getAllEventTypesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event-type/all'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllPublishedEvents
 * request: getAllPublishedEventsUsingGET
 * url: getAllPublishedEventsUsingGETURL
 * method: getAllPublishedEventsUsingGET_TYPE
 * raw_url: getAllPublishedEventsUsingGET_RAW_URL
 */
export const getAllPublishedEventsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event-type/all-published'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllPublishedEventsUsingGET_RAW_URL = function() {
  return '/event-type/all-published'
}
export const getAllPublishedEventsUsingGET_TYPE = function() {
  return 'get'
}
export const getAllPublishedEventsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event-type/all-published'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllVisibleEvents
 * request: getAllVisibleEventsUsingGET
 * url: getAllVisibleEventsUsingGETURL
 * method: getAllVisibleEventsUsingGET_TYPE
 * raw_url: getAllVisibleEventsUsingGET_RAW_URL
 */
export const getAllVisibleEventsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event-type/all-visible'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllVisibleEventsUsingGET_RAW_URL = function() {
  return '/event-type/all-visible'
}
export const getAllVisibleEventsUsingGET_TYPE = function() {
  return 'get'
}
export const getAllVisibleEventsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event-type/all-visible'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * eventTypeExists
 * request: eventTypeExistsUsingGET
 * url: eventTypeExistsUsingGETURL
 * method: eventTypeExistsUsingGET_TYPE
 * raw_url: eventTypeExistsUsingGET_RAW_URL
 * @param name - name
 */
export const eventTypeExistsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event-type/event-type-exists'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const eventTypeExistsUsingGET_RAW_URL = function() {
  return '/event-type/event-type-exists'
}
export const eventTypeExistsUsingGET_TYPE = function() {
  return 'get'
}
export const eventTypeExistsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event-type/event-type-exists'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * hideOrShowEventType
 * request: hideOrShowEventTypeUsingPOST
 * url: hideOrShowEventTypeUsingPOSTURL
 * method: hideOrShowEventTypeUsingPOST_TYPE
 * raw_url: hideOrShowEventTypeUsingPOST_RAW_URL
 * @param id - id
 */
export const hideOrShowEventTypeUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event-type/hide/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const hideOrShowEventTypeUsingPOST_RAW_URL = function() {
  return '/event-type/hide/{id}'
}
export const hideOrShowEventTypeUsingPOST_TYPE = function() {
  return 'post'
}
export const hideOrShowEventTypeUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event-type/hide/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchAllCutEvents
 * request: searchAllCutEventsUsingPOST
 * url: searchAllCutEventsUsingPOSTURL
 * method: searchAllCutEventsUsingPOST_TYPE
 * raw_url: searchAllCutEventsUsingPOST_RAW_URL
 * @param eventSearchCriteria - eventSearchCriteria
 */
export const searchAllCutEventsUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/all-cut-events'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['eventSearchCriteria'] !== undefined) {
    body = parameters['eventSearchCriteria']
  }
  if (parameters['eventSearchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eventSearchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchAllCutEventsUsingPOST_RAW_URL = function() {
  return '/event/all-cut-events'
}
export const searchAllCutEventsUsingPOST_TYPE = function() {
  return 'post'
}
export const searchAllCutEventsUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/all-cut-events'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllEvents
 * request: getAllEventsUsingPOST
 * url: getAllEventsUsingPOSTURL
 * method: getAllEventsUsingPOST_TYPE
 * raw_url: getAllEventsUsingPOST_RAW_URL
 * @param eventSearchCriteria - eventSearchCriteria
 */
export const getAllEventsUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/all-events'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['eventSearchCriteria'] !== undefined) {
    body = parameters['eventSearchCriteria']
  }
  if (parameters['eventSearchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eventSearchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const getAllEventsUsingPOST_RAW_URL = function() {
  return '/event/all-events'
}
export const getAllEventsUsingPOST_TYPE = function() {
  return 'post'
}
export const getAllEventsUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/all-events'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getArchivedEvents
 * request: getArchivedEventsUsingPOST
 * url: getArchivedEventsUsingPOSTURL
 * method: getArchivedEventsUsingPOST_TYPE
 * raw_url: getArchivedEventsUsingPOST_RAW_URL
 * @param eventSearchCriteria - eventSearchCriteria
 */
export const getArchivedEventsUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/archived-events'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['eventSearchCriteria'] !== undefined) {
    body = parameters['eventSearchCriteria']
  }
  if (parameters['eventSearchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eventSearchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const getArchivedEventsUsingPOST_RAW_URL = function() {
  return '/event/archived-events'
}
export const getArchivedEventsUsingPOST_TYPE = function() {
  return 'post'
}
export const getArchivedEventsUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/archived-events'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getBlockedEvents
 * request: getBlockedEventsUsingPOST
 * url: getBlockedEventsUsingPOSTURL
 * method: getBlockedEventsUsingPOST_TYPE
 * raw_url: getBlockedEventsUsingPOST_RAW_URL
 * @param eventSearchCriteria - eventSearchCriteria
 */
export const getBlockedEventsUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/blocked-events'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['eventSearchCriteria'] !== undefined) {
    body = parameters['eventSearchCriteria']
  }
  if (parameters['eventSearchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eventSearchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const getBlockedEventsUsingPOST_RAW_URL = function() {
  return '/event/blocked-events'
}
export const getBlockedEventsUsingPOST_TYPE = function() {
  return 'post'
}
export const getBlockedEventsUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/blocked-events'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * countEvents
 * request: countEventsUsingPOST
 * url: countEventsUsingPOSTURL
 * method: countEventsUsingPOST_TYPE
 * raw_url: countEventsUsingPOST_RAW_URL
 * @param eventSearchCriteria - eventSearchCriteria
 */
export const countEventsUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['eventSearchCriteria'] !== undefined) {
    body = parameters['eventSearchCriteria']
  }
  if (parameters['eventSearchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eventSearchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const countEventsUsingPOST_RAW_URL = function() {
  return '/event/count'
}
export const countEventsUsingPOST_TYPE = function() {
  return 'post'
}
export const countEventsUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * countAllCutEvents
 * request: countAllCutEventsUsingPOST
 * url: countAllCutEventsUsingPOSTURL
 * method: countAllCutEventsUsingPOST_TYPE
 * raw_url: countAllCutEventsUsingPOST_RAW_URL
 * @param eventSearchCriteria - eventSearchCriteria
 */
export const countAllCutEventsUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/count-all-cut-events'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['eventSearchCriteria'] !== undefined) {
    body = parameters['eventSearchCriteria']
  }
  if (parameters['eventSearchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eventSearchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const countAllCutEventsUsingPOST_RAW_URL = function() {
  return '/event/count-all-cut-events'
}
export const countAllCutEventsUsingPOST_TYPE = function() {
  return 'post'
}
export const countAllCutEventsUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/count-all-cut-events'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * countAllEvents
 * request: countAllEventsUsingPOST
 * url: countAllEventsUsingPOSTURL
 * method: countAllEventsUsingPOST_TYPE
 * raw_url: countAllEventsUsingPOST_RAW_URL
 * @param eventSearchCriteria - eventSearchCriteria
 */
export const countAllEventsUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/count-all-events'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['eventSearchCriteria'] !== undefined) {
    body = parameters['eventSearchCriteria']
  }
  if (parameters['eventSearchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eventSearchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const countAllEventsUsingPOST_RAW_URL = function() {
  return '/event/count-all-events'
}
export const countAllEventsUsingPOST_TYPE = function() {
  return 'post'
}
export const countAllEventsUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/count-all-events'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * countArchivedEvents
 * request: countArchivedEventsUsingPOST
 * url: countArchivedEventsUsingPOSTURL
 * method: countArchivedEventsUsingPOST_TYPE
 * raw_url: countArchivedEventsUsingPOST_RAW_URL
 * @param eventSearchCriteria - eventSearchCriteria
 */
export const countArchivedEventsUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/count-archived-events'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['eventSearchCriteria'] !== undefined) {
    body = parameters['eventSearchCriteria']
  }
  if (parameters['eventSearchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eventSearchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const countArchivedEventsUsingPOST_RAW_URL = function() {
  return '/event/count-archived-events'
}
export const countArchivedEventsUsingPOST_TYPE = function() {
  return 'post'
}
export const countArchivedEventsUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/count-archived-events'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * countBlockedEvents
 * request: countBlockedEventsUsingPOST
 * url: countBlockedEventsUsingPOSTURL
 * method: countBlockedEventsUsingPOST_TYPE
 * raw_url: countBlockedEventsUsingPOST_RAW_URL
 * @param eventSearchCriteria - eventSearchCriteria
 */
export const countBlockedEventsUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/count-blocked-events'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['eventSearchCriteria'] !== undefined) {
    body = parameters['eventSearchCriteria']
  }
  if (parameters['eventSearchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eventSearchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const countBlockedEventsUsingPOST_RAW_URL = function() {
  return '/event/count-blocked-events'
}
export const countBlockedEventsUsingPOST_TYPE = function() {
  return 'post'
}
export const countBlockedEventsUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/count-blocked-events'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteEvent
 * request: deleteEventUsingDELETE
 * url: deleteEventUsingDELETEURL
 * method: deleteEventUsingDELETE_TYPE
 * raw_url: deleteEventUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteEventUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/delete'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteEventUsingDELETE_RAW_URL = function() {
  return '/event/delete'
}
export const deleteEventUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteEventUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/delete'
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteMyEvent
 * request: deleteMyEventUsingDELETE
 * url: deleteMyEventUsingDELETEURL
 * method: deleteMyEventUsingDELETE_TYPE
 * raw_url: deleteMyEventUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteMyEventUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/delete/my'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteMyEventUsingDELETE_RAW_URL = function() {
  return '/event/delete/my'
}
export const deleteMyEventUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteMyEventUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/delete/my'
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * editEvent
 * request: editEventUsingPOST
 * url: editEventUsingPOSTURL
 * method: editEventUsingPOST_TYPE
 * raw_url: editEventUsingPOST_RAW_URL
 * @param eventDto - eventDTO
 * @param publish - publish
 */
export const editEventUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/edit'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['eventDto'] !== undefined) {
    body = parameters['eventDto']
  }
  if (parameters['eventDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eventDto'))
  }
  if (parameters['publish'] !== undefined) {
    queryParameters['publish'] = parameters['publish']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const editEventUsingPOST_RAW_URL = function() {
  return '/event/edit'
}
export const editEventUsingPOST_TYPE = function() {
  return 'post'
}
export const editEventUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/edit'
  if (parameters['publish'] !== undefined) {
    queryParameters['publish'] = parameters['publish']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * canEdit
 * request: canEditUsingGET
 * url: canEditUsingGETURL
 * method: canEditUsingGET_TYPE
 * raw_url: canEditUsingGET_RAW_URL
 * @param eventId - eventId
 */
export const canEditUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/edit-access'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['eventId'] !== undefined) {
    queryParameters['eventId'] = parameters['eventId']
  }
  if (parameters['eventId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eventId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const canEditUsingGET_RAW_URL = function() {
  return '/event/edit-access'
}
export const canEditUsingGET_TYPE = function() {
  return 'get'
}
export const canEditUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/edit-access'
  if (parameters['eventId'] !== undefined) {
    queryParameters['eventId'] = parameters['eventId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchEvents
 * request: searchEventsUsingPOST
 * url: searchEventsUsingPOSTURL
 * method: searchEventsUsingPOST_TYPE
 * raw_url: searchEventsUsingPOST_RAW_URL
 * @param eventSearchCriteria - eventSearchCriteria
 */
export const searchEventsUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['eventSearchCriteria'] !== undefined) {
    body = parameters['eventSearchCriteria']
  }
  if (parameters['eventSearchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eventSearchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchEventsUsingPOST_RAW_URL = function() {
  return '/event/search'
}
export const searchEventsUsingPOST_TYPE = function() {
  return 'post'
}
export const searchEventsUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getEvent
 * request: getEventUsingGET
 * url: getEventUsingGETURL
 * method: getEventUsingGET_TYPE
 * raw_url: getEventUsingGET_RAW_URL
 * @param id - id
 * @param noCache - noCache
 */
export const getEventUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['noCache'] !== undefined) {
    queryParameters['noCache'] = parameters['noCache']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getEventUsingGET_RAW_URL = function() {
  return '/event/{id}'
}
export const getEventUsingGET_TYPE = function() {
  return 'get'
}
export const getEventUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['noCache'] !== undefined) {
    queryParameters['noCache'] = parameters['noCache']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * addEvent
 * request: addEventUsingPOST_1
 * url: addEventUsingPOST_1URL
 * method: addEventUsingPOST_1_TYPE
 * raw_url: addEventUsingPOST_1_RAW_URL
 * @param eventDto - eventDTO
 */
export const addEventUsingPOST_1 = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/external/event'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['eventDto'] !== undefined) {
    body = parameters['eventDto']
  }
  if (parameters['eventDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eventDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addEventUsingPOST_1_RAW_URL = function() {
  return '/external/event'
}
export const addEventUsingPOST_1_TYPE = function() {
  return 'post'
}
export const addEventUsingPOST_1URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/external/event'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteEvent
 * request: deleteEventUsingDELETE_1
 * url: deleteEventUsingDELETE_1URL
 * method: deleteEventUsingDELETE_1_TYPE
 * raw_url: deleteEventUsingDELETE_1_RAW_URL
 * @param eventId - eventId
 */
export const deleteEventUsingDELETE_1 = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/external/event/delete'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['eventId'] !== undefined) {
    queryParameters['eventId'] = parameters['eventId']
  }
  if (parameters['eventId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eventId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteEventUsingDELETE_1_RAW_URL = function() {
  return '/external/event/delete'
}
export const deleteEventUsingDELETE_1_TYPE = function() {
  return 'delete'
}
export const deleteEventUsingDELETE_1URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/external/event/delete'
  if (parameters['eventId'] !== undefined) {
    queryParameters['eventId'] = parameters['eventId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * acceptImportedEvent
 * request: acceptImportedEventUsingPOST
 * url: acceptImportedEventUsingPOSTURL
 * method: acceptImportedEventUsingPOST_TYPE
 * raw_url: acceptImportedEventUsingPOST_RAW_URL
 * @param id - id
 */
export const acceptImportedEventUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/importedEvent/accept'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    body = parameters['id']
  }
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const acceptImportedEventUsingPOST_RAW_URL = function() {
  return '/importedEvent/accept'
}
export const acceptImportedEventUsingPOST_TYPE = function() {
  return 'post'
}
export const acceptImportedEventUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/importedEvent/accept'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchImportedEventAcceptanceViewCount
 * request: searchImportedEventAcceptanceViewCountUsingPOST
 * url: searchImportedEventAcceptanceViewCountUsingPOSTURL
 * method: searchImportedEventAcceptanceViewCountUsingPOST_TYPE
 * raw_url: searchImportedEventAcceptanceViewCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchImportedEventAcceptanceViewCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/importedEvent/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchImportedEventAcceptanceViewCountUsingPOST_RAW_URL = function() {
  return '/importedEvent/count'
}
export const searchImportedEventAcceptanceViewCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchImportedEventAcceptanceViewCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/importedEvent/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchImportedEvent
 * request: searchImportedEventUsingPOST
 * url: searchImportedEventUsingPOSTURL
 * method: searchImportedEventUsingPOST_TYPE
 * raw_url: searchImportedEventUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchImportedEventUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/importedEvent/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchImportedEventUsingPOST_RAW_URL = function() {
  return '/importedEvent/search'
}
export const searchImportedEventUsingPOST_TYPE = function() {
  return 'post'
}
export const searchImportedEventUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/importedEvent/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * isTokenValid
 * request: isTokenValidUsingGET
 * url: isTokenValidUsingGETURL
 * method: isTokenValidUsingGET_TYPE
 * raw_url: isTokenValidUsingGET_RAW_URL
 */
export const isTokenValidUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/is-token-valid'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const isTokenValidUsingGET_RAW_URL = function() {
  return '/is-token-valid'
}
export const isTokenValidUsingGET_TYPE = function() {
  return 'get'
}
export const isTokenValidUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/is-token-valid'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * login
 * request: loginUsingPOST
 * url: loginUsingPOSTURL
 * method: loginUsingPOST_TYPE
 * raw_url: loginUsingPOST_RAW_URL
 * @param authenticationRequest - authenticationRequest
 */
export const loginUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/login'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['authenticationRequest'] !== undefined) {
    body = parameters['authenticationRequest']
  }
  if (parameters['authenticationRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: authenticationRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const loginUsingPOST_RAW_URL = function() {
  return '/login'
}
export const loginUsingPOST_TYPE = function() {
  return 'post'
}
export const loginUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/login'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getOrganizationData
 * request: getOrganizationDataUsingGET
 * url: getOrganizationDataUsingGETURL
 * method: getOrganizationDataUsingGET_TYPE
 * raw_url: getOrganizationDataUsingGET_RAW_URL
 * @param id - id
 */
export const getOrganizationDataUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getOrganizationDataUsingGET_RAW_URL = function() {
  return '/organization'
}
export const getOrganizationDataUsingGET_TYPE = function() {
  return 'get'
}
export const getOrganizationDataUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization'
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrganization
 * request: createOrganizationUsingPOST
 * url: createOrganizationUsingPOSTURL
 * method: createOrganizationUsingPOST_TYPE
 * raw_url: createOrganizationUsingPOST_RAW_URL
 * @param organizationDto - organizationDTO
 */
export const createOrganizationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['organizationDto'] !== undefined) {
    body = parameters['organizationDto']
  }
  if (parameters['organizationDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organizationDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrganizationUsingPOST_RAW_URL = function() {
  return '/organization'
}
export const createOrganizationUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrganizationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateOrganization
 * request: updateOrganizationUsingPUT
 * url: updateOrganizationUsingPUTURL
 * method: updateOrganizationUsingPUT_TYPE
 * raw_url: updateOrganizationUsingPUT_RAW_URL
 * @param organizationDto - organizationDTO
 */
export const updateOrganizationUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['organizationDto'] !== undefined) {
    body = parameters['organizationDto']
  }
  if (parameters['organizationDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organizationDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateOrganizationUsingPUT_RAW_URL = function() {
  return '/organization'
}
export const updateOrganizationUsingPUT_TYPE = function() {
  return 'put'
}
export const updateOrganizationUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteOrganization
 * request: deleteOrganizationUsingDELETE
 * url: deleteOrganizationUsingDELETEURL
 * method: deleteOrganizationUsingDELETE_TYPE
 * raw_url: deleteOrganizationUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteOrganizationUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/delete'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteOrganizationUsingDELETE_RAW_URL = function() {
  return '/organization/delete'
}
export const deleteOrganizationUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteOrganizationUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/delete'
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * organizationExistsByName
 * request: organizationExistsByNameUsingGET
 * url: organizationExistsByNameUsingGETURL
 * method: organizationExistsByNameUsingGET_TYPE
 * raw_url: organizationExistsByNameUsingGET_RAW_URL
 * @param name - name
 */
export const organizationExistsByNameUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/exists'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organizationExistsByNameUsingGET_RAW_URL = function() {
  return '/organization/exists'
}
export const organizationExistsByNameUsingGET_TYPE = function() {
  return 'get'
}
export const organizationExistsByNameUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/exists'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getOrganizationsList
 * request: getOrganizationsListUsingGET
 * url: getOrganizationsListUsingGETURL
 * method: getOrganizationsListUsingGET_TYPE
 * raw_url: getOrganizationsListUsingGET_RAW_URL
 */
export const getOrganizationsListUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/list'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getOrganizationsListUsingGET_RAW_URL = function() {
  return '/organization/list'
}
export const getOrganizationsListUsingGET_TYPE = function() {
  return 'get'
}
export const getOrganizationsListUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/list'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getMyOrganizationData
 * request: getMyOrganizationDataUsingGET
 * url: getMyOrganizationDataUsingGETURL
 * method: getMyOrganizationDataUsingGET_TYPE
 * raw_url: getMyOrganizationDataUsingGET_RAW_URL
 */
export const getMyOrganizationDataUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/my'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getMyOrganizationDataUsingGET_RAW_URL = function() {
  return '/organization/my'
}
export const getMyOrganizationDataUsingGET_TYPE = function() {
  return 'get'
}
export const getMyOrganizationDataUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/my'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchOrganization
 * request: searchOrganizationUsingPOST
 * url: searchOrganizationUsingPOSTURL
 * method: searchOrganizationUsingPOST_TYPE
 * raw_url: searchOrganizationUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchOrganizationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/search/organization'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchOrganizationUsingPOST_RAW_URL = function() {
  return '/organization/search/organization'
}
export const searchOrganizationUsingPOST_TYPE = function() {
  return 'post'
}
export const searchOrganizationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/search/organization'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchOrganizationCount
 * request: searchOrganizationCountUsingPOST
 * url: searchOrganizationCountUsingPOSTURL
 * method: searchOrganizationCountUsingPOST_TYPE
 * raw_url: searchOrganizationCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchOrganizationCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/search/organization/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchOrganizationCountUsingPOST_RAW_URL = function() {
  return '/organization/search/organization/count'
}
export const searchOrganizationCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchOrganizationCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/search/organization/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getGoogleAnalyticsCode
 * request: getGoogleAnalyticsCodeUsingGET
 * url: getGoogleAnalyticsCodeUsingGETURL
 * method: getGoogleAnalyticsCodeUsingGET_TYPE
 * raw_url: getGoogleAnalyticsCodeUsingGET_RAW_URL
 */
export const getGoogleAnalyticsCodeUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/public/google-analytics-code'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getGoogleAnalyticsCodeUsingGET_RAW_URL = function() {
  return '/public/google-analytics-code'
}
export const getGoogleAnalyticsCodeUsingGET_TYPE = function() {
  return 'get'
}
export const getGoogleAnalyticsCodeUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/public/google-analytics-code'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getPopup
 * request: getPopupUsingGET
 * url: getPopupUsingGETURL
 * method: getPopupUsingGET_TYPE
 * raw_url: getPopupUsingGET_RAW_URL
 */
export const getPopupUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/public/popup'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPopupUsingGET_RAW_URL = function() {
  return '/public/popup'
}
export const getPopupUsingGET_TYPE = function() {
  return 'get'
}
export const getPopupUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/public/popup'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getPrivacyPolicy
 * request: getPrivacyPolicyUsingGET
 * url: getPrivacyPolicyUsingGETURL
 * method: getPrivacyPolicyUsingGET_TYPE
 * raw_url: getPrivacyPolicyUsingGET_RAW_URL
 */
export const getPrivacyPolicyUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/public/privacy-policy'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPrivacyPolicyUsingGET_RAW_URL = function() {
  return '/public/privacy-policy'
}
export const getPrivacyPolicyUsingGET_TYPE = function() {
  return 'get'
}
export const getPrivacyPolicyUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/public/privacy-policy'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * publicShareEvent
 * request: publicShareEventUsingGET
 * url: publicShareEventUsingGETURL
 * method: publicShareEventUsingGET_TYPE
 * raw_url: publicShareEventUsingGET_RAW_URL
 * @param id - id
 */
export const publicShareEventUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/public/share/event/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const publicShareEventUsingGET_RAW_URL = function() {
  return '/public/share/event/{id}'
}
export const publicShareEventUsingGET_TYPE = function() {
  return 'get'
}
export const publicShareEventUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/public/share/event/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getUseOfTermsText
 * request: getUseOfTermsTextUsingGET
 * url: getUseOfTermsTextUsingGETURL
 * method: getUseOfTermsTextUsingGET_TYPE
 * raw_url: getUseOfTermsTextUsingGET_RAW_URL
 */
export const getUseOfTermsTextUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/public/terms-of-use'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUseOfTermsTextUsingGET_RAW_URL = function() {
  return '/public/terms-of-use'
}
export const getUseOfTermsTextUsingGET_TYPE = function() {
  return 'get'
}
export const getUseOfTermsTextUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/public/terms-of-use'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * registerUser
 * request: registerUserUsingPOST
 * url: registerUserUsingPOSTURL
 * method: registerUserUsingPOST_TYPE
 * raw_url: registerUserUsingPOST_RAW_URL
 * @param registrationData - registrationData
 */
export const registerUserUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/register'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['registrationData'] !== undefined) {
    body = parameters['registrationData']
  }
  if (parameters['registrationData'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: registrationData'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const registerUserUsingPOST_RAW_URL = function() {
  return '/register'
}
export const registerUserUsingPOST_TYPE = function() {
  return 'post'
}
export const registerUserUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/register'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * verifyEmail
 * request: verifyEmailUsingPOST
 * url: verifyEmailUsingPOSTURL
 * method: verifyEmailUsingPOST_TYPE
 * raw_url: verifyEmailUsingPOST_RAW_URL
 * @param token - token
 */
export const verifyEmailUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/register/verify-email'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['token'] !== undefined) {
    queryParameters['token'] = parameters['token']
  }
  if (parameters['token'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: token'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const verifyEmailUsingPOST_RAW_URL = function() {
  return '/register/verify-email'
}
export const verifyEmailUsingPOST_TYPE = function() {
  return 'post'
}
export const verifyEmailUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/register/verify-email'
  if (parameters['token'] !== undefined) {
    queryParameters['token'] = parameters['token']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * reportAbuse
 * request: reportAbuseUsingPOST
 * url: reportAbuseUsingPOSTURL
 * method: reportAbuseUsingPOST_TYPE
 * raw_url: reportAbuseUsingPOST_RAW_URL
 * @param reportAbuseDto - reportAbuseDTO
 */
export const reportAbuseUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/report-abuse'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['reportAbuseDto'] !== undefined) {
    body = parameters['reportAbuseDto']
  }
  if (parameters['reportAbuseDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: reportAbuseDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const reportAbuseUsingPOST_RAW_URL = function() {
  return '/report-abuse'
}
export const reportAbuseUsingPOST_TYPE = function() {
  return 'post'
}
export const reportAbuseUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/report-abuse'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * uploadFiles
 * request: uploadFilesUsingPOST
 * url: uploadFilesUsingPOSTURL
 * method: uploadFilesUsingPOST_TYPE
 * raw_url: uploadFilesUsingPOST_RAW_URL
 * @param files - files
 */
export const uploadFilesUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/static-file/upload'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['files'] !== undefined) {
    body = parameters['files']
  }
  if (parameters['files'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: files'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const uploadFilesUsingPOST_RAW_URL = function() {
  return '/static-file/upload'
}
export const uploadFilesUsingPOST_TYPE = function() {
  return 'post'
}
export const uploadFilesUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/static-file/upload'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getSystemSettings
 * request: getSystemSettingsUsingGET
 * url: getSystemSettingsUsingGETURL
 * method: getSystemSettingsUsingGET_TYPE
 * raw_url: getSystemSettingsUsingGET_RAW_URL
 */
export const getSystemSettingsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/systemSettings'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSystemSettingsUsingGET_RAW_URL = function() {
  return '/systemSettings'
}
export const getSystemSettingsUsingGET_TYPE = function() {
  return 'get'
}
export const getSystemSettingsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/systemSettings'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateSystemParameter
 * request: updateSystemParameterUsingPOST
 * url: updateSystemParameterUsingPOSTURL
 * method: updateSystemParameterUsingPOST_TYPE
 * raw_url: updateSystemParameterUsingPOST_RAW_URL
 * @param systemSettingsDto - systemSettingsDTO
 */
export const updateSystemParameterUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/systemSettings'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['systemSettingsDto'] !== undefined) {
    body = parameters['systemSettingsDto']
  }
  if (parameters['systemSettingsDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: systemSettingsDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateSystemParameterUsingPOST_RAW_URL = function() {
  return '/systemSettings'
}
export const updateSystemParameterUsingPOST_TYPE = function() {
  return 'post'
}
export const updateSystemParameterUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/systemSettings'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getSystemSettingsAddressAndPhoneNumber
 * request: getSystemSettingsAddressAndPhoneNumberUsingGET
 * url: getSystemSettingsAddressAndPhoneNumberUsingGETURL
 * method: getSystemSettingsAddressAndPhoneNumberUsingGET_TYPE
 * raw_url: getSystemSettingsAddressAndPhoneNumberUsingGET_RAW_URL
 */
export const getSystemSettingsAddressAndPhoneNumberUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/systemSettings/address-and-phone-number'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSystemSettingsAddressAndPhoneNumberUsingGET_RAW_URL = function() {
  return '/systemSettings/address-and-phone-number'
}
export const getSystemSettingsAddressAndPhoneNumberUsingGET_TYPE = function() {
  return 'get'
}
export const getSystemSettingsAddressAndPhoneNumberUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/systemSettings/address-and-phone-number'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getSystemSettingsCookiesPolicyText
 * request: getSystemSettingsCookiesPolicyTextUsingGET
 * url: getSystemSettingsCookiesPolicyTextUsingGETURL
 * method: getSystemSettingsCookiesPolicyTextUsingGET_TYPE
 * raw_url: getSystemSettingsCookiesPolicyTextUsingGET_RAW_URL
 */
export const getSystemSettingsCookiesPolicyTextUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/systemSettings/cookies-policy-text'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSystemSettingsCookiesPolicyTextUsingGET_RAW_URL = function() {
  return '/systemSettings/cookies-policy-text'
}
export const getSystemSettingsCookiesPolicyTextUsingGET_TYPE = function() {
  return 'get'
}
export const getSystemSettingsCookiesPolicyTextUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/systemSettings/cookies-policy-text'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * emailExist
 * request: emailExistUsingGET
 * url: emailExistUsingGETURL
 * method: emailExistUsingGET_TYPE
 * raw_url: emailExistUsingGET_RAW_URL
 * @param email - email
 */
export const emailExistUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/email-exists'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['email'] !== undefined) {
    queryParameters['email'] = parameters['email']
  }
  if (parameters['email'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: email'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const emailExistUsingGET_RAW_URL = function() {
  return '/user/email-exists'
}
export const emailExistUsingGET_TYPE = function() {
  return 'get'
}
export const emailExistUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/email-exists'
  if (parameters['email'] !== undefined) {
    queryParameters['email'] = parameters['email']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteMyAccount
 * request: deleteMyAccountUsingDELETE
 * url: deleteMyAccountUsingDELETEURL
 * method: deleteMyAccountUsingDELETE_TYPE
 * raw_url: deleteMyAccountUsingDELETE_RAW_URL
 */
export const deleteMyAccountUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/my-account'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteMyAccountUsingDELETE_RAW_URL = function() {
  return '/user/my-account'
}
export const deleteMyAccountUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteMyAccountUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/my-account'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateMyData
 * request: updateMyDataUsingPOST
 * url: updateMyDataUsingPOSTURL
 * method: updateMyDataUsingPOST_TYPE
 * raw_url: updateMyDataUsingPOST_RAW_URL
 * @param userDto - userDTO
 */
export const updateMyDataUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/my-data'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['userDto'] !== undefined) {
    body = parameters['userDto']
  }
  if (parameters['userDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: userDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateMyDataUsingPOST_RAW_URL = function() {
  return '/user/my-data'
}
export const updateMyDataUsingPOST_TYPE = function() {
  return 'post'
}
export const updateMyDataUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/my-data'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getUserPreferences
 * request: getUserPreferencesUsingGET
 * url: getUserPreferencesUsingGETURL
 * method: getUserPreferencesUsingGET_TYPE
 * raw_url: getUserPreferencesUsingGET_RAW_URL
 */
export const getUserPreferencesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/preferences'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUserPreferencesUsingGET_RAW_URL = function() {
  return '/user/preferences'
}
export const getUserPreferencesUsingGET_TYPE = function() {
  return 'get'
}
export const getUserPreferencesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/preferences'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchOrganizer
 * request: searchOrganizerUsingPOST
 * url: searchOrganizerUsingPOSTURL
 * method: searchOrganizerUsingPOST_TYPE
 * raw_url: searchOrganizerUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchOrganizerUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/search/organizer'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchOrganizerUsingPOST_RAW_URL = function() {
  return '/user/search/organizer'
}
export const searchOrganizerUsingPOST_TYPE = function() {
  return 'post'
}
export const searchOrganizerUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/search/organizer'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchOrganizerCount
 * request: searchOrganizerCountUsingPOST
 * url: searchOrganizerCountUsingPOSTURL
 * method: searchOrganizerCountUsingPOST_TYPE
 * raw_url: searchOrganizerCountUsingPOST_RAW_URL
 * @param organizerSearchCriteria - organizerSearchCriteria
 */
export const searchOrganizerCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/search/organizer/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['organizerSearchCriteria'] !== undefined) {
    body = parameters['organizerSearchCriteria']
  }
  if (parameters['organizerSearchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organizerSearchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchOrganizerCountUsingPOST_RAW_URL = function() {
  return '/user/search/organizer/count'
}
export const searchOrganizerCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchOrganizerCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/search/organizer/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchUser
 * request: searchUserUsingPOST
 * url: searchUserUsingPOSTURL
 * method: searchUserUsingPOST_TYPE
 * raw_url: searchUserUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchUserUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/search/user'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchUserUsingPOST_RAW_URL = function() {
  return '/user/search/user'
}
export const searchUserUsingPOST_TYPE = function() {
  return 'post'
}
export const searchUserUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/search/user'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchUserCount
 * request: searchUserCountUsingPOST
 * url: searchUserCountUsingPOSTURL
 * method: searchUserCountUsingPOST_TYPE
 * raw_url: searchUserCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchUserCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/search/user/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchUserCountUsingPOST_RAW_URL = function() {
  return '/user/search/user/count'
}
export const searchUserCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchUserCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/search/user/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateUser
 * request: createOrUpdateUserUsingPOST
 * url: createOrUpdateUserUsingPOSTURL
 * method: createOrUpdateUserUsingPOST_TYPE
 * raw_url: createOrUpdateUserUsingPOST_RAW_URL
 * @param userDto - userDTO
 */
export const createOrUpdateUserUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/user'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['userDto'] !== undefined) {
    body = parameters['userDto']
  }
  if (parameters['userDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: userDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateUserUsingPOST_RAW_URL = function() {
  return '/user/user'
}
export const createOrUpdateUserUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateUserUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/user'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteUser
 * request: deleteUserUsingDELETE
 * url: deleteUserUsingDELETEURL
 * method: deleteUserUsingDELETE_TYPE
 * raw_url: deleteUserUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteUserUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/user/delete'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteUserUsingDELETE_RAW_URL = function() {
  return '/user/user/delete'
}
export const deleteUserUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteUserUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/user/delete'
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * changePassword
 * request: changePasswordUsingPOST
 * url: changePasswordUsingPOSTURL
 * method: changePasswordUsingPOST_TYPE
 * raw_url: changePasswordUsingPOST_RAW_URL
 * @param passwordChangeRequest - passwordChangeRequest
 */
export const changePasswordUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/user/password/change'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['passwordChangeRequest'] !== undefined) {
    body = parameters['passwordChangeRequest']
  }
  if (parameters['passwordChangeRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: passwordChangeRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const changePasswordUsingPOST_RAW_URL = function() {
  return '/user/user/password/change'
}
export const changePasswordUsingPOST_TYPE = function() {
  return 'post'
}
export const changePasswordUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/user/password/change'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * remindPassword
 * request: remindPasswordUsingPOST
 * url: remindPasswordUsingPOSTURL
 * method: remindPasswordUsingPOST_TYPE
 * raw_url: remindPasswordUsingPOST_RAW_URL
 * @param passwordRemindRequest - passwordRemindRequest
 */
export const remindPasswordUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/user/password/remind'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['passwordRemindRequest'] !== undefined) {
    body = parameters['passwordRemindRequest']
  }
  if (parameters['passwordRemindRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: passwordRemindRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const remindPasswordUsingPOST_RAW_URL = function() {
  return '/user/user/password/remind'
}
export const remindPasswordUsingPOST_TYPE = function() {
  return 'post'
}
export const remindPasswordUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/user/password/remind'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getUser
 * request: getUserUsingGET
 * url: getUserUsingGETURL
 * method: getUserUsingGET_TYPE
 * raw_url: getUserUsingGET_RAW_URL
 * @param id - id
 */
export const getUserUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/user/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUserUsingGET_RAW_URL = function() {
  return '/user/user/{id}'
}
export const getUserUsingGET_TYPE = function() {
  return 'get'
}
export const getUserUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/user/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}