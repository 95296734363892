<template>
    <div class="panel">
        <div class="top-panel">
            <div class="code">
                404
            </div>
        </div>
        <div class="bottom-panel">
            <div class="icon">
                <i class="pi pi-exclamation-triangle"/>
            </div>
            <div class="content">
                <h3>Nie znalezionio strony</h3>
                <span>Prosimy skontaktować się z administratorem systemu</span>
                <br/>
                <br/>
                <router-link class="redirect-link" tag="div" :to="{name: 'search'}">
                    Powrót na stronę główną
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";

    export default {
        name: "NotFoundView",
        components: {Button},
    };
</script>

<style lang="less" scoped>
    @import "assets/less/status-page.less";

    .panel {
        .top-panel {
            background-color: deeppink;
            .code {
                color: #ff86d6;
            }
        }

        .bottom-panel {
            .icon {
                background-color: deeppink;
            }
        }
    }
</style>
