/* eslint-disable */
import axios from 'axios'

export const FileUtils = {

    //TODO: przenieść do env
    acceptedImageFormats: ["jpg", "jpeg", "png"],

    validateFileSize(file) {
      return file.size <= process.env.VUE_APP_MAX_IMAGE_SIZE_IN_MB * 1024 * 1024;
    },

    maxImagesSizeString() {
        return process.env.VUE_APP_MAX_IMAGE_SIZE_IN_MB + " MB"
    },

    acceptedImageFormatsAsString() {
        return this.acceptedImageFormats.join(", ");
    },

    validateImageFormat(file) {
        return this.validateFileFormat(file, this.acceptedImageFormats)
    },

    validateFileFormat(file, formats) {
        if (file === null) {
            return false;
        }

        let extension = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length) || file.name;
        return formats.includes(extension);
    },

    forceFileDownload(response, fileName) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },

    downloadFile(query, fileName, url) {
        axios({
            url: process.env.VUE_APP_API_URL + url,
            method: 'post',
            responseType: 'blob',
            data: query,
        }).then((response) => {
            this.forceFileDownload(response, fileName);
        });
    },

    downloadFileUsingGET(fileName, url) {
        axios({
            url: process.env.VUE_APP_API_URL + url,
            method: 'get',
            responseType: 'blob',
        }).then((response) => {
            this.forceFileDownload(response, fileName);
        });
    },

    validateImageSize(file) {

    },

    async fileToBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    },
};
