<template>
    <div class="form-view user-form-view">
        <Form @submit="saveUser" v-slot="{ isSubmitting}">
            <div class="page-header">
                <div class="page-header-text">
                    <h1 class="header-text">{{this.title}}</h1>
                    <SaveButton class="button button-filled-white" type="submit" :disabled="isSubmitting"/>
                </div>
            </div>
            <div class="data general-container">
                <div class="row">
                    <div class="label">Imię</div>
                    <CustomInputText name="firstName" v-model="user.firstName" no-icon label="imię" width="500"
                                     rules="firstNameLength|required"/>
                </div>
                <div class="row">
                    <div class="label">Nazwisko</div>
                    <CustomInputText name="lastName" v-model="user.lastName" no-icon label="nazwisko" width="500"
                                     rules="lastNameLength|required"/>
                </div>
                <div class="row">
                    <div class="label">Email</div>
                    <CustomInputText name="email"
                                     v-model="user.email"
                                     no-icon label="adres email"
                                     width="500"
                                     rules="emailLength|email|required"
                                     :disabled="computedDisabledEmail"/>
                </div>
                <div class="row">
                    <div class="label">Telefon</div>
                    <CustomInputMask name="phoneNumber" v-model="user.phoneNumber" label="telefon kontaktowy"
                                     width="500" mask="(+48) 999 999 999"/>
                </div>
                <div>
                    <div class="row">
                        <div class="label">{{ passwordLabel }}</div>
                        <CustomPassword name="password" v-model="changedPassword"
                                        :label="$t('message.register.password')" width="400"
                                        :rules="computedPasswordRules" :feedback="true"
                                        autocomplete="new-password"/>
                    </div>
                    <div class="row">
                        <div class="label">Powtórz hasło</div>
                        <CustomPassword name="password2" v-model="changedRepeatedPassword"
                                        :label="$t('message.register.password2')" width="400"
                                        :rules="computedRepeatPasswordRules"/>
                    </div>
                </div>
                <div class="row">
                    <div class="label">Dzielnice</div>
                    <DesignedMultiSelectDistrict name="userDistricts"
                                                 v-model="user.userDistricts"
                                                 label="dzielnice"
                                                 width="500"/>
                </div>
                <div class="user-form-specify-item">
                    <span  v-show="showDistrictValidationMessage"
                           id="district-validate-message" class="p-invalid">
                        Należy wybrać co najmniej jedną dzielnicę
                    </span>
                </div>
                <div class="row">
                    <div class="label activities-label">Aktywności</div>
                    <div class="activities">
                        <CustomCheckbox v-for="activity in allActivitiesList" :name="'activity' + activity.id"
                                        v-bind:key="activity.id" :label="activity.name"
                                        v-model="activity.interested" class="checkbox"/>
                    </div>
                </div>
                <div class="user-form-specify-item">
                    <span v-show="showActivitiesValidationMessage"
                          id="activity-validate-message" class="p-invalid">
                        Należy wybrać co najmniej trzy aktywności
                    </span>
                </div>
                <div class="admin-form-view-buttons">
                    <SaveButton type="submit" :disabled="isSubmitting"/>
                    <DeleteButton @click="deleteUser(user.id, $event)" :disabled="isSubmitting"/>
                </div>
            </div>
        </Form>
    </div>
</template>

<script>
    import {Form} from "vee-validate";
    import {
        createOrUpdateUserUsingPOST as createOrUpdateUser,
        deleteMyAccountUsingDELETE as deleteMyAccount,
        deleteUserUsingDELETE as deleteUser,
        emailExistUsingGET as emailExist,
        getAllVisibleEventsUsingGET as getAllVisibleEventTypes,
        getUserUsingGET as getUser,
        updateMyDataUsingPOST as updateMyData,
    } from "@/swagger/vue-api-client";
    import CustomInputText from "../../components/form/CustomInputText";
    import {SystemRole} from "../../utils/SystemRole";
    import CustomCheckbox from "@/components/form/CustomCheckbox";
    import CustomInputMask from "@/components/form/CustomInputMask";
    import SaveButton from "@/components/form/SaveButton";
    import DesignedMultiSelectDistrict from "@/components/form/multiselect/DesignedMultiSelectDistrict";
    import DeleteButton from "@/components/form/DeleteButton";
    import CustomPassword from "@/components/form/CustomPassword";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "UserFormView",

        components: {
            DeleteButton,
            DesignedMultiSelectDistrict,
            SaveButton,
            CustomInputMask,
            CustomCheckbox,
            CustomInputText,
            Form,
            CustomPassword,
        },

        props: {
            value: {},
            create: false,
        },

        data() {
            return {
                allActivitiesList: [],
                user: {
                    id: null,
                    firstName: null,
                    lastName: null,
                    email: null,
                    phoneNumber: null,
                    userDistricts: [],
                    emailVerified: false,
                    favoriteActivities: [],
                    favoriteActivitiesIds: [],
                    password: null,
                    systemRole: SystemRole.USER,
                },
                changedPassword: null,
                changedRepeatedPassword: null,
                showActivitiesValidationMessage: false,
                showDistrictValidationMessage: false,
                loaded: false,
                registering: false,
                title: "Edycja konta użytkownika",
            };
        },

        async beforeMount() {
            if (SystemRole.isUser() && this.$route.name === "userEdit"
                && !(this.$route.params.id === localStorage.getItem("id").toString())) {
                this.$router.push({name: "userEdit", params: {id: localStorage.getItem("id")}});
            }

            await this.getActivityList();

            if (this.create) {
                this.title = "Tworzenie konta użytkownika";
            }

            this.$watch("user.userDistricts", this.showDistrictsErrorMessage, {deep: true});
            this.$watch("allActivitiesList", this.showActivitiesErrorMessage, {deep: true});

            if (this.$route.params.id) {
                getUser({id: this.$route.params.id}).then((response) => {
                    this.user.id = response.data.id;
                    this.user.firstName = response.data.firstName;
                    this.user.lastName = response.data.lastName;
                    this.user.email = response.data.email;
                    this.user.password = response.data.password;
                    this.user.systemRole = response.data.systemRole;
                    this.user.userDistricts = response.data.userDistricts;
                    this.user.phoneNumber = response.data.phoneNumber;
                    this.user.emailVerified = response.data.emailVerified;
                    this.user.favoriteActivities = response.data.favoriteActivities;
                    this.allActivitiesList.forEach((activity) => {
                        if (this.user.favoriteActivities
                            .some((favoriteActivity) => activity.id === favoriteActivity.id)) {
                            activity.interested = true;
                        }
                    });
                    this.loaded = true;
                }).catch((error) => {
                    if (error.response.status === 403) {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Nie masz wystarczających uprawnień",
                            life: 3000,
                        });
                    } else {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd",
                            life: 3000,
                        });
                    }
                });
            } else {
                this.loaded = true;
            }
        },

        computed: {
            computedDisabledEmail() {
                return !this.create;
            },
            computedPasswordRules() {
                return this.create || ((this.changedPassword != null) && (this.changedPassword !== ""))
                    ? "required|validOptionalPassword" : "validOptionalPassword";
            },
            computedRepeatPasswordRules() {
                return this.create || ((this.changedPassword != null) && (this.changedPassword !== ""))
                    ? "required|confirmed:@password,Hasła" : "confirmed:@password,Hasła";
            },
            passwordLabel() {
                return this.create ? "Hasło" : "Zmiana hasła";
            },
        },

        methods: {
            async saveUser(value, actions) {
                this.registering = true;
                let valid = true;

                const func = SystemRole.isAdmin() ? createOrUpdateUser : updateMyData;

                if (!this.activitiesValid(this.allActivitiesList)) {
                    this.showActivitiesValidationMessage = true;
                    valid = false;
                }
                if (!this.districtsValid(this.user.userDistricts)) {
                    this.showDistrictValidationMessage = true;
                    valid = false;
                }

                if (this.create) {
                    await emailExist({email: this.user.email})
                        .then((response) => {
                            if (response.data) {
                                actions.setFieldError("email", "Istnieje już użytkownik o takim adresie email");
                                valid = false;
                            }
                        }).catch(() => {
                            this.showErrorToast();
                            valid = false;
                        });
                }

                if (!valid) {
                    this.registering = false;
                    return;
                }

                if (this.changedPassword != null && this.changedPassword !== "") {
                    this.user.password = this.changedPassword;
                }

                this.user.favoriteActivitiesIds = this.allActivitiesList
                    .filter((activity) => activity.interested === true)
                    .map((activity) => activity.id);

                await func({userDto: this.user})
                    .then((response) => {
                        this.$toast.add({
                            severity: "success",
                            summary: "Sukces",
                            detail: "Zapisano dane użytkownika",
                            life: 3000,
                        });
                        if (this.create) {
                            this.$router.push({name: "userEdit", params: {id: response.data.id}});
                        }
                    }).catch(() => {
                        ToastUtils.showError(this, "Nastąpił nieoczekiwany błąd",
                                             "Prosimy o kontakt z administratorem");
                        valid = false;
                    });
            },

            async getActivityList() {
                await getAllVisibleEventTypes().then((response) => {
                    this.allActivitiesList = response.data;
                }).catch(() => {
                    this.showGetActivityListErrorToast();
                });
            },

            showGetActivityListErrorToast() {
                this.$toast.add({
                    severity: "info",
                    summary: "Nie można pobrać listy aktywności",
                    detail: "Prosimy o kontakt z administratorem",
                    life: 3000,
                });
            },

            activitiesValid(activities) {
                return activities.filter((activity) => activity.interested === true).length >= 3;
            },

            districtsValid(districts) {
                return districts.length >= 1;
            },

            showActivitiesErrorMessage(activities) {
                this.showActivitiesValidationMessage = !this.activitiesValid(activities);
            },

            showDistrictsErrorMessage(districts) {
                this.showDistrictValidationMessage = !this.districtsValid(districts);
            },

            deleteUser(id, event) {
                this.$confirm.require({
                    target: event.currentTarget,
                    header: "Czy na pewno usunąć konto?",
                    message: "Tej operacji nie będzie można cofnąć.",
                    acceptLabel: "Tak",
                    rejectLabel: "Nie",
                    position: "topright",
                    accept: () => {
                        const func = SystemRole.isAdmin() ? deleteUser({id}) : deleteMyAccount();
                        func.then(() => {
                            this.$toast.add({
                                severity: "success",
                                summary: "Sukces",
                                detail: "Pomyślnie usunięto konto",
                                life: 3000,
                            });
                            // Jeśli użytkownik usuwa swoje konto to go wylogowujemy
                            if (!SystemRole.isAdmin()) {
                                SystemRole.logout();
                            }
                            this.$router.push("/");
                        }).catch((error) => {
                            if (error.response.status === 403) {
                                this.$toast.add({
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Nie masz wystarczających uprawnień",
                                    life: 3000,
                                });
                            } else {
                                this.$toast.add({
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd",
                                    life: 3000,
                                });
                            }
                        });
                    },
                });
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "src/views/assets/less/form-view";

    .user-form-specify-item {
        margin-bottom: 25px;
    }
</style>
