<template>
    <div class="p-fluid custom-input-text" :style="'width: ' + width + 'px;'">
        <span v-if="noIcon" class="p-float-label">
            <InputMask :id="name" :name="name" :mask="mask" v-model="internalValue"
                       :placeholder="internalValue ? '' : label" type="text"
                       @input="handleChange" @blur="handleBlur" :aria-label="name"/>
            <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
        </span>
        <span v-if="!noIcon" class="p-float-label p-input-icon-left input-outer-icon">
            <i :class="icon" />
            <InputMask :id="name" :name="name" :mask="mask" v-model="internalValue"
                       :placeholder="internalValue ? '' : label" type="text"
                       @input="handleChange" @blur="handleBlur" :aria-label="name"/>
            <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
        </span>
    </div>
</template>

<script>
    import InputMask from "primevue/inputmask";
    import Tooltip from "primevue/tooltip";
    import {useField} from "vee-validate";

    export default {
        name: "CustomInputMask",

        props: {
            name: {
                type: String,
            },
            mask: {
                type: String,
            },
            label: {
                type: String,
            },
            width: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            rules: undefined,
            modelValue: null,
            noIcon: {
                type: Boolean,
                default: true,
            },
            icon: {
                type: String,
                default: "pi pi-phone",
            },
        },

        emits: ["update:modelValue"],

        directives: {
            tooltip: Tooltip,
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.inputValue = value;
                    this.$emit("update:modelValue", value);
                },
            },

            computedClasses() {
                return this.errorMessage ? "p-invalid" : "";
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

        components: {InputMask},
    };
</script>

<style lang="less" scoped>
    @import "../../assets/theme/variable";
    .custom-input-text {
        .input-outer-icon {
            .p-inputmask {
                padding-left: 45px;
            }
        }
        .p-inputmask {
            background-color: @color-white;
            border-radius: 30px;
            padding: 1rem 1.5rem;

            box-shadow: rgba(0, 0, 0, 8%) 0px 1px 2px, rgba(0, 0, 0, 5%) 0px 4px 12px;
            border: 1px solid rgb(221, 221, 221);
            &:enabled:focus {
                box-shadow: none;
            }

            &:focus {
                box-shadow: none;
            }
            .p-multiselect-label {
                padding: 0;
            }
        }
        label {
            font-size: 12px;
            color: #898989;
            top: -10px;
            background-color: #ffffff;
            padding: 2px 4px;
            margin-left: -4px;
            margin-top: 0;
            position: absolute;
            left: 1rem;
            z-index: 2;
        }

        .p-multiselect {
            width: 100%;
        }
    }
</style>
