<template>
    <div class="select-organization">
        <DesignedSelectOne id="status" name="status" :items="items" v-model="internalValue"
                           item-label="label" item-value="value" :empty-value="false"
                           @change="handleChange" :label="label" :width="width"
                           :rules="rules" optionDisabled="disabled" :disabled="disabled"
        />
        <label for="status">{{ label }}</label>
    </div>
</template>

<script>
    import {useField} from "vee-validate";
    import DesignedSelectOne from "../inner/DesignedSelectOne";
    import {SystemRole} from "@/utils/SystemRole";
    import {getAllEventStatusesUsingGET as getStatuses} from "@/swagger/vue-api-client";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "DesignedSelectEventStatus",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            icon: {
                type: String,
                default: "pi pi-search",
            },
            width: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            rules: undefined,
            modelValue: null,
        },

        emits: ["update:modelValue"],

        data() {
            return {
                items: [],
            };
        },

        beforeMount() {
            getStatuses({}).then((response) => {
                this.items = response.data.map((obj) => ({...obj, disabled: this.filterStatusesByRole(obj)}));
            }).catch(() => {
                ToastUtils.showError(this);
            });
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },

            computedClasses() {
                return this.errorMessage ? "p-invalid" : "";
            },
        },

        methods: {
            filterStatusesByRole(item) {
                return SystemRole.isOrganizer() && (item.value === "BLOCKED" || item.value === "NEW"
                    || item.value === "IMPORTED");
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

        components: {DesignedSelectOne},
    };
</script>

<style lang="less" scoped>
    .select-organization {
        label {
            display: none;
        }
    }
</style>
