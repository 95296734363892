<template>
    <div class="form-readonly-view" v-if="loaded">
        <div class="form-readonly-view-header">
            <h1>{{ event.name }}</h1>
        </div>
        <div class="general-container">
            <div class="form-readonly-view-left">
                <div class="data">
                    <div class="row-outer-short-info">
                        <div class="row row-for-desktop">
                            <span class="calendar-icon"/>
                            <div class="row-text">
                                <div class="label">Termin</div>
                                <div class="value value-link" style="white-space: pre">
                                    {{getTermLabel()}}
                                </div>
                            </div>
                        </div>
                        <div class="row row-for-desktop">
                            <span class="pin-icon"/>
                            <div class="row-text">
                                <div class="label">Miejsce</div>
                                <div class="value value-link">
                                    {{ event.address }}
                                </div>
                            </div>
                        </div>
                        <div class="row row-for-desktop">
                            <span class="phone-icon"/>
                            <div class="row-text">
                                <div class="label">Telefon</div>
                                <div class="value">
                                    <a class="link-tel" :href="'tel:${event.phoneNumber}'">
                                        {{ event.phoneNumber }}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <span class="email-icon"/>
                            <div class="row-text">
                                <div class="label">Email</div>
                                <div class="value">{{ event.email }}</div>
                            </div>
                        </div>
                        <div class="row" v-if="event.termsSecondUrl">
                            <span class="statute-icon"/>
                            <div class="row-text map">
                                <div class="label">Drugi regulamin</div>
                                <div class="value value-link">
                                    <a :href="event.termsSecondUrl" target="_blank">Link do regulaminu</a>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <span class="link-icon"/>
                            <div class="row-text">
                                <div class="label">Strona</div>
                                <div class="value value-link">
                                    <a :href="event.eventPageUrl" target="_blank">{{ event.eventPageUrl }}</a>
                                </div>
                            </div>
                        </div>
                        <div class="row row-for-mobile">
                            <span class="organization-icon"/>
                            <div class="row-text">
                                <div class="label">Organizator</div>
                                <div class="value value-link">
                                    <router-link :to="{name: 'organization', params: {'id': event.organizationId}}"
                                                 v-if="event.organizationId">
                                        {{ event.organizationName }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="row row-for-mobile"
                             v-if="event.contactPersonName && event.contactPersonName !== ''">
                            <span class="phone-icon"/>
                            <div class="row-text">
                                <div class="label">Kontakt</div>
                                <div class="value value-link contact-person-name">
                                    {{ event.contactPersonName }}
                                </div>
                            </div>
                        </div>
                        <div class="row row-for-mobile" v-if="event.termsFirstUrl && event.termsFirstUrl !== ''">
                            <span class="statute-icon"/>
                            <div class="row-text">
                                <div class="label">Regulamin</div>
                                <div class="value value-link">
                                    <a :href="event.termsFirstUrl" target="_blank">Link do regulaminu</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row map-row">
                        <div class="row-text">
                            <span class="pin-icon"/>
                            <div class="label">Lokalizacja</div>
                            <EventsMap :events="[event]"  v-if="event.district !== 'ONLINE'" />
                            <div class="row-text" v-else>
                                <div class="value" >Wydarzenie online</div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row desc-row">
                        <div class="row-text">
                            <span class="desc-icon"/>
                            <div class="label">Opis</div>
                            <div class="value description">{{ event.description }}</div>
                        </div>
                    </div>
                    <div v-if="event.additionalInfo" class="row desc-row">
                        <div class="row-text">
                            <span class="desc-icon"/>
                            <div class="label">Informacje dodatkowe</div>
                            <div class="value additional-info description">{{ event.additionalInfo }}</div>
                        </div>
                    </div>
                    <div class="paragraph" v-if="!create && event.galleryFiles && event.galleryFiles.length > 0">
                        <h2>Galeria</h2>
                        <EventGallery :photos="event.galleryFiles" />
                    </div>
                </div>
                <report-abuse-dialog v-model:event-id="event.id" v-model:display-modal="displayModal"/>
            </div>
            <div class="form-readonly-view-right">
                <div class="form-readonly-view-right-inner">
                    <div class="image-container">
                        <img alt="Zdjęcie" :src="event.coverImageUrl ? event.coverImageUrl : altImg" />
                    </div>
                    <div class="form-readonly-view-right-info">
                        <div class="row row-for-desktop">
                            <div class="label">
                                <span class="organization-icon event-icon"></span>
                                <span class="form-readonly-view-right-label">Organizator</span>
                            </div>
                            <div class="value">
                                <router-link :to="{name: 'organization', params: {'id': event.organizationId}}"
                                             v-if="event.organizationId">
                                    {{ event.organizationName }}
                                </router-link>
                            </div>
                        </div>
                        <div class="row row-for-desktop"
                             v-if="event.contactPersonName && event.contactPersonName !== ''">
                            <div class="label">
                                <span class="phone-icon event-icon"></span>
                                <span class="form-readonly-view-right-label">Kontakt</span>
                            </div>
                            <div class="value contact-person-name">{{ event.contactPersonName }}</div>
                        </div>
                        <div class="row row-for-desktop" v-if="event.termsFirstUrl && event.termsFirstUrl !== ''">
                            <div class="label">
                                <span class="statute-icon event-icon"></span>
                                <span class="form-readonly-view-right-label">Regulamin</span>
                            </div>
                            <div class="value">
                                <a :href="event.termsFirstUrl" target="_blank">Link do regulaminu</a>
                            </div>
                        </div>
                        <div class="row row-for-mobile">
                            <div class="label">
                                <span class="calendar-icon event-icon"></span>
                                <span class="form-readonly-view-right-label">Termin</span>
                            </div>
                            <div class="value">
                                {{getTermLabel()}}
                            </div>
                        </div>
                        <div class="row row-for-mobile">
                            <div class="label">
                                <span class="pin-icon event-icon"></span>
                                <span class="form-readonly-view-right-label">Miejsce</span>
                            </div>
                            <div class="value">
                                {{ event.address }}
                            </div>
                        </div>
                        <div class="row row-for-mobile">
                            <div class="label">
                                <span class="phone-icon event-icon"></span>
                                <span class="form-readonly-view-right-label">Telefon</span>
                            </div>
                            <div class="value">
                                <a class="link-tel" :href="'tel:${event.phoneNumber}'">{{ event.phoneNumber }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="share-container">
                        <div>Udostępnij:</div>
                        <div class="social-buttons">
                            <a :href="'https://www.linkedin.com/sharing/share-offsite/?url=' + getSocialShareUrl()"
                               target="_blank">
                                <img alt="Udostępnij na linkedin" src="../../assets/icons/linkedin-icon.png" />
                            </a>
                            <a :href="'https://twitter.com/intent/tweet?size=medium&count=' +
                                'none&url=' + getSocialShareUrl()" target="_blank" >
                                <img alt="Udostępnij na twitter" src="../../assets/icons/twitter-icon.png" />
                            </a>
                            <a :href="'https://www.facebook.com/sharer/sharer.php?u=' + getSocialShareUrl()"
                               target="_blank">
                                <img alt="Udostępnij na facebook" src="../../assets/icons/fb-icon.png" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="report-abuse-container" @click="showAbuseReportDialog">
                    <a>
                        Zgłoś nadużycie
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import EventsMap from "@/views/event/components/EventsMap";
    import EventGallery from "@/views/event/components/EventGallery";
    import {getEventUsingGET} from "@/swagger/vue-api-client";
    import {EventUtils} from "@/utils/EventUtils";
    import ReportAbuseDialog from "@/views/event/components/ReportAbuseDialog";

    export default {
        name: "EventView",
        components: {ReportAbuseDialog, EventGallery, EventsMap},

        data() {
            return {
                event: null,
                loaded: false,
                displayModal: false,
                // eslint-disable-next-line global-require
                altImg: require("@/assets/notfoundphoto.png"),
            };
        },

        beforeMount() {
            if (this.$route.params.id) {
                getEventUsingGET({id: this.$route.params.id}).then((response) => {
                    this.event = response.data;
                    this.loaded = true;
                }).catch((error) => {
                    if (error.response.status === 403) {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Nie masz wystarczających uprawnień",
                            life: 3000,
                        });
                        this.$router.push({name: "forbidden"});
                    } else if (error.response.status === 404) {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Nie można zlokalizować takiego wydarzenia",
                            life: 3000,
                        });
                        this.$router.push({name: "notFound"});
                    } else {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd",
                            life: 3000,
                        });
                    }
                });
            }
        },

        methods: {
            getSocialShareUrl() {
                return process.env.VUE_APP_API_URL + "/public/share/event/" + this.event.id;
            },
            getTermLabel() {
                return EventUtils.getTermForEventView(this.event);
            },
            showAbuseReportDialog() {
                this.displayModal = true;
            },
        },
    };
</script>

<style lang="less">
    @import "../assets/less/form-readonly-view";
</style>
