<template>
    <div class="select-district">
        <DesignedSelectOne :id="name" :name="name" :items="items" v-model="internalValue"
                           item-label="label" item-value="value" :empty-value="false"
                           @change="handleChange" :label="label" :width="width"/>
    </div>
</template>

<script>
    import {useField} from "vee-validate";
    import DesignedSelectOne from "../inner/DesignedSelectOne";
    import {getAllDistrictsUsingGET} from "@/swagger/vue-api-client";

    export default {
        name: "DesignedSelectDistrict",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            icon: {
                type: String,
                default: "pi pi-search",
            },
            width: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            rules: undefined,
            modelValue: null,
        },

        emits: ["update:modelValue"],

        data() {
            return {
                items: [],
            };
        },

        mounted() {
            getAllDistrictsUsingGET()
                .then((response) => {
                    this.items = response.data;
                });
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },

            computedClasses() {
                return this.errorMessage ? "p-invalid" : "";
            },
        },

        watch: {
            modelValue() {
                this.inputValue = this.modelValue;
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

        components: {DesignedSelectOne},
    };
</script>

<style lang="less" scoped>
.select-district {
    label {
        display: none;
    }
}
</style>
