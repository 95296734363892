<template>
    <div class="list">
        <DataTable ref="importedEventAcceptanceViewTable" :value="events" :lazy="true" :paginator="true"
                   v-model:rows="searchParams.page.limit" v-model:first="searchParams.page.offset"
                   removableSort :totalRecords="totalRecords" :loading="loading" @page="onPage"
                   @sort="onPage" :rowsPerPageOptions="[5,10,20,50]" v-model:selection="selectedEvents"
                   :dataKey="events.id" responsiveLayout="scroll">
            <Column selectionMode="multiple" style="min-width: 3em"></Column>
            <Column field="logo" header="Logo">
                <template #body="slotProps">
                    <img v-if="slotProps.data.logoUrl !== null" :alt="`Brak zdjęcia`" class="list-logo"
                         :src="slotProps.data.logoUrl"/>
                </template>
            </Column>
            <Column field="name" header="Nazwa" :sortable="true">
                <template #body="slotProps">
                    <router-link :to="{name: 'event', params: {'id': slotProps.data.id}}"
                                 style="text-decoration: none;">
                        <Button class="p-button-outlined">
                            {{ slotProps.data.name }}
                        </Button>
                    </router-link>
                </template>
            </Column>
            <Column field="organizationName" header="Organizacja" :sortable="true">
                <template #body="slotProps">
                    <router-link :to="{name: 'organization', params: {'id': slotProps.data.organizationId}}"
                                 style="text-decoration: none;">
                        <Button class="p-button-outlined">
                            {{ slotProps.data.organizationName }}
                        </Button>
                    </router-link>
                </template>
            </Column>
            <Column field="statusLabel" header="Status" :sortable="true">
            </Column>
            <Column field="id" header="ID" :sortable="true"></Column>
            <Column header="Edycja">
                <template #body="slotProps">
                    <router-link :to="{name: 'eventEdit', params: {'id': slotProps.data.id}}">
                        <Button icon="pi pi-pencil"
                                class="p-button-rounded p-button-text p-button-lg add-user-button"/>
                    </router-link>
                    <span/>
                </template>
            </Column>
            <template #empty>
                Nie znaleziono wydarzeń do akceptacji.
            </template>
        </DataTable>
    </div>
</template>

<script>
  import Button from "primevue/button";
  import DataTable from "primevue/datatable";
  import Column from "primevue/column";
  import {
    countAllCutEventsUsingPOST as countEvents,
    searchAllCutEventsUsingPOST as searchEvents,
  } from "@/swagger/vue-api-client";
  import {SearchCriteria} from "@/utils/SearchCriteria";

  export default {
        name: "EventEditionTable",
        components: {
            Button, Column, DataTable,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        emits: ["update:searchCriteria"],

        computed: {
            searchParams: {
                get() {
                    return this.searchCriteria;
                },
                set(value) {
                    this.$emit("update:searchCriteria", value);
                },
            },
        },

        data() {
            return {
                selectedEvents: [],
                loading: false,
                totalRecords: 0,
                events: [],
                acceptance: false,
            };
        },

        mounted() {
            this.updateTotalRecords();
            this.onPage(this.getFirstPage());
        },

        methods: {
            search() {
                this.onPage(this.getFirstPage());
                this.updateTotalRecords();
            },

            onPage(event) {
                this.loading = true;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = event.sortField;
                this.searchParams.page.sortOrder = event.sortOrder;

                searchEvents({eventSearchCriteria: this.searchCriteria}).then((response) => {
                    this.events = response.data;
                    this.loading = false;
                }).catch((error) => {
                    if (error.response.status === 403) {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Nie masz wystarczających uprawnień",
                            life: 3000,
                        });
                    } else {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd",
                            life: 3000,
                        });
                    }
                });
                SearchCriteria.updateUrl(this);
            },

            updateTotalRecords() {
                countEvents({eventSearchCriteria: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                });
            },

            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                    sortField: "id",
                    sortOrder: -1,
                };
            },
        },

    };
</script>

<style lang="less" scoped>
@import "src/assets/theme/variable";

.list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    button {
        font-size: 16px;
    }
    .organization-list-logo {
        max-width: 150px;
    }
}
</style>
