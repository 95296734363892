<template>
    <div>
        <div class="page-header">
            <div class="page-header-text">
                <CustomTitle title="Organizacje partnerskie"/>
            </div>
        </div>
        <div class="page-content general-container">
            <div class="organizations-buttons-action">
                <OrganizationSearchCriteria ref="OrganizationSearchCriteria"
                                            v-model="organizationSearchCriteriaInput"
                                            @search="$refs.OrganizationTable.search()"/>
                <Button @click="createOrganization" label="Dodaj organizację"
                        @keyup.enter="createOrganization" icon="pi pi-plus"
                        class="button button-filled"></Button>
            </div>
            <OrganizationTable ref="OrganizationTable" :search-criteria="organizationSearchCriteriaInput" />
            <CustomTitle title="Członkowie organizacji partnerskich"/>
            <div class="add-organizer">
                <Button @click="openFiltersDialog" label="Filtruj członków"
                        class="button button-outlined" icon="pi pi-search"/>
                <Button icon="pi pi-plus" @click="createOrganizer"
                        class="button button-filled"
                        label="Dodaj organizatora"/>
            </div>
            <div class="organization-name-info">
                <h4>Aby przejść do strony organizacji, kliknij na jej nazwę</h4>
            </div>
            <Dialog :header="'Filtruj organizatorów'" v-model:visible="displayFiltersDialog"
                    :modal="true">
                <OrganizerSearchCriteria ref="userSearchCriteria" v-model="searchCriteria"/>
                <template #footer>
                    <Button label="Szukaj" icon="pi pi-search" class="button" @click="search"/>
                    <Button label="Wyczyść" icon="pi pi-times" class="button" @click="clear"/>
                </template>
            </Dialog>
            <OrganizerTable ref="OrganizerTable" :search-criteria="searchCriteria" />
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Dialog from "primevue/dialog";
    import Tooltip from "primevue/tooltip";
    import {SystemRole} from "../../utils/SystemRole";
    import {SearchCriteria} from "../../utils/SearchCriteria";
    import OrganizationTable from "@/views/organization/components/OrganizationTable";
    import OrganizationSearchCriteria
        from "@/views/organization/components/OrganizationSearchCriteria";
    import CustomTitle from "@/components/CustomTitle";
    import OrganizerSearchCriteria from "@/views/organizer/components/OrganizerSearchCriteria";
    import OrganizerTable from "@/views/organizer/components/OrganizerTable";

    export default {
        name: "OrganizerAndOrganizationListView",
        components: {
            OrganizerTable,
            OrganizerSearchCriteria,
            CustomTitle,
            OrganizationSearchCriteria,
            OrganizationTable,
            Button,
            Dialog,
        },

        directives: {
            tooltip: Tooltip,
        },

        data() {
            return {
                organizationSearchCriteria: this.getOrganizationClearSearchCriteria(),
                organizationSearchCriteriaInput: this.getClearSearchCriteria(),
                searchCriteria: this.getClearSearchCriteria(),
                displayFiltersDialog: false,
                Role: SystemRole,
                organizations: [],
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        methods: {
            createOrganization() {
                this.$router.push({name: "organizationCreate"});
            },
            search() {
                SearchCriteria.updateUrl(this);

                this.$refs.OrganizerTable.search();
            },

            createOrganizer() {
                this.$router.push({name: "organizerCreate"});
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
            },

            getOrganizationClearSearchCriteria() {
                return {
                    name: null,
                };
            },

            getClearSearchCriteria() {
                return {
                    firstName: null,
                    lastName: null,
                    email: null,
                    organizationName: null,
                    systemRole: null,
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
            openFiltersDialog() {
                this.displayFiltersDialog = true;
            },
            closeFiltersDialog() {
                this.displayFiltersDialog = false;
            },
        },
    };
</script>

<style lang="less">
    .organizations-list {
        margin-bottom: 15%;
    }
    .organizations-buttons-action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
    }
    .organization-list {
        .tile {
            position: relative;
        }
    }
    .add-organizer {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        .filter-button {
            position: absolute;
            right: 50px;
            top: -10px;
        }
    }
    .organization-name-info {
        margin-bottom: 15px;
    }
</style>
