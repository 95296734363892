<template>
    <div class="organizer-search-criteria">
        <div class="organizer-search-criteria-row">
            <CustomInputText name="firstName-filter" label="Imię" v-model="internalValue.firstName"
                             width="250" no-icon/>
            <CustomInputText name="lastName-filter" label="Nazwisko" v-model="internalValue.lastName"
                             width="350" no-icon/>
        </div>
        <div class="organizer-search-criteria-row">
            <CustomInputText name="email-filter" label="Email" v-model="internalValue.email"
                             width="400" no-icon/>
            <CustomInputMask name="phoneNumber-filter" label="Telefon" v-model="internalValue.phoneNumber"
                             width="200" mask="(+48) 999 999 999"/>
        </div>
        <div class="organizer-search-criteria-row">
            <CustomInputText v-if="organizationForm"
                             name="organization-filter"
                             label="Organizacja"
                             v-model="internalValue.organizationName"
                             width="400" no-icon
                             style="display: none;"/>
            <CustomInputText v-else name="organization-filter"
                             label="Organizacja"
                             v-model="internalValue.organizationName"
                             width="400" no-icon/>
            <DesignedMultiSelectStatus id="status-filter" name="status" label="Status"
                                       v-model="internalValue.emailVerified" width="200"/>
        </div>
    </div>
</template>

<script>
    import CustomInputText from "../../../components/form/CustomInputText";
    import DesignedMultiSelectStatus from "../../../components/form/multiselect/DesignedMultiSelectStatus";
    import CustomInputMask from "@/components/form/CustomInputMask";

    export default {
        name: "OrganizerSearchCriteria",

        props: {
            modelValue: null,
            organizationForm: {
                type: Boolean,
                default: false,
            },
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        components: {
            CustomInputMask,
            DesignedMultiSelectStatus,
            CustomInputText,
        },
    };
</script>

<style lang="less" scoped>
.organizer-search-criteria {

    .organizer-search-criteria-row {
        display: flex;
        flex: 1;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        margin: 30px 0;

        >div {
            width: 24%;
        }
    }
}
</style>
