/* eslint-disable */
import {configure, defineRule} from "vee-validate";
import pl from '@vee-validate/i18n/dist/locale/pl.json';
import {
    confirmed,
    is,
    max,
    max_value,
    min,
    min_value,
    regex,
    required,
    url,
} from "@vee-validate/rules";
import {localize} from "@vee-validate/i18n";
import {messagesPL} from "./messages/messages_pl";
import {badWords} from "@/badWords";

defineRule("required", required);
defineRule("min", min);
defineRule("max", max);
defineRule("min_value", min_value);
defineRule("max_value", max_value);
defineRule("confirmed", confirmed);
defineRule("is", is);
defineRule("regex", regex);
defineRule("url", url);

/*
 * Stałe określające wielkości danych kolumn w bazie danych
 */

/* universal */
const emailLength = 128;
const phoneNumberLength = 17;
const contactPersonNameLength = 255;
const eventNameLength = 128;
const organizationNameLength = 128;
const firstNameLength = 128;
const lastNameLength = 128;
const urlLength = 128;
const addressLength = 255;

/* event_type */
const eventTypeNameLength = 64;

/* event */
const additionalInfoLength = 1000;

/* system_setting */
const systemSettingAbuseInfoEmailLength = 128;
const systemSettingAddressDataLength = 1000;
const systemSettingGoogleApiKeyLength = 128;
const systemSettingSystemInfoEmailLength = 128;

/*
 * Sprawdza czy hasło spełnia warunki:
 * Minimum jedna duża litera
 * Minimum jedna mała litera
 * Minimum jedna cyfra
 * Minimum jeden znak specjalny
 * Minimum 8 znaków
 */
defineRule("validPassword", (value) => {
    if (!value || !value.length) {
        return false;
    }

    let regexExp = new RegExp("^(?=.*\\d)(?=.*[a-zżźćńółęąś])(?=.*[A-ZŻŹĆĄŚĘŁÓŃ])(?=.*[!@#$%^&*()[\\]{};:+=|,<.>`~£§?/'-_\"])(?=.*[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ]).{8,}$");

    return regexExp.test(value);
});

defineRule("validOptionalPassword", (value) => {
    if (!value || !value.length) {
        return true;
    }

    let regexExp = new RegExp("^(?=.*\\d)(?=.*[a-zżźćńółęąś])(?=.*[A-ZŻŹĆĄŚĘŁÓŃ])(?=.*[!@#$%^&*()[\\]{};:+=|,<.>`~£§?/'-_\"])(?=.*[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ]).{8,}$");

    return regexExp.test(value);
});

defineRule("email", value => {
    // Field is empty, should pass
    if (!value || !value.length) {
        return true;
    }

    // Check if email
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
});

defineRule("emailLength", value => {
    // Field is empty, should pass
    if (!value || !value.length) {
        return true;
    }

    return value.length <= emailLength;
});

defineRule("phoneNumber", value => {
    // Field is empty, should pass
    if (!value || !value.length) {
        return true;
    }

    // Check if phone number
    return /\(\+([0-9]{2})\)([ ])([0-9]{3})([ ])([0-9]{3})([ ])([0-9]{3})/.test(value);
});

defineRule("phoneNumberLength", value => {
    // Field is empty, should pass
    if (!value || !value.length) {
        return true;
    }

    return value.length <= phoneNumberLength;
});

defineRule("firstNameLength", value => {
    // Field is empty, should pass
    if (!value || !value.length) {
        return true;
    }

    return value.length <= firstNameLength;
});

defineRule("lastNameLength", value => {
    // Field is empty, should pass
    if (!value || !value.length) {
        return true;
    }

    return value.length <= lastNameLength;
});

defineRule("addressLength", value => {
    // Field is empty, should pass
    if (!value || !value.length) {
        return true;
    }

    return value.length <= addressLength;
});

defineRule("eventNameLength", value => {
    // Field is empty, should pass
    if (!value || !value.length) {
        return true;
    }

    // Check length
    return value.length <= eventNameLength;
});

defineRule("organizationNameLength", value => {
    // Field is empty, should pass
    if (!value || !value.length) {
        return true;
    }

    // Check length
    return value.length <= organizationNameLength;
});

defineRule("contactPersonNameLength", value => {
    // Field is empty, should pass
    if (!value || !value.length) {
        return true;
    }

    // Check length
    return value.length <= contactPersonNameLength;


});

defineRule("urlLength", value => {
    // Field is empty, should pass
    if (!value || !value.length) {
        return true;
    }

    // Check length
    return value.length <= urlLength;


});

defineRule("eventTypeNameLength", value => {
    // Field is empty, should pass
    if (!value || !value.length) {
        return true;
    }

    // Check length
    if (value.length > eventTypeNameLength) {
        return false;
    }

    return true;
});

defineRule("additionalInfoLength", value => {
    // Field is empty, should pass
    if (!value || !value.length) {
        return true;
    }

    // Check length
    if (value.length > additionalInfoLength) {
        return false;
    }

    return true;
});

defineRule("dateAfter", (value, [timeBefore]) => {
    // Field is empty, should pass
    if (!value && !timeBefore) {
        return true;
    }

    //Check chronology
    if (timeBefore > value) {
        return false;
    }

    return true;
});

defineRule("confirmed", (value, [other, label]) => {
    if (value !== other) {
        return `${label} nie są takie same`;
    }

    return true;
});

defineRule("size", (file, [size]) => {
    if (file) {
        let maxSize = size ? size : process.env.VUE_APP_MAX_IMAGE_SIZE_IN_MB;
        let maxSizeInKb = maxSize * 1024 * 1024;
        if (file.size <= maxSizeInKb) {
            return true;
        } else {
            return "Maksymalny rozmiar pliku to " + (size ? size : process.env.VUE_APP_MAX_IMAGE_SIZE_IN_MB) + " MB";
        }
    }
    return true;
});

defineRule("image", file => {
    if (file) {
        let allowedTypes = process.env.VUE_APP_IMAGE_FORMAT.split(', ');
        let extension = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length) || file.name;
        if (allowedTypes.includes(extension)) {
            return true;
        } else {
            return "Niedozwolony format pliku. Dozwolone formaty to:  " + process.env.VUE_APP_IMAGE_FORMAT;
        };
    }
    return true;
});

defineRule("badWords", (value) => {
    if (!value || !value.length) {
        return true;
    }
    const badWordsRegex = regexFrom(badWords, "gi");

    let result;
    while (result = badWordsRegex.exec(value)) {
        return false;
    }
    return true;
});



configure({
    generateMessage: localize("pl", {
        messages: messagesPL.validations,
    }),
});

const regexFrom = (strings, flags) =>
    new RegExp(
        '\\b(' + strings
            // Escape special characters
            .map(s => s.replace(/[()[\]{}*+?^$|#.,\/\\\s-]/g, "\\$&"))
            // Sort for maximal munch
            .sort((a, b) => b.length - a.length)
            .join("|") +
        ')\\b',
        flags
    );

defineRule("googleAnalyticsId", value => {
    if (!value || !value.length) {
        return true;
    }

    let regexExp = new RegExp("^[A-Z0-9-]+$");

    if(regexExp.test(value) ) {
        return true;
    } else {
        return "Klucz google Analytics zawiera niedozwolone znaki";
    }
})
