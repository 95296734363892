<template>
    <div class="panel">
        <div class="top-panel">
            <div class="code">
                401
            </div>
        </div>
        <div class="bottom-panel">
            <div class="icon">
                <i class="pi pi-lock"/>
            </div>
            <div class="content">
                <h2>Dostęp zabroniony</h2>
                <span>Prosimy skontaktować się z administratorem systemu</span>
                <br/>
                <br/>
                <router-link class="redirect-link" tag="div" :to="{name: 'search'}">
                    Powrót na stronę główną
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";

    export default {
        name: "ForbiddenView",
        components: {Button},
    };
</script>

<style lang="less" scoped>
    @import "assets/less/status-page.less";
</style>
