<template>
    <div class="public-layout-wrapper">
        <header class="align-images" :class="(SystemRole.isAdmin() || SystemRole.isOrganizer()) ? 'admin-menu' :
            (!SystemRole.isOrganizer() && !SystemRole.isAdmin()) ? 'not-logged-menu' :''">
            <div class="header-inner">
                <div class="left-menu">
                    <router-link :to="{name: 'search'}" class="logo">
<!--TODO logo                        <img v-if="scrolled" v-tooltip.top="'Strona główna'"-->
<!--                             src="../../assets/UActiv_logo/POZIOM/logo-white.svg"-->
<!--                             alt="U!Activ logo"-->
<!--                             class="logo"/>-->
<!--                        <img v-else v-tooltip.top="'Strona główna'"-->
<!--                             src="../../assets/UActiv_logo/POZIOM/UACTIV_POZIOM_kolor_RGB.png"-->
<!--                             alt="U!Activ logo"-->
<!--                             class="logo"/>-->
                    </router-link>
                </div>
                <AppMenu class="align-menu"/>
                <AccMenu />
            </div>
        </header>
        <div class="main">
            <router-view />
        </div>
        <footer v-if="loaded">
            <div class="footer-inner general-container">
                <div class="footer-icons">
                    <a href="https://www.facebook.com/GdanskiOsrodekSportu" class="fb-icon"></a>
                    <a href="https://twitter.com/GdanskSport" class="tweeter-icon"></a>
                </div>
                <div class="footer-copyright">
                    © Copyright 2021 Gdański Ośrodek Sportu. Wszelkie prawa zastrzeżone.
                </div>
                <div class="footer-links">
                    <a href="/">Wyszukiwarka</a>
                    <a href="/privacy-policy">Polityka prywatności</a>
                    <a href="/terms-of-use">Regulamin</a>
                    <a href="mailto:kontakt@aktywujsiewgdansku.pl">Napisz do nas</a>
                </div>
            </div>
        </footer>
        <div v-if="loaded" class="disclaimer">
            <div class="disclaimer-inner general-container">
                Niniejsza strona internetowa została przygotowana przez partnerów projektu „!Active Citizens in
                European Cities (U!ACTIV), nr projektu 613451-EPP-1-2019-PL-SPO-SSCP. <br>Agencja Wykonawcza oraz
                Komisja Europejska nie ponoszą odpowiedzialności za treści na niej zamieszczone.
            </div>
        </div>
        <div v-if="loaded" class="eu-info">
            <img
                src="https://uactiv.pl/wp-content/themes/uactive/assets/dist/img/logosbeneficaireserasmusleft_pl.jpg"
                alt="EU">
            <a class="realization" href="https://www.vavatech.pl">Wykonanie: Vavatech</a>
        </div>
    </div>
</template>

<script>
    import Tooltip from "primevue/tooltip";
    import AppMenu from "@/components/AppMenu";
    import AccMenu from "@/components/AccMenu";
    import {
        getSystemSettingsAddressAndPhoneNumberUsingGET as getAddressAndPhoneNumber,
    } from "@/swagger/vue-api-client";
    import {SystemRole} from "@/utils/SystemRole";
    import {ThemeSettingsHelper} from "@/mixin/themeSettings";

    export default {
        name: "LayoutPublic",

        components: {AppMenu, AccMenu},

        data() {
            return {
                address: "",
                loaded: false,
                phoneNumber: "",
                SystemRole,
                scrolled: true,
            };
        },

        directives: {
            tooltip: Tooltip,
        },

        beforeMount() {
            getAddressAndPhoneNumber()
                .then((response) => {
                    this.address = response.data.addressData;
                    this.phoneNumber = response.data.phoneNumber;
                    this.loaded = true;
                })
                .catch((error) => console.log(error));
        },

        mounted() {
            this.stickyHeader();
        },

        methods: {
            setDarkMode() {
                ThemeSettingsHelper.setDarkThemeForAllApp();
            },
            serNormalMode() {
                ThemeSettingsHelper.setNormalThemeForAllApp();
            },
            stickyHeader() {
                const header = document.querySelector("header");
                document.addEventListener("scroll", () => {
                    const scrolled = document.scrollingElement.scrollTop;
                    if (scrolled > 200) {
                        document.getElementById("vue-app").style.paddingTop = "84px";
                        header.classList.add("fixed");
                        this.scrolled = false;
                    } else {
                        document.getElementById("vue-app").style.paddingTop = "0";
                        header.classList.remove("fixed");
                        this.scrolled = true;
                    }
                });
            },
        },
    };
</script>

<style lang="less">
    @import "../assets/less/layout-public.less";
    footer {
         margin-top:20px
    }
</style>
