<template>
    <div class="event-list">
        <template v-for="(event, index) of events" :key="index">
            <Event :event="event" :highlight="highlightedEventId === event.id"/>
        </template>
        <div v-if="events.length === 0">Nie znaleziono aktywności</div>
    </div>
    <Paginator :rows="rowsPerPageNum" :first="offset" :totalRecords="totalRecords"
               @page="onPage($event)"></Paginator>
    <AddEvent v-if="canAdd && offset === 0"/>
</template>

<script>
    import Paginator from "primevue/paginator";
    import Event from "@/views/event/components/Event";
    import AddEvent from "@/views/event/components/AddEvent";

    export default {
        name: "EventList",
        components: {
            AddEvent, Event, Paginator,
        },

        emits: ["update:offset"],

        props: {
            events: {
                type: Array,
            },
            canAdd: {
                type: Boolean,
                default: false,
            },
            highlightedEventId: {
                type: Number,
                default: null,
            },
            totalRecords: {
                type: Number,
                default: 0,
            },
            offset: {
                type: Number,
                default: 0,
            },
            rowsPerPage: {
                type: Number,
                default: 12,
            },
            name: {
                type: String,
                default: undefined,
            },
        },

        data() {
            return {
                rowsPerPageNum: this.rowsPerPage,
            };
        },

        methods: {
            onPage(event) {
                event.source = this.name;
                this.$emit("update:offset", event);
            },
        },

        watch: {
            highlightedEventId() {
                if (this.highlightedEventId) {
                    const eventId = "eventTile_" + this.highlightedEventId;
                    const eventToScroll = document.getElementById(eventId);
                    eventToScroll.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
                }
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "../../../assets/theme/variable";

    .event-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .p-paginator {
        display: flex;
        justify-content: center;
    }

    .can-add-box {
      border: solid;
      border-color: red;
      border-width: thick;
      color: red;
      padding: 20px;
      font-weight: bold;
      border-radius: 15px;
    }
</style>
