<template>
    <div class="select-frequency">
        <DesignedSelectOne :id="name" :name="name" :items="items" v-model="internalValue"
                           item-label="label" item-value="value" :empty-value="false"
                           @change="onChange" :label="label" :width="width" :rules="rules"
                           :disabled="disabled"/>
    </div>
</template>

<script>
    import DesignedSelectOne from "../inner/DesignedSelectOne";

    export default {
        name: "DesignedSelectFrequency",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            icon: {
                type: String,
                default: "pi pi-search",
            },
            width: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            rules: undefined,
            modelValue: null,
        },

        emits: ["update:modelValue", "change"],

        data() {
            return {
                items: [
                    {label: "co tydzień", value: true},
                    {label: "jednorazowo", value: false},
                ],
            };
        },

        watch: {
            modelValue() {
                this.inputValue = this.modelValue;
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },

            methods: {
                onChange(val) {
                    if (val && (val.value || val.value === false)) {
                        this.$emit("change", val.value);
                    } else {
                        this.$emit("change", undefined);
                    }
                },
            },

            computedClasses() {
                return this.errorMessage ? "p-invalid" : "";
            },
        },

        components: {DesignedSelectOne},
    };
</script>

<style lang="less" scoped>
    .select-frequency {
        label {
            display: none;
        }
    }
</style>
