<template>
    <div>
        <div class="page-header">
            <div class="page-header-text">
                <CustomTitle title="Wydarzenia do akceptacji"/>
            </div>
        </div>
        <div class="page-content general-container">
            <v-card class="flex-wrap organizations-buttons-action">
                <ImportedEventAcceptanceViewSearchCriteria
                    ref="importedEventAcceptanceViewSearchCriteria"
                    v-model="searchCriteria"
                    @search="$refs.ImportedEventAcceptanceViewTable.search()"/>
                <div class="flex">
                    <Button @click="search" label="Szukaj"
                            class="button button-filled" icon="pi pi-search"/>
                    <Button @click="accept" label="Zatwierdź"
                            class="button button-filled"/>
                </div>
            </v-card>
            <ImportedEventAcceptanceViewTable ref="ImportedEventAcceptanceViewTable"
                                              :search-criteria="searchCriteria"/>
            <Button @click="accept" label="Zatwierdź"
                    class="button button-filled col-offset-1"/>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import {SearchCriteria} from "../../utils/SearchCriteria";
    import ImportedEventAcceptanceViewSearchCriteria from "./components/ImportedEventAcceptanceViewSearchCriteria";
    import ImportedEventAcceptanceViewTable from "./components/ImportedEventAcceptanceTable";
    import CustomTitle from "@/components/CustomTitle";

    export default {
        name: "ImportedEventAcceptanceListView",
        components: {
            ImportedEventAcceptanceViewTable,
            ImportedEventAcceptanceViewSearchCriteria,
            Button,
            CustomTitle,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);

                this.$refs.ImportedEventAcceptanceViewTable.search();
            },

            accept() {
                this.$refs.ImportedEventAcceptanceViewTable.accept();
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
            },

            getClearSearchCriteria() {
                return {
                    eventName: "",
                    organizationName: "",
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: "id",
                        sortOrder: -1,
                    },
                };
            },
        },
    };
</script>

<style lang="less">
.organizations-buttons-action {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
</style>
