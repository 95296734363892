<template>
    <div v-if="loaded" class="form-readonly-view organization-view">
        <div class="page-header">
            <div class="page-header-text">
                <h1>{{organization.name}}</h1>
            </div>
        </div>
        <div class="page-content general-container">
            <div class="image-container">
                <img alt="Błąd w wyświetleniu zdjęcia"
                     :src="organization.logoUrl"
                     id="logo"
                     style="object-fit: cover"/>
            </div>
            <div class="data">
                <div class="row">
                    <div class="label">Adres</div>
                    <div class="value">{{organization.address}}</div>
                </div>
                <div class="row">
                    <div class="label">Kontakt</div>
                    <div class="value">{{organization.contact}}</div>
                </div>
                <div class="row">
                    <div class="label">Telefon</div>
                    <div class="value">{{organization.phoneNumber}}</div>
                </div>
                <div class="row">
                    <div class="label">Email</div>
                    <div class="value">{{organization.email}}</div>
                </div>
                <div class="row">
                    <div class="label">Strona</div>
                    <div class="value value-link">
                        <a :href="organization.page" target="_blank">{{organization.page}}</a>
                    </div>
                </div>
                <div class="row">
                    <div class="label">Opis</div>
                    <div class="value">{{organization.description}}</div>
                </div>
            </div>
            <div class="paragraph">
                <h2>Nasze aktywności</h2>
                <EventList :events="events" :can-add="false" :total-records="numberOfEvents"
                           @update:offset="updateOffset"/>
            </div>
        </div>
    </div>
</template>

<script>
    import EventList from "@/views/event/components/EventList";
    import {
        getOrganizationDataUsingGET as getOrganizationData,
        searchEventsUsingPOST as searchEvents,
        countEventsUsingPOST as countEvents,
    } from "@/swagger/vue-api-client";

    export default {
        name: "OrganizationView",
        components: {EventList},

        data() {
            return {
                organization: null,
                events: [],
                numberOfEvents: null,
                searchCriteria: this.getClearSearchCriteria(),
                loaded: false,
            };
        },

        beforeMount() {
            if (this.$route.params.id) {
                // TODO download organization data
                this.fetchOrganizationData();
                this.search();
            }
        },

        methods: {
            updateOffset(event) {
                this.searchCriteria.page.offset = event.first;
                this.searchCriteria.page.limit = event.rows;
                this.searchCriteria.page.sortField = event.sortField;
                this.searchCriteria.page.sortOrder = event.sortOrder;

                this.search();
            },

            fetchOrganizationData() {
                getOrganizationData({id: this.$route.params.id})
                    .then((response) => {
                        this.organization = response.data;
                        this.organization.logoFile = {};
                        this.loaded = true;
                    }).catch((error) => {
                        if (error.response.status === 404) {
                            this.$router.push({name: "notFound"});
                        }
                    });
            },

            search() {
                // FIXME: naprawić update'owanie urla;
                // SearchCriteria.updateUrl(this);
                searchEvents({eventSearchCriteria: this.searchCriteria}).then((response) => {
                    this.events = response.data;
                }).catch((error) => {
                    window.console.log(error);
                });

                countEvents({eventSearchCriteria: this.searchCriteria}).then((response) => {
                    this.numberOfEvents = response.data;
                }).catch((error) => {
                    window.console.log(error);
                });
            },

            getClearSearchCriteria() {
                return {
                    districts: undefined,
                    phrase: undefined,
                    eventTypesIds: undefined,
                    dates: undefined,
                    organizationId: this.$route.params.id,
                    page: {
                        limit: 12,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "src/views/assets/less/form-readonly-view";
    #logo {
        object-fit: contain;
    }
    .organization-view {
        .image-container {
            max-width: 300px;
        }
    }
</style>
