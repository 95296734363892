<template>
    <div>
        <div class="card">
            <Carousel :value="computedList" :numVisible="3" :numScroll="3" :responsiveOptions="responsiveOptions">
                <template #item="photo">
                    <div v-if="photo.data" class="gallery-item">
                        <div class="container slick-item">
                            <img :src="photo.data.url" alt="zdjęcie z galerii" @click="imageClicked">
                        </div>
                    </div>
                </template>
            </Carousel>
        </div>
        <Galleria v-if="photos && photos.length > 0" :value="computedList"
                  containerStyle="max-width: 50%" :circular="true" :fullScreen="true"
                  :showItemNavigators="true" :showThumbnails="false" v-model:visible="showGalery">
            <template #item="slotProps">
                <img :src="slotProps.item.url" alt="zdjęcie z galerii" style="width: 100%; display: block;" />
            </template>
        </Galleria>
    </div>
</template>

<script>
    import Carousel from "primevue/carousel";
    import Galleria from "primevue/galleria";

    export default {
        name: "EventGallery",
        components: {Carousel, Galleria},

        props: {
            photos: {
                type: Array,
            },
            acceptedFileFormats: {
                type: Array,
            },
        },

        emits: ["photosAdded", "photoDeleted", "photoEdited"],

        data() {
            return {
                photosToDelete: [],
                responsiveOptions: [
                    {
                        breakpoint: "1024px",
                        numVisible: 3,
                        numScroll: 3,
                    },
                    {
                        breakpoint: "600px",
                        numVisible: 2,
                        numScroll: 2,
                    },
                    {
                        breakpoint: "480px",
                        numVisible: 1,
                        numScroll: 1,
                    },
                ],
                showGalery: false,
            };
        },

        methods: {
            imageClicked() {
                this.showGalery = true;
            },
        },

        computed: {
            computedList() {
                return this.photos ? this.photos : [];
            },
        },
    };
</script>

<style lang="less" scoped>
@import "../../../assets/theme/variable";
@import "../../assets/less/image-tile-add";
.gallery-item {
    display: flex;
    flex-direction: column;
    height: 300px;
    margin: 0 10px;
    text-align: center;

    .container {
        position: relative;

        img {
            width: 100%;
            height: 300px;
            object-fit: cover;
        }
    }
}
.tile {
    height: 300px;
    width: 100%;
    margin: 0;
}

</style>
