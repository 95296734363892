<template>
    <div class="select-events" :style="'width: ' + width + 'px;'">
        <DesignedMultiSelect :id="name" :name="name" :items="processItems" v-model="internalValue"
                             item-label="name" item-value="categoryId" :empty-value="false"
                             @change="handleChange" :label="label" :loading="loading"
                             :scrollHeight="scrollHeight"/>
    </div>
</template>

<script>
    import {useField} from "vee-validate";
    import DesignedMultiSelect from "@/components/form/inner/DesignedMultiSelect";
    import {getAllPublishedEventsUsingGET as getEventTypes} from "@/swagger/vue-api-client";

    export default {
        name: "DesignedMultiSelectEvents",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            icon: {
                type: String,
                default: "pi pi-search",
            },
            width: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            userPrefs: {
                type: Object,
                default: null,
                required: false,
            },
            rules: undefined,
            modelValue: null,
            scrollHeight: {
                type: String,
                default: undefined,
            },
        },

        emits: ["update:modelValue"],

        data() {
            return {
                items: [],
                processedItems: [],
                eventsToSearch: [],
                loading: true,
            };
        },

        methods: {
            fetchEventTypes() {
                getEventTypes().then((response) => {
                    this.items = response.data;
                    if (this.userPrefs != null) {
                        this.internalValue = response.data
                            .filter((event) => this.userPrefs.some((pref) => pref.id === event.id))
                            .map((event) => event.id);
                    }
                    this.loading = false;
                });
            },
        },

        watch: {
            userPrefs() {
                if (this.userPrefs != null) {
                    this.internalValue = this.items
                        .filter((event) => this.userPrefs.some((pref) => pref.id === event.id))
                        .map((event) => event.id);
                }
            },
        },

        beforeMount() {
            this.fetchEventTypes();
        },

        computed: {
            internalValue: {
                get() {
                    let categoriesId = [];
                    if (this.modelValue !== undefined) {
                        let events = [];
                        for (const d of this.modelValue) {
                            if (typeof d !== "string") {
                                events = [...events, ...d];
                            } else {
                                events.push(d);
                            }
                        }
                        this.processedItems.forEach((item) => {
                            if (typeof item.eventId !== "string" && events.some((r) => item.eventId.includes(r))) {
                                categoriesId = categoriesId.concat(item.categoryId);
                            } else if (events.includes(item.eventId)) {
                                categoriesId = categoriesId.concat(item.categoryId);
                            }
                        });
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.eventsToSearch = events;
                    }
                    return categoriesId;
                },
                set(categoriesId) {
                    let eventsId = [];
                    categoriesId.forEach((category) => {
                        eventsId = [...eventsId, ...this.processedItems
                            .filter((item) => item.categoryId === category)
                            .map((item) => item.eventId)];
                    });
                    this.$emit("update:modelValue", eventsId);
                },
            },

            computedClasses() {
                return this.errorMessage ? "p-invalid" : "";
            },

            processItems() {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.processedItems = [];
                let selectId = 0;
                // get all event type categories from data
                const categories = [...new Set(this.items.map(
                    (item) => (item.eventTypeCategory ? item.eventTypeCategory.name : null),
                ))];

                // add events without category
                this.items.forEach((item) => {
                    if (!item.eventTypeCategory) {
                        item.eventId = JSON.stringify(item.id);
                        item.categoryId = JSON.stringify(selectId);
                        this.processedItems[selectId] = item;
                        selectId += 1;
                    }
                });

                // group and add events with category
                categories
                    .filter((category) => category !== null)
                    .forEach((category) => {
                        const eventsId = this.items.filter((item) => item.eventTypeCategory
                            && item.eventTypeCategory.name === category)
                            .map((item) => JSON.stringify(item.id));
                        this.processedItems[selectId] = [].concat();
                        this.processedItems[selectId].name = category;
                        this.processedItems[selectId].eventId = eventsId;
                        this.processedItems[selectId].categoryId = JSON.stringify(selectId);
                        selectId += 1;
                    });

                return this.processedItems;
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

        components: {DesignedMultiSelect},
    };
</script>

<style lang="less" scoped>
    .select-events {
        label {
            display: none;
        }
    }
</style>
