/* eslint-disable */
export const primeVueLocale_pl = {
    locale: {
        startsWith: "Starts with",
        contains: "Contains",
        notContains: "Not contains",
        endsWith: "Ends with",
        equals: "Equals",
        notEquals: "Not equals",
        noFilter: "No Filter",
        lt: "Less than",
        lte: "Less than or equal to",
        gt: "Greater than",
        gte: "Greater than or equal to",
        dateIs: "Date is",
        dateIsNot: "Date is not",
        dateBefore: "Date is before",
        dateAfter: "Date is after",
        clear: "Wyczyść",
        apply: "Zatwierdź",
        matchAll: "Match All",
        matchAny: "Match Any",
        addRule: "Dodaj regułę",
        removeRule: "Usuń regułę",
        accept: "Tak",
        reject: "Nie",
        choose: "Wybierz",
        upload: "Dodaj plik",
        cancel: "Anuluj",
        dayNames: ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Saturday"],
        dayNamesShort: ["Nie", "Pon", "Wto", "Śr", "Czw", "Pt", "Sob"],
        dayNamesMin: ["Nie", "Pon", "Wto", "Śr", "Czw", "Pt", "Sob"],
        monthNames: ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec",
            "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],
        monthNamesShort: ["St", "Lt", "Mrz", "Kw", "Mj", "Czrw", "Lp", "Sp", "Wrz", "Prn", "Lst", "Gr"],
        today: "Dzisiaj",
        weekHeader: "Tyg",
        firstDayOfWeek: 1,
        dateFormat: "mm/dd/yy",
        weak: "Słabe",
        medium: "Średnie",
        strong: "Mocne",
        passwordPrompt: "Wprowadź hasło",
        emptyFilterMessage: "Nie znaleziono wyników",
        emptyMessage: "Brak dostępnych opcji",
    }
}

