<template>
    <div>
        <DataTable ref="OrganizerTable" :value="Organizers" :lazy="true" :paginator="true"
                   v-model:rows="searchParams.page.limit" v-model:first="searchParams.page.offset"
                   removableSort :totalRecords="totalRecords" :loading="loading" @page="onPage"
                   @sort="onPage" :rowsPerPageOptions="[5,10,20,50]">
            <Column field="firstName" header="Imię" :sortable="true"></Column>
            <Column field="lastName" header="Nazwisko" :sortable="true"></Column>
            <Column field="email" header="Email" :sortable="true"></Column>
            <Column field="phoneNumber" header="Telefon" :sortable="true"></Column>
            <Column field="emailVerified" header="Status" :sortable="true">
                <template #body="slotProps">
                    {{getStatus(slotProps.data)}}
                </template>
            </Column>
            <Column field="organization.name" header="Organizacja" v-if="!organizationForm">
                <template #body="slotProps">
                    <router-link :to="{name: 'organization', params: {id: slotProps.data.organizationId}}"
                                 style="text-decoration: none;">
                        <Button class="p-button-outlined">
                            {{ slotProps.data.organizationName }}
                        </Button>
                    </router-link>
                </template>
            </Column>
            <Column header="Edycja" v-if="Role.isAdmin()">
                <template #body="slotProps">
                    <router-link :to="{name: 'organizerEdit', params: {id: slotProps.data.id}}">
                        <Button icon="pi pi-pencil"  v-tooltip.top="'Edytuj'" aria-label="Edytuj"
                                class="p-button-rounded p-button-text p-button-lg add-user-button"/>
                    </router-link>
                    <span/>
                </template>
            </Column>
            <template #empty>
                Nie znaleziono członków organizacji.
            </template>
        </DataTable>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Tooltip from "primevue/tooltip";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {
        searchOrganizerCountUsingPOST as countOrganizer,
        searchOrganizerUsingPOST as searchOrganizer,
    } from "@/swagger/vue-api-client";
    import {SystemRole} from "@/utils/SystemRole";
    import {SearchCriteria} from "@/utils/SearchCriteria";

    export default {
        name: "OrganizerTable",
        components: {
            Button, Column, DataTable,
        },

        directives: {
            tooltip: Tooltip,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
            organizationForm: {
                type: Boolean,
                default: false,
            },
        },

        emits: ["update:searchCriteria"],

        data() {
            return {
                loading: false,
                totalRecords: 0,
                Organizers: [],
                Role: SystemRole,
            };
        },

        computed: {
            searchParams: {
                get() {
                    return this.searchCriteria;
                },
                set(value) {
                    this.$emit("update:searchCriteria", value);
                },
            },
        },

        mounted() {
            this.updateTotalRecords();
            this.onPage(this.getFirstPage());
        },

        methods: {
            search() {
                this.onPage(this.getFirstPage());
                this.updateTotalRecords();
            },

            onPage(event) {
                this.loading = true;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = event.sortField;
                this.searchParams.page.sortOrder = event.sortOrder;

                searchOrganizer({searchCriteria: this.searchCriteria}).then((response) => {
                    this.Organizers = response.data;
                    this.loading = false;
                }).catch((error) => {
                    if (error.response.status === 403) {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Nie masz wystarczających uprawnień",
                            life: 3000,
                        });
                    } else {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd",
                            life: 3000,
                        });
                    }
                });
                SearchCriteria.updateUrl(this);
            },

            updateTotalRecords() {
                countOrganizer({organizerSearchCriteria: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                });
            },

            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                };
            },

            getStatus(data) {
                if (data.emailVerified) {
                    return "aktywny";
                }
                return "nowy";
            },
        },

    };
</script>

<style lang="less" scoped>
@import "../../../assets/theme/variable";

.events {
    i {
        font-size: 25px;
    }

    display: flex;
    justify-content: space-evenly;
}
</style>
