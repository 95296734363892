<template>
    <div class="form-view" v-if="loaded">
        <div class="page-header">
            <div class="page-header-text">
                <h1 v-if="create">Tworzenie organizacji</h1>
                <h1 v-else>Edycja organizacji</h1>
            </div>
        </div>
        <div class="page-content general-container">
            <div class="first-paragraph" v-if="Role.isOrganizer()">
                <h2>Aktywności mojej organizacji</h2>
                <div v-if="!isAddingEventsPermitted" class="can-add-box">
                    Funkcja dodawania wydarzeń zostanie aktywowana po zaakceptowaniu organizacji przez administratora.
                    <br>
                    W celu przyspieszenia tego procesu zachęcamy do wypełnienia formularza poniżej.
                </div>
                <EventList :events="events" :total-records="numberOfEvents" :can-add="isAddingEventsPermitted"
                           @update:offset="updateOffset"/>
            </div>
            <div class="paragraph">
                <Form @submit="saveOrganization" v-slot="{ isSubmitting }">
                    <div class="data">
                        <h2>Dane mojej organizacji</h2>
                        <div v-if="Role.isAdmin()" class="row">
                            <div class="label">Zatwierdź</div>
                            <CustomCheckbox name="accepted" v-model="organization.accepted" class="accepted-checkbox"
                                            :disabled="isOrganizationAccepted" :no-star="true"/>
                        </div>
                        <div class="row">
                            <div class="label">Nazwa</div>
                            <CustomInputText name="name" v-model="organization.name" no-icon
                                             label="nazwa organizacji" :width="dataRowWidth"
                                             rules="required|organizationNameLength"/>
                        </div>
                        <div class="row">
                            <div class="label">Adres</div>
                            <CustomInputText name="address" v-model="organization.address" no-icon
                                             label="adres" :width="dataRowWidth" rules="required|addressLength"/>
                        </div>
                        <div class="row">
                            <div class="label">Kontakt</div>
                            <CustomInputText name="contact" v-model="organization.contact" no-icon
                                             label="osoba kontaktowa" :width="dataRowWidth"
                                             rules="required|contactPersonNameLength"/>
                        </div>
                        <div class="row">
                            <div class="label">Email</div>
                            <CustomInputText name="email" v-model="organization.email" no-icon
                                             label="kontaktowy adres email" :width="dataRowWidth"
                                             rules="required|email|emailLength"/>
                        </div>
                        <div class="row">
                            <div class="label">Telefon</div>
                            <CustomInputMask name="phone" v-model="organization.phoneNumber" no-icon
                                             label="telefon kontaktowy" :width="dataRowWidth" mask="(+48) 999 999 999"
                                             rules="phoneNumberLength"/>
                        </div>
                        <div class="row">
                            <div class="label">Strona</div>
                            <CustomInputText name="page" v-model="organization.page" no-icon
                                             label="adres strony internetowej" :width="dataRowWidth"
                                             rules="required|url|urlLength"/>
                        </div>
                        <div class="row" style="margin-top: 100px">
                            <div class="label" style="align-items: flex-start">Opis</div>
                            <CustomInputTextarea name="description" v-model="organization.description" no-icon
                                                 label="opis organizacji" rules="required"/>
                        </div>
                        <div class="row">
                            <div class="label">Zdjęcie</div>
                            <div class="image-container">
                                <CustomFileUpload :label="organization.logoUrl ? 'Zmień logo' : 'Dodaj logo'"
                                                  v-model="file" icon="pi pi-plus"
                                                  v-model:logo-url="organization.logoUrl"
                                                  :accepted-file-formats="acceptedFileFormats" name="logo-file"
                                                  rules="size:1"/>
                            </div>
                        </div>
                    </div>
                    <SaveButton on-bottom type="submit"/>
                    <div class="paragraph" v-if="!create">
                        <div class="add-organizer">
                            <h2>Członkowie organizacji partnerskich</h2>
                            <Button icon="pi pi-search" label="Szukaj członków organizacji"
                                    @click="openFiltersDialog"
                                    class="button button-outlined"/>
                            <Button v-if="Role.isAdmin()" icon="pi pi-plus" @click="createOrganizer"
                                    class="button button-filled"
                                    label="Dodaj członka organizacji partnerskiej"/>
                        </div>

                        <Dialog :header="'Filtruj członków organizacji'" v-model:visible="displayFiltersDialog">
                            <OrganizerSearchCriteria ref="userSearchCriteria"
                                                     v-model="searchCriteria"
                                                     :organization-form="true"/>
                            <template #footer>
                                <div class="buttons-inline">
                                    <Button label="Szukaj" class="button button-filled"
                                            icon="pi pi-search" @click="search"/>
                                    <Button label="Wyczyść" class="button button-outlined"
                                            icon="pi pi-times" @click="clear"/>
                                </div>
                            </template>
                        </Dialog>

                        <OrganizerTable ref="OrganizerTable" :search-criteria="searchCriteria"
                                        :organization-form="true"/>
                    </div>
                </form>
            </div>
            <div class="paragraph" v-if="!create && Role.isAdmin()">
                <h2>Aktywności organizacji</h2>
                <EventList :events="events" :total-records="numberOfEvents" :can-add="isAddingEventsPermitted"
                           @update:offset="updateOffset"/>
                <DeleteButton label="USUŃ ORGANIZACJĘ" on-bottom @click="deleteOrganization(organization.id, $event)"/>
            </div>
        </div>
    </div>
</template>

<script>
    import {Form} from "vee-validate";
    import Button from "primevue/button";
    import Dialog from "primevue/dialog";
    import Tooltip from "primevue/tooltip";
    import {
        createOrganizationUsingPOST as createOrganization,
        deleteOrganizationUsingDELETE as deleteOrganization,
        getMyOrganizationDataUsingGET as getMyOrganizationData,
        getOrganizationDataUsingGET as getOrganizationData,
        getAllEventsUsingPOST as searchEvents,
        countAllEventsUsingPOST as countEvents,
        updateOrganizationUsingPUT as updateOrganization,
    } from "@/swagger/vue-api-client";
    import CustomInputText from "../../components/form/CustomInputText";
    import CustomInputTextarea from "@/components/form/CustomInputTextArea";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {SystemRole} from "@/utils/SystemRole";
    import EventList from "@/views/event/components/EventList";
    import CustomInputMask from "@/components/form/CustomInputMask";
    import CustomFileUpload from "@/components/form/inner/CustomFileUpload";
    import {FileUtils} from "@/utils/FileUtils";
    import OrganizerTable from "@/views/organizer/components/OrganizerTable";
    import OrganizerSearchCriteria from "@/views/organizer/components/OrganizerSearchCriteria";
    import SaveButton from "@/components/form/SaveButton";
    import CustomCheckbox from "@/components/form/CustomCheckbox";
    import DeleteButton from "@/components/form/DeleteButton";

    export default {
        name: "OrganizationFormView",

        components: {
            DeleteButton,
            CustomCheckbox,
            SaveButton,
            OrganizerSearchCriteria,
            OrganizerTable,
            Form,
            CustomFileUpload,
            CustomInputMask,
            EventList,
            CustomInputTextarea,
            CustomInputText,
            Button,
            Dialog,
        },

        directives: {
            tooltip: Tooltip,
        },

        props: {
            value: {},
            create: {
                type: Boolean,
                default: false,
            },
        },

        mixins: [FileUtils],

        data() {
            return {
                // organization: standardOrganizationData,
                organization: {},
                displayFiltersDialog: false,
                acceptedFileFormats: FileUtils.acceptedImageFormats.map((val) => "." + val),
                loaded: false,
                title: (this.create ? "Tworzenie" : "Edycja") + " organizacji partnerskiej",
                searchCriteria: null,
                eventSearchCriteria: null,
                Role: SystemRole,
                events: [],
                numberOfEvents: null,
                file: undefined,
                isAddingEventsPermitted: true,
                isOrganizationAccepted: false,
                dataRowWidth: 500,
            };
        },

        beforeMount() {
            if (this.$route.params.id) {
                this.eventSearchCriteria = this.getClearEventSearchCriteria();
                this.searchOrganizationEvents();
                this.fetchOrganizationData(true);
            } else {
                this.loaded = true;
            }

            const windowWidth = window.innerWidth;
            this.dataRowWidth = (windowWidth - 340) * 0.75 - 330;
        },

        methods: {
            async saveOrganization() {
                if (this.file) {
                    this.organization.logoFile = {};
                    this.organization.logoFile.filename = this.file.name;
                    this.organization.logoFile.name = this.file.name;
                    this.organization.logoFile.encodedData = await FileUtils.fileToBase64(this.file);
                } else {
                    this.organization.logoFile = null;
                }

                const func = this.create ? createOrganization : updateOrganization;
                await func({organizationDto: this.organization}).then((response) => {
                    this.createdEventId = response.data.id;
                    this.$toast.add({
                        severity: "success",
                        summary: "Sukces",
                        detail: this.create ? "Dodano nową organizację" : "Zapisano dane organizacji",
                        life: 3000,
                    });

                    this.fetchOrganizationData();
                    if (this.create) {
                        this.$router.push({name: "organizationEdit", params: {id: response.data.id}});
                    }
                }).catch((error) => {
                    if (error.response.status === 403) {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Nie masz wystarczających uprawnień",
                            life: 3000,
                        });
                    } else {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd",
                            life: 3000,
                        });
                    }
                });
            },

            deleteOrganization(id, event) {
                this.$confirm.require({
                    target: event.currentTarget,
                    header: "Czy na pewno chcesz usunąć organizację?",
                    message:
                        // eslint-disable-next-line max-len
                        "Usunięcie organizacji wiążę się z usunięciem wszystkich przypisanych do niej wydarzeń, kont członków oraz plików graficznych."
                        + " Tej operacji nie będzie można cofnąć. Czy na pewno chcesz kontynuować?",
                    acceptLabel: "Tak",
                    rejectLabel: "Nie",
                    accept: () => {
                        const func = deleteOrganization({id});
                        func.then(() => {
                            this.$toast.add({
                                severity: "success",
                                summary: "Sukces",
                                detail: "Pomyślnie usunięto organizację",
                                life: 3000,
                            });
                            this.$router.push({name: "organizerList"});
                        }).catch((error) => {
                            if (error.response.status === 403) {
                                this.$toast.add({
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Nie masz wystarczających uprawnień",
                                    life: 3000,
                                });
                            } else if (error.response.status === 500) {
                                this.$toast.add({
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił błąd po stronie serwera. Skontaktuj się z administratorem",
                                    life: 3000,
                                });
                            } else {
                                this.$toast.add({
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd",
                                    life: 3000,
                                });
                            }
                        });
                    },
                });
            },

            fetchOrganizationData(setLoaded) {
                const idToSend = this.$route.params.id === undefined ? this.createdEventId : this.$route.params.id;
                const func = SystemRole.isAdmin()
                    ? getOrganizationData({id: idToSend}) : getMyOrganizationData({});
                func.then((response) => {
                    this.organization = response.data;
                    this.isOrganizationAccepted = response.data.accepted;
                    if (this.organization.accepted === false && SystemRole.isOrganizer()) {
                        this.isAddingEventsPermitted = false;
                    }
                    this.organization.logoFile = {};
                    // Kryteria przeniesione do ciała funkcji pobierającej dane,
                    // żeby można było im przekazać organization.name
                    this.searchCriteria = this.getClearSearchCriteria();
                    SearchCriteria.loadCriteria(this);
                    if (setLoaded) {
                        this.loaded = true;
                    }
                }).catch((error) => {
                    if (error.response.status === 404) {
                        this.$router.push({name: "notFound"});
                    }
                });
            },

            createUser() {
                this.$router.push({name: "userCreate"});
            },

            search() {
                SearchCriteria.updateUrl(this);
                this.$refs.OrganizerTable.search();
            },

            createOrganizer() {
                this.$router.push({name: "organizerCreate"});
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
            },

            updateOffset(event) {
                this.eventSearchCriteria.page.offset = event.first;
                this.eventSearchCriteria.page.limit = event.rows;
                this.eventSearchCriteria.page.sortField = event.sortField;
                this.eventSearchCriteria.page.sortOrder = event.sortOrder;
                this.searchOrganizationEvents();
            },

            getClearSearchCriteria() {
                return {
                    firstName: "",
                    lastName: "",
                    email: "",
                    systemRole: null,
                    organizationId: this.$route.params.id,
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },

            openFiltersDialog() {
                this.displayFiltersDialog = true;
            },

            closeFiltersDialog() {
                this.displayFiltersDialog = false;
            },

            searchOrganizationEvents() {
                // FIXME: naprawić update'owanie urla;
                // SearchCriteria.updateUrl(this);
                searchEvents({eventSearchCriteria: this.eventSearchCriteria}).then((response) => {
                    this.events = response.data;
                }).catch((error) => {
                    window.console.log(error);
                });
                countEvents({eventSearchCriteria: this.eventSearchCriteria}).then((response) => {
                    this.numberOfEvents = response.data;
                }).catch((error) => {
                    window.console.log(error);
                });
            },

            getClearEventSearchCriteria() {
                return {
                    districts: undefined,
                    phrase: undefined,
                    eventTypesIds: undefined,
                    dates: undefined,
                    organizationId: this.$route.params.id,
                    page: {
                        limit: 12,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
        },
    };
</script>

<style lang="less" scoped>
@import "src/views/assets/less/form-view";
.add-organizer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    .button-outlined {
        margin-left: auto;
    }

    .filter-button {
        position: absolute;
        right: 50px;
        top: -10px;
    }
}
.can-add-box {
    border: solid;
    border-color: red;
    border-width: thick;
    color: red;
    padding: 20px;
    font-weight: bold;
}

.accepted-checkbox {
    margin-top: 10px;
}
</style>
