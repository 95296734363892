<template>
    <div class="select-district" :style="'width: ' + width + 'px;'">
        <DesignedMultiSelect :id="name" :name="name" :items="items" v-model="internalValue"
                             item-label="label" item-value="value" :empty-value="false"
                             @change="handleChange" :label="label" :loading="loading"
                             :scrollHeight="scrollHeight"/>
    </div>
</template>

<script>
    import {useField} from "vee-validate";
    import DesignedMultiSelect from "@/components/form/inner/DesignedMultiSelect";
    import {getAllDistrictsUsingGET} from "@/swagger/vue-api-client";

    export default {
        name: "DesignedMultiSelectDistrict",

        props: {
            name: {
                type: String,
            },
            icon: {
                type: String,
                default: "pi pi-search",
            },
            width: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
                default: "Dzielnica",
            },
            userPrefs: {
                type: Object,
                default: null,
                required: false,
            },
            rules: undefined,
            modelValue: null,
            scrollHeight: {
                type: String,
                default: undefined,
            },
        },

        emits: ["update:modelValue"],

        data() {
            return {
                items: [],
                loading: true,
            };
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.inputValue = value;
                    this.$emit("update:modelValue", value);
                },
            },

            computedClasses() {
                return this.errorMessage ? "p-invalid" : "";
            },
        },

        watch: {
            userPrefs() {
                if (this.userPrefs != null) {
                    this.internalValue = this.items
                        .filter((district) => this.userPrefs.includes(district.value))
                        .map((district) => district.value);
                }
            },
        },

        beforeMount() {
            getAllDistrictsUsingGET().then((response) => {
                this.items = response.data;
                if (this.userPrefs != null) {
                    this.internalValue = response.data
                        .filter((district) => this.userPrefs.includes(district.value))
                        .map((district) => district.value);
                }
                this.loading = false;
            });
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

        components: {DesignedMultiSelect},
    };
</script>

<style lang="less" scoped>
    .select-district {
        label {
            display: none;
        }
    }
</style>
