<template>
    <div class="select-status" :style="'width: ' + width + 'px;'">
        <DesignedSelectOne :id="name" :name="name" :items="items" v-model="internalValue" item-label="label"
                           item-value="value" :empty-value="false" @change="handleChange" :label="label"/>
    </div>
</template>

<script>
    import {useField} from "vee-validate";
    import DesignedSelectOne from "@/components/form/inner/DesignedSelectOne";

    export default {
        name: "DesignedMultiSelectStatus",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            icon: {
                type: String,
                default: "pi pi-search",
            },
            width: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            rules: undefined,
            modelValue: null,
        },

        emits: ["update:modelValue"],

        data() {
            return {
                items: [
                    {label: "", value: null},
                    {label: "aktywny", value: true},
                    {label: "nowy", value: false},
                ],
            };
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.inputValue = value;
                    this.$emit("update:modelValue", value);
                },
            },

            computedClasses() {
                return this.errorMessage ? "p-invalid" : "";
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

        components: {DesignedSelectOne},
    };
</script>

<style lang="less" scoped>
    .select-status {
        label {
            display: none;
        }
    }
</style>
