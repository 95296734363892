<template>
    <div class="p-fluid custom-calendar" :style="'width: ' + width + 'px;'">
        <Calendar :disabled="disabled" :id="name" :name="name" :aria-label="name"
                  v-model="internalValue" dateFormat="dd.mm.yy" autocomplete="off"
                  :placeholder="internalValue ? '' : label" :selectionMode="selectionMode"
                  :minDate="minDate" :maxDate="maxDate" :show-icon="true" :icon="icon"
                  :showTime="showTime" :showButtonBar="showButtonBar"
                  @clear-click="clearDate"/>
        <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
    </div>
</template>

<script>
    import {useField} from "vee-validate";
    import Tooltip from "primevue/tooltip";
    import Calendar from "primevue/calendar";

    export default {
        name: "CustomCalendar",

        components: {
            Calendar,
        },

        props: {
            showTime: {
                type: Boolean,
                default: false,
            },
            icon: {
                type: String,
            },
            name: {
                type: String,
            },
            width: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
            },
            showButtonBar: {
                type: Boolean,
                default: false,
            },
            rules: undefined,
            modelValue: null,
            selectionMode: {
                type: String,
                default: "single",
            },
            minDate: {
                type: Date,
            },
            maxDate: {
                type: Date,
            },
        },

        emits: ["update:modelValue"],

        directives: {
            tooltip: Tooltip,
        },

        methods: {
            clearDate() {
                this.internalValue = null;
                this.$emit("update:modelValue", null);
            },
        },

        computed: {
            internalValue: {
                get() {
                    if (!this.modelValue) {
                        return this.modelValue;
                    }
                    if (this.selectionMode === "range") {
                        return this.modelValue.map((obj) => new Date(obj));
                    }
                    return new Date(this.modelValue);
                },
                set(value) {
                    this.inputValue = value;
                    if (this.inputValue) {
                        if (this.selectionMode === "range") {
                            const stringDatesArr = [];
                            this.inputValue.forEach((val) => {
                                if (val) {
                                    const offset = val.getTimezoneOffset();
                                    const fixedDate = new Date(val.getTime() - (offset * 60 * 1000));
                                    stringDatesArr.push(fixedDate.toISOString().split("T")[0]);
                                }
                            });
                            this.$emit("update:modelValue", stringDatesArr);
                        } else if (this.inputValue) {
                            const offset = value.getTimezoneOffset();
                            const fixedDate = new Date(value.getTime() - (offset * 60 * 1000));
                            this.$emit("update:modelValue", fixedDate.toISOString().split("T")[0] + "T"
                                + fixedDate.toISOString().split("T")[1].substring(0, 5));
                        }
                    }
                },
            },

            computedClasses() {
                return this.errorMessage ? "p-invalid" : "";
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

    };
</script>

<style lang="less">
    @import "../../../assets/theme/variable";
    body {
    .custom-calendar {
        .p-calendar {
            background-color: @color-white;
            border-radius: 0;
        }

        .p-calendar:not(.p-disabled).p-focus {
            border-left: none;
            border-right: none;
            border-top: none;
            border-bottom: 2px solid @color-black;
            box-shadow: none;
            -webkit-box-shadow: none;
        }

        .p-calendar .p-multiselect-label {
            text-align: left;
        }
        .p-calendar-w-btn {
            .input;
            padding: 0;
            .p-button-icon-only {
                border-top-right-radius: 30px;
                border-bottom-right-radius: 30px;
                background-color: #f2f2f2;
                width: 62px;
            }
        }
        // input {
        //     .input;
        // }
    }
    }
</style>
