import {createApp, h} from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import PrimeVue from "primevue/config";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import {createI18n} from "vue-i18n";
import router from "./router";
import store from "./store";
import App from "./App";
import {isTokenValidUsingGET as isTokenValid, setDomain} from "./swagger/vue-api-client";
import "./assets/theme/theme.css";
import {SystemRole} from "./utils/SystemRole";
import {messagesPL} from "./messages/messages_pl";
import {primeVueLocale_pl} from "@/messages/primeVueLocale_pl";
import "./validators";
import {ThemeSettings} from "@/mixin/themeSettings";

setDomain(process.env.VUE_APP_API_URL);

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        isTokenValid().then(() => {
            // token jest ważny, teraz należy sprawdzić role
            const role = SystemRole.getUserRole();

            if (to.matched.some((record) => record.meta.roles && record.meta.roles.indexOf(role) < 0)) {
                // użytkownik nie ma odpowiedniej roli aby wejść na daną stronę
                return next({name: "forbidden"});
            }
                return next();
        }, () => {
            localStorage.removeItem("token");
            return next({name: "login"});
        });
    } else {
        return next();
    }
});

router.afterEach((to) => {
    // Konfiguracja GA - działa tylko gdy na stronie jest kod GA
    if (typeof gtag !== "undefined") {
        gtag("set", "page_path", to.path);
        gtag("event", "page_view");
    } else {
        window.console.log("GA not initialised");
    }
});

const messages = {
    PL: {
        message: messagesPL,
    },
};

// Create VueI18n instance with options
const i18n = createI18n({
    locale: localStorage.getItem("language") ? localStorage.getItem("language") : "PL",
    messages,
});

const myMixin = {
    mounted() {
        this.checkIfExistsPaginationAndAddLabel();
        this.checkIfSelectsExistsAndAddLabel();
        this.checkIfCarouselExistsAndAddLabel();
    },
    watch: {
        $route() {
            this.checkIfExistsPaginationAndAddLabel();
            this.checkIfSelectsExistsAndAddLabel();
            this.checkIfCarouselExistsAndAddLabel();
        },
    },
    methods: {
        checkIfExistsPaginationAndAddLabel() {
            const observer = new MutationObserver(() => {
                if (document.querySelectorAll(".p-paginator-first")[0]) {
                    this.addLabelToButtonPaginator();
                    observer.disconnect();
                }
            });
            observer.observe(document, {
                attributes: true,
                childList: true,
                characterData: true,
                subtree: true,
            });
        },
        addLabelToButtonPaginator() {
            const paginatorFirst = document.querySelectorAll(".p-paginator-first");
            for (let i = 0; i < paginatorFirst.length; i += 1) {
                if (!paginatorFirst[i].classList.contains("first-page")) {
                    const buttonLabel = document.createElement("span");
                    paginatorFirst[i].classList.add("first-page");
                    buttonLabel.classList.add("visually-hidden");
                    buttonLabel.innerHTML = "Pierwsza strona";
                    paginatorFirst[i].appendChild(buttonLabel);
                }
            }
            const paginatorLast = document.querySelectorAll(".p-paginator-last");
            for (let i = 0; i < paginatorLast.length; i += 1) {
                if (!paginatorLast[i].classList.contains("last-page")) {
                    const buttonLabel = document.createElement("span");
                    paginatorLast[i].classList.add("last-page");
                    buttonLabel.classList.add("visually-hidden");
                    buttonLabel.innerHTML = "Ostatnia strona";
                    paginatorLast[i].appendChild(buttonLabel);
                }
            }
            const paginatorPrev = document.querySelectorAll(".p-paginator-prev");
            for (let i = 0; i < paginatorPrev.length; i += 1) {
                if (!paginatorPrev[i].classList.contains("prev-page")) {
                    const buttonLabel = document.createElement("span");
                    paginatorPrev[i].classList.add("prev-page");
                    buttonLabel.classList.add("visually-hidden");
                    buttonLabel.innerHTML = "Poprzednia strona";
                    paginatorPrev[i].appendChild(buttonLabel);
                }
            }
            const paginatorNext = document.querySelectorAll(".p-paginator-next");
            for (let i = 0; i < paginatorNext.length; i += 1) {
                if (!paginatorNext[i].classList.contains("next-page")) {
                    const buttonLabel = document.createElement("span");
                    paginatorNext[i].classList.add("next-page");
                    buttonLabel.classList.add("visually-hidden");
                    buttonLabel.innerHTML = "Następna strona";
                    paginatorNext[i].appendChild(buttonLabel);
                }
            }
            const paginatorRppOptions = document.querySelectorAll(".p-paginator-rpp-options");
            for (let i = 0; i < paginatorRppOptions.length; i += 1) {
                if (!paginatorRppOptions[i].classList.contains("rpp-options")
                    && paginatorRppOptions[i].children.length > 0
                    && paginatorRppOptions[i].children[0].classList.contains("p-hidden-accessible")) {
                    paginatorRppOptions[i].classList.add("rpp-options");
                    const rppOptionsInput = paginatorRppOptions[i].children[0].children[0];
                    rppOptionsInput.setAttribute("id", "rpp-options-input-" + i);
                    const rppOptionsInputLabel = document.createElement("label");
                    rppOptionsInputLabel.setAttribute("for", "rpp-options-input-" + i);
                    rppOptionsInputLabel.innerHTML = "Wiersze na stronę";
                    paginatorRppOptions[i].children[0].appendChild(rppOptionsInputLabel);
                }
            }
        },
        checkIfSelectsExistsAndAddLabel() {
            const observer = new MutationObserver(() => {
                if (document.querySelectorAll(".p-multiselect")[0]
                    || document.querySelectorAll(".custom-select-one")[0]) {
                    this.addLabelsToSelects();
                    observer.disconnect();
                }
            });
            observer.observe(document, {
                attributes: true,
                childList: true,
                characterData: true,
                subtree: true,
            });
        },
        addLabelsToSelects() {
            const multiSelect = document.querySelectorAll(".p-multiselect");
            for (let i = 0; i < multiSelect.length; i += 1) {
                if (!multiSelect[i].classList.contains("multi-select")
                    && multiSelect[i].children.length > 0
                    && multiSelect[i].children[0].classList.contains("p-hidden-accessible")) {
                    multiSelect[i].classList.add("multi-select");
                    const multiSelectInput = multiSelect[i].children[0].children[0];
                    multiSelectInput.setAttribute("id", "multi-select-input-" + multiSelect[i].id);
                    const multiSelectInputLabel = document.createElement("label");
                    multiSelectInputLabel.setAttribute("for", "multi-select-input-" + multiSelect[i].id);
                    multiSelectInputLabel.setAttribute("style", "display: none;");
                    multiSelectInputLabel.innerHTML = "Pole wielokrotnego wyboru";
                    multiSelect[i].children[0].appendChild(multiSelectInputLabel);
                }
            }
            const selectOne = document.querySelectorAll(".custom-select-one");
            for (let i = 0; i < selectOne.length; i += 1) {
                if (!selectOne[i].classList.contains("has-label")) {
                    selectOne[i].classList.add("has-label");
                    const input = selectOne[i].children[0].children[0].children[0];
                    input.setAttribute("id", "input-field-" + selectOne[i].children[0].id);
                    const label = document.createElement("label");
                    label.setAttribute("for", "input-field-" + selectOne[i].children[0].id);
                    label.setAttribute("style", "display: none;");
                    label.innerHTML = "wartość";
                    selectOne[i].children[0].children[0].appendChild(label);
                }
            }
        },
        checkIfCarouselExistsAndAddLabel() {
            const observer = new MutationObserver(() => {
                if (document.querySelectorAll(".p-carousel")[0]) {
                    this.addLabelsToCarousel();
                    observer.disconnect();
                }
            });
            observer.observe(document, {
                attributes: true,
                childList: true,
                characterData: true,
                subtree: true,
            });
        },
        addLabelsToCarousel() {
            const prevButton = document.querySelectorAll(".p-carousel-prev");
            for (let i = 0; i < prevButton.length; i += 1) {
                if (!prevButton[i].classList.contains("has-alt-txt")) {
                    prevButton[i].classList.add("has-alt-txt");
                    prevButton[i].setAttribute("aria-label", "Obróć karuzelę w lewo");
                }
            }
            const nextButton = document.querySelectorAll(".p-carousel-next");
            for (let i = 0; i < nextButton.length; i += 1) {
                if (!nextButton[i].classList.contains("has-alt-txt")) {
                    nextButton[i].classList.add("has-alt-txt");
                    nextButton[i].setAttribute("aria-label", "Obróć karuzelę w prawo");
                }
            }
            const indicatorButton = document.querySelectorAll(".p-carousel-indicator");
            for (let i = 0; i < indicatorButton.length; i += 1) {
                if (!indicatorButton[i].classList.contains("has-alt-txt")) {
                    indicatorButton[i].classList.add("has-alt-txt");
                    indicatorButton[i].children[0].setAttribute("aria-label", "Obróć karuzelę " + i);
                }
            }
        },
    },
};

const app = createApp({
    mixins: [myMixin],
    i18n,
    render: () => h(App),
    }).use(router)
    .use(store)
    .use(VueAxios, axios)
    .use(ToastService)
    .use(ConfirmationService)
    .use(i18n)
    .use(PrimeVue, primeVueLocale_pl)
    .mixin(ThemeSettings)
    .mount("#app");

app.axios.interceptors.request.use((config) => {
    if (localStorage.getItem("token") !== null) {
        config.headers.Authorization = "Bearer " + localStorage.getItem("token");
    }

    return config;
});
