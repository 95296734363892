<template>
    <div>
        <div class="remind-password-panel-header">
            <router-link :to="{name: 'search'}">
<!--TODO logo               <img src="../assets/UActiv_logo/PION/UACTIV_PION_kolor-RGB.png" alt="logo"/>-->
            </router-link>
            <h1 v-if="!sending">{{ $t('message.passwordRemind.title') }}</h1>
        </div>
        <div v-if="sending" class="remind-password-panel-content">
            <h2>Proszę sprawdzić skrzynkę</h2>
            <br/>
            <h3>Jeżeli podany e-mail znajduje się w bazie powinna
                przyjść wiadomość z linkiem do zresetowania hasła.</h3>
            <br/>
            <h3>W razie problemów prosimy o kontakt z administratorem.</h3>
            <br/>
            <router-link :to="{name: 'search'}">
                Powrót na stronę główną
            </router-link>
        </div>
        <div v-else class="remind-password-panel-content">
            <div class="p-grid">
                <div class="p-col-12" @keyup.enter="remind">
                    <CustomInputText name="login" v-model="passwordRemindForm.email" icon="pi pi-envelope"
                                     :label="$t('message.passwordRemind.email')"
                                     rules="email"/>
                </div>
                <div class="p-col-12">
                    <Button :label="$t('message.passwordRemind.action.send')" @click="remind" class="button"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import {remindPasswordUsingPOST as remindPassword} from "@/swagger/vue-api-client";
    import CustomInputText from "../components/form/CustomInputText";

    export default {
        name: "PasswordRemindView",
        components: {CustomInputText, Button},

        data() {
            return {
                sending: false,
                passwordRemindForm: {
                    email: "",
                },
            };
        },

        methods: {
            remind() {
                this.sending = true;
                grecaptcha.ready(() => {
                    grecaptcha.execute(process.env.VUE_APP_CAPTCHA_KEY, {action: "submit"}).then((token) => {
                        this.setToken(token);
                        this.sendRemindRequest();
                    });
                });
            },
            setToken(token) {
                this.passwordRemindForm.captchaToken = token;
            },
            sendRemindRequest() {
                remindPassword({passwordRemindRequest: this.passwordRemindForm});
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "assets/less/remind-password.less";
</style>
