<template>
    <div class="organization-search-criteria" @keyup.enter="search">
        <CustomInputText name="name" label="Szukaj organizacji" v-model="internalValue.name" width="400"/>
    </div>
</template>

<script>
    import CustomInputText from "../../../components/form/CustomInputText";

    export default {
        name: "OrganizationSearchCriteria",

        props: {
            modelValue: null,
        },

        methods: {
            search() {
                this.$emit("search");
            },
        },

        emits: ["update:modelValue", "search"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        components: {
            CustomInputText,
        },
    };
</script>

<style lang="less" scoped>
</style>
