<template>
    <div :style="onBottom ? 'margin-top: 100px' : ''">
        <Button label="ZAPISZ" @click="onClick" :class="class" :type="type"
                :disabled="disabled"/>
    </div>
</template>

<script>
    import Button from "primevue/button";

    export default {
        name: "SaveButton",
        props: {
            onClick: {
                type: Function,
            },
            onBottom: {
                type: Boolean,
                default: false,
            },
            type: {
                type: String,
                default: null,
            },
            disabled: {
                type: Boolean,
                default: null,
            },
            class: {
                type: String,
                default: "button button-filled",
            },
        },
        components: {Button},
    };
</script>

<style lang="less" scoped>
.save-button-container {
    margin-top: 100px;
}
</style>
