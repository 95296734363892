<template>
    <div class="page-header">
        <div class="page-header-text">
        </div>
    </div>
    <div class="conditions" v-if="privacyPolicy" v-html="privacyPolicy"/>
</template>

<script>
    import {getPrivacyPolicyUsingGET as getPrivacyPolicy} from "@/swagger/vue-api-client";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "PrivacyPolicy",
        data() {
            return {
                privacyPolicy: null,
            };
        },
        beforeMount() {
            // eslint-disable-next-line no-return-assign
            getPrivacyPolicy().then((response) => this.privacyPolicy = response.data)
                .catch(() => { ToastUtils.showError(this); });
        },
    };
</script>

<style scoped>
    .conditions {
        max-width: 1400px;
        margin:auto
    }
</style>
