import Vuex from "vuex";
// eslint-disable-next-line import/no-cycle
import {ThemeSettingsHelper} from "./mixin/themeSettings";

export default Vuex.createStore({
    state() {
        return {
            theme: "normal",
            fontSize: 0,
        };
    },
    mutations: {
        setNormalContrast(state) {
            state.theme = ThemeSettingsHelper.normalContrast;
        },
        setBlackContrast(state) {
            state.theme = ThemeSettingsHelper.blackContrast;
        },
        setYellowContrast(state) {
            state.theme = ThemeSettingsHelper.yellowContrast;
        },
        setNormalFontSize(state) {
            state.fontSize = ThemeSettingsHelper.normalFontSize;
        },
        setBigFontSize(state) {
            state.fontSize = ThemeSettingsHelper.bigFontSize;
        },
        setBiggerFontSize(state) {
            state.fontSize = ThemeSettingsHelper.biggerFontSize;
        },
    },
});
