<template>
    <div class="menu-wrapper">
        <i class="pi pi-bars" @click="changeItemsVisibility" v-if="showBars"/>
        <div class="menu-items" v-if="showItems">
            <router-link v-if="SystemRole.isOrganizer() || SystemRole.isUser()" :to="{name: 'search'}"
                         :class="$route.name === 'search' ? 'active' : ''">
                Wyszukiwarka
            </router-link>
            <router-link v-if="SystemRole.isAdmin()" :to="{name: 'search'}"
                         :class="$route.name === 'search' ? 'active' : ''">
                Aktywności
            </router-link>
            <router-link v-if="SystemRole.isAdmin()" :to="{name: 'organizerList'}"
                         :class="$route.name === 'organizerList' ? 'active' : ''">
                Organizatorzy
            </router-link>
            <router-link v-if="SystemRole.isAdmin()" :to="{name: 'userList'}"
                         :class="$route.name === 'userList' ? 'active' : ''">
                Użytkownicy
            </router-link>
            <router-link v-if="SystemRole.isOrganizer()"
                         :to="{name: 'organizationEdit', params: {id: organizationId}}"
                         :class="$route.name === 'organizationEdit' ? 'active' : ''">
                Moja&nbsp;organizacja
            </router-link>
            <router-link v-if="SystemRole.isOrganizer()"
                         :to="{name: 'organizerEdit', params: {id: id}}"
                         :class="$route.name === 'organizerEdit' ? 'active' : ''">
                Moje&nbsp;konto
            </router-link>
            <router-link v-if="SystemRole.isUser()" :to="{name: 'userEdit', params: {id: id}}"
                         :class="$route.name === 'userEdit' ? 'active' : ''">
                Moje&nbsp;konto
            </router-link>
            <router-link v-if="SystemRole.isAdmin()" :to="{name: 'adminEdit', params: {id: id}}"
                         :class="$route.name === 'adminEdit' ? 'active' : ''">
                Moje&nbsp;konto
            </router-link>
            <router-link v-if="SystemRole.isAdmin()" :to="{name: 'systemSettings'}"
                         :class="$route.name === 'systemSettings' ? 'active' : ''">
                Konfiguracja
            </router-link>
            <router-link v-if="!SystemRole.isLogged()" to="/login">
                Zaloguj&nbsp;się
            </router-link>
            <a v-if="SystemRole.isLogged()" @click="logout">
                Wyloguj
            </a>
        </div>
    </div>
</template>

<script>
    import {SystemRole} from "@/utils/SystemRole";

    export default {
        name: "AppMenu",

        data() {
            return {
                SystemRole,
                id: null,
                organizationId: null,
                showItems: true,
                showBars: false,
            };
        },

        beforeMount() {
            this.id = localStorage.getItem("id");
            this.organizationId = localStorage.getItem("organizationId");
            this.responsiveNav();
            window.addEventListener("resize", () => {
                this.responsiveNav();
            });
        },

        methods: {
            responsiveNav() {
                const windowWidth = window.innerWidth;
                if (windowWidth <= 1493) {
                    this.showItems = false;
                    this.showBars = true;
                } else {
                    this.showItems = true;
                    this.showBars = false;
                }
            },
            logout() {
                localStorage.removeItem("token");
                localStorage.removeItem("role");
                localStorage.removeItem("fullName");
                localStorage.removeItem("organizationId");
                localStorage.removeItem("id");

                this.$router.push({name: "login"});
            },

            changeItemsVisibility() {
                this.showItems = !this.showItems;
            },
        },
    };
</script>

<style lang="less">
    @import "@/components/assets/less/app-menu.less";
</style>
