<template>
    <div class="page-header">
        <div class="page-header-text">
        </div>
    </div>
    <div class="conditions" v-if="termsOfUse" v-html="termsOfUse" style=""/>
</template>

<script>
    import {getUseOfTermsTextUsingGET as getTermsOfUse} from "@/swagger/vue-api-client";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "TermsOfUse",
        data() {
            return {
                termsOfUse: null,
            };
        },
        beforeMount() {
            // eslint-disable-next-line no-return-assign
            getTermsOfUse().then((response) => this.termsOfUse = response.data)
                .catch(() => { ToastUtils.showError(this); });
        },
    };
</script>

<style scoped>
    .conditions {
        max-width: 1400px;
        margin:auto
    }
</style>
