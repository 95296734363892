<template>
    <div class="custom-select-one" :style="'width: ' + width + 'px;'">
        <Dropdown :class="dropdownClass" style="width: 90%" :id="name" :options="internalItems" :optionLabel="itemLabel"
                  :optionValue="itemValue" :disabled="disabled"
                  @change="onChange" :optionDisabled="optionDisabled"
                  v-model="internalValue" :placeholder="typeof internalValue === 'String' ? '' : label"/>
        <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
    </div>
</template>

<script>
    import Dropdown from "primevue/dropdown";
    import {useField} from "vee-validate";

    export default {
        name: "DesignedSelectOne",
        props: {
            dropdownClass: {
                type: String,
                default: null,
            },
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            itemLabel: {
                type: String,
            },
            itemValue: {
                type: String,
            },
            width: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            emptyValue: {
                type: Boolean,
                default: true,
            },
            items: {
                type: Array,
            },
            modelValue: null,
            rules: undefined,
            optionDisabled: {
                default: undefined,
                required: false,
            },
        },

        emits: ["update:modelValue", "change"],

        data() {
            return {
                internalItems: this.items,
                selectedValue: null,
            };
        },

        watch: {
            items: {
                handler(newVal) {
                    if (this.emptyValue) {
                        this.internalItems = [{value: null, label: this.$t("message.other.emptyValue")}, ...newVal];
                    } else {
                        this.internalItems = this.items;
                    }
                },
            },
            modelValue() {
                this.inputValue = this.modelValue;
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        methods: {
            onChange(val) {
                if (val && (val.value || val.value === false)) {
                    this.handleChange(val.originalEvent);
                    this.$emit("change", val.value);
                } else {
                    this.handleChange(undefined);
                    this.$emit("change", undefined);
                }
            },
        },

        components: {Dropdown},

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

    };
</script>

<style lang="less">
@import "../../../assets/theme/variable.less";
.custom-select-one {
        .p-dropdown {
            background-color: @color-white;
            border-radius: 0;
            border-radius: 30px;
            padding: 1rem 1.5rem;

            box-shadow: rgba(0, 0, 0, 8%) 0px 1px 2px, rgba(0, 0, 0, 5%) 0px 4px 12px;
            border: 1px solid rgb(221, 221, 221);
            .p-inputtext {
                padding: 0;
            }
        }
        label {
            font-size: 12px;
            color: #898989;
            top: -10px;
            background-color: #ffffff;
            padding: 2px 4px;
            margin-left: -4px;
            margin-top: 0;
            position: absolute;
            left: 1rem;
            z-index: 2;
        }

        .p-multiselect {
            width: 100%;
        }
}
</style>
