<template>
    <div class="form-view">
        <Form @submit="saveOrganizer" v-slot="{ isSubmitting}">
            <div class="page-header">
                <div class="page-header-text">
                    <h1 class="header-text">{{this.title}}</h1>
                    <SaveButton class="button button-filled-white" type="submit" :disabled="isSubmitting"/>
                </div>
            </div>
            <div class="page-content general-container">
                <div class="data">
                    <div class="row">
                        <div class="label">Imię</div>
                        <CustomInputText name="firstName" v-model="organizer.firstName" no-icon
                                         label="imię" width="500"
                                         rules="required|firstNameLength"/>
                    </div>
                    <div class="row">
                        <div class="label">Nazwisko</div>
                        <CustomInputText name="lastName" v-model="organizer.lastName" no-icon
                                         label="nazwisko" width="500"
                                         rules="required|lastNameLength"/>
                    </div>
                    <div class="row">
                        <div class="label">Email</div>
                        <CustomInputText name="email"
                                         v-model="organizer.email"
                                         no-icon
                                         label="adres email"
                                         width="500"
                                         :disabled="edit"
                                         :rules="computedRules"/>
                    </div>
                    <div>
                        <div class="row">
                            <div class="label">{{passwordLabel}}</div>
                            <CustomPassword name="password" v-model="changedPassword"
                                            :label="$t('message.register.password')" width="400"
                                            :rules="computedPasswordRules" :feedback="true"/>
                        </div>
                        <div class="row">
                            <div class="label">Powtórz hasło</div>
                            <CustomPassword name="password2" v-model="changedRepeatedPassword"
                                            :label="$t('message.register.password2')" width="400"
                                            :rules="computedRepeatPasswordRules"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="label">Telefon</div>
                        <CustomInputMask name="phone" v-model="organizer.phoneNumber" label="telefon kontaktowy"
                                         width="500" mask="(+48) 999 999 999"
                                         rules="phoneNumberLength"/>
                    </div>
                    <div class="row">
                        <div class="label">Organizacja</div>
                        <DesignedSelectOrganization name="organization" v-model="organizer.organizationId"
                                                    label="organizacja" width="500"
                                                    rules="required"
                                                    :disabled="SystemRole.isOrganizer()"/>
                    </div>
                </div>
                <div class="organizater-form-buttons-action">
                    <SaveButton type="submit" :disabled="isSubmitting"/>
                    <DeleteButton v-if="edit"
                                  @click="deleteOrganizer(organizer.id, $event)"
                                  :disabled="isSubmitting" />
                </div>
            </div>
        </Form>
    </div>
</template>

<script>
    import {Form} from "vee-validate";
    import {
        createOrUpdateUserUsingPOST as createOrUpdateUser,
        deleteMyAccountUsingDELETE as deleteMyAccount,
        deleteUserUsingDELETE as deleteUser,
        emailExistUsingGET as emailExist,
        getUserUsingGET as getUser,
    } from "@/swagger/vue-api-client";
    import CustomInputText from "../../components/form/CustomInputText";
    import {SystemRole} from "@/utils/SystemRole";
    import DesignedSelectOrganization from "@/components/form/selectOne/DesignedSelectOrganization";
    import CustomInputMask from "@/components/form/CustomInputMask";
    import SaveButton from "@/components/form/SaveButton";
    import DeleteButton from "@/components/form/DeleteButton";
    import CustomPassword from "@/components/form/CustomPassword";

    export default {
        name: "OrganizerFormView",

        components: {
            SaveButton,
            CustomInputMask,
            DesignedSelectOrganization,
            CustomInputText,
            DeleteButton,
            Form,
            CustomPassword,
        },

        props: {
            value: {},
            create: false,
        },

        data() {
            return {
                organizer: {
                    id: null,
                    firstName: null,
                    lastName: null,
                    email: null,
                    phoneNumber: null,
                    organizationId: null,
                    password: null,
                    systemRole: SystemRole.ORGANIZER,
                },
                changedPassword: null,
                changedRepeatedPassword: null,
                loaded: false,
                title: "Edycja konta członka organizacji",
                registering: false,
                edit: true,
                SystemRole,
            };
        },

        beforeMount() {
            if (SystemRole.isOrganizer() && this.$route.name === "organizerEdit"
                && !(this.$route.params.id === localStorage.getItem("id").toString())) {
                this.$router.push({name: "organizerEdit", params: {id: localStorage.getItem("id")}});
            }

            if (this.create) {
                this.title = "Tworzenie konta członka organizacji";
                this.edit = false;
                this.organizer.password = this.createPassword();
            }
            if (this.$route.params.id) {
                getUser({id: this.$route.params.id}).then((response) => {
                    this.organizer.id = response.data.id;
                    this.organizer.firstName = response.data.firstName;
                    this.organizer.lastName = response.data.lastName;
                    this.organizer.email = response.data.email;
                    this.organizer.password = response.data.password;
                    this.organizer.systemRole = response.data.systemRole;
                    this.organizer.phoneNumber = response.data.phoneNumber;
                    this.organizer.emailVerified = response.data.emailVerified;
                    this.organizer.organizationId = response.data.organizationId;
                }).catch((error) => {
                    if (error.response.status === 404) {
                        this.$router.push({name: "notFound"});
                    } else if (error.response.status === 403) {
                        this.$router.push({name: "forbidden"});
                    } else {
                        this.showGetUserErrorToast();
                    }
                });
                this.loaded = true;
            } else {
                this.loaded = true;
            }
        },

        computed: {
            computedRules() {
                return this.edit ? "" : "required|email|emailLength";
            },
            computedPasswordRules() {
                return this.create || ((this.changedPassword != null) && (this.changedPassword !== ""))
                    ? "required|validOptionalPassword" : "validOptionalPassword";
            },
            computedRepeatPasswordRules() {
                return this.create ||  ((this.changedPassword != null) && (this.changedPassword !== ""))
                    ? "required|confirmed:@password,Hasła" : "confirmed:@password,Hasła";
            },
            passwordLabel() {
                return this.create ? "Hasło" : "Zmiana hasła";
            }
        },

        methods: {
            async saveOrganizer(value, actions) {
                this.registering = true;
                let valid = true;

                if (!this.edit) {
                    await emailExist({email: this.organizer.email})
                        .then((response) => {
                            if (response.data) {
                                actions.setFieldError("email", "Istnieje już użytkownik o takim adresie email");
                                valid = false;
                            }
                        }).catch(() => {
                            this.showErrorToast();
                            valid = false;
                        });

                    if (!valid) {
                        this.registering = false;
                        return;
                    }
                }

                if (this.changedPassword != null && this.changedPassword !== "") {
                    this.organizer.password = this.changedPassword;
                }

                await createOrUpdateUser({userDto: this.organizer})
                    .then((response) => {
                        this.$toast.add({
                            severity: "success",
                            summary: "Sukces",
                            detail: "Zapisano dane użytkownika",
                            life: 3000,
                        });
                        if (this.create) {
                            this.$router.push({name: "organizerEdit", params: {id: response.data.id}});
                        }
                    }).catch(() => {
                        this.showErrorToast();
                        valid = false;
                });
                this.registering = false;
            },

            createPassword() {
                const length = 8;
                let result = "";
                const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
                const charactersLength = characters.length;
                for (let i = 0; i < length; i += 1) {
                    result += characters.charAt(Math.floor(Math.random()
                        * charactersLength));
                }
                return result;
            },

            deleteOrganizer(id, event) {
                this.$confirm.require({
                    target: event.currentTarget,
                    header: "Czy na pewno usunąć konto?",
                    message: "Tej operacji nie będzie można cofnąć.",
                    acceptLabel: "Tak",
                    rejectLabel: "Nie",
                    position: "topright",
                    accept: () => {
                        const func = SystemRole.isAdmin() ? deleteUser({id}) : deleteMyAccount();
                        func.then(() => {
                            this.$toast.add({
                                severity: "success",
                                summary: "Sukces",
                                detail: "Pomyślnie usunięto konto",
                                life: 3000,
                            });
                            // Jeśli użytkownik usuwa swoje konto to go wylogowujemy
                            if (!SystemRole.isAdmin()) {
                                SystemRole.logout();
                            }
                            this.$router.push("/");
                        }).catch((error) => {
                            if (error.response.status === 403) {
                                this.$toast.add({
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Nie masz wystarczających uprawnień",
                                    life: 3000,
                                });
                            } else {
                                this.$toast.add({
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd",
                                    life: 3000,
                                });
                            }
                        });
                    },
                });
            },

            showErrorToast() {
                this.$toast.add({
                    severity: "error",
                    summary: "Wystąpił nieoczekiwany błąd",
                    detail: "Prosimy o kontakt z administratorem",
                    life: 3000,
                });
            },

            showGetUserErrorToast() {
                this.$toast.add({
                    severity: "info",
                    summary: "Nie można pobrać danych członka organizacji",
                    detail: "Prosimy o kontakt z administratorem",
                    life: 3000,
                });
            },
        },
    };
</script>

<style lang="less" scoped>
@import "src/views/assets/less/form-view";
.organizater-form-buttons-action {
    display: flex;
    justify-content: space-between;
    margin-top: 40px
}
</style>
