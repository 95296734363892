<template>
    <div>
        <div class="page-header">
            <div class="page-header-text">
                <CustomTitle title="Wszystkie wydarzenia"/>
            </div>
        </div>
        <div class="page-content general-container">
            <v-card class="sm:flex-wrap organizations-buttons-action">
                <EventEditionViewSearchCriteria
                    ref="eventEditionViewSearchCriteria"
                    v-model="searchCriteria"
                    @search="$refs.EventEditionViewTable.search()"/>
                <div class="flex">
                    <Button @click="clear" label="Wyczyść"
                            class="button button-filled" icon="pi pi-times"/>
                    <Button @click="search" label="Szukaj"
                            class="button button-filled" icon="pi pi-search"/>
                </div>
            </v-card>
            <EventEditionTable ref="EventEditionViewTable"
                               :search-criteria="searchCriteria"/>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import {SearchCriteria} from "../../utils/SearchCriteria";
    import CustomTitle from "@/components/CustomTitle";
    import EventEditionViewSearchCriteria
        from "@/views/admin/components/EventEditionViewSearchCriteria";
    import EventEditionTable from "@/views/admin/components/EventEditionTable";

    export default {
        name: "EventEditionListView",
        components: {
            EventEditionTable,
            EventEditionViewSearchCriteria,
            Button,
            CustomTitle,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);
                this.$refs.EventEditionViewTable.search();
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
            },

            getClearSearchCriteria() {
                return {
                    eventName: "",
                    organizationName: "",
                    eventStatus: undefined,
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: "id",
                        sortOrder: -1,
                    },
                };
            },
        },
    };
</script>

<style lang="less">
.organizations-buttons-action {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
@media screen and (max-width: 830px) {
    .sm\:flex-wrap{
        flex-wrap: wrap !important;
    }
}
</style>
