/* eslint-disable */
export const SystemRole = {
    ADMIN: "ADMIN",
    ORGANIZER: "ORGANIZER",
    USER: "USER",

    isAdmin() {
        return localStorage.getItem("role") === this.ADMIN;
    },

    isOrganizer() {
        return localStorage.getItem("role") === this.ORGANIZER;
    },

    isUser() {
        return localStorage.getItem("role") === this.USER;
    },

    isLogged() {
        return localStorage.getItem("token");
    },

    getUserRole() {
        return localStorage.getItem("role");
    },

    logout() {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        localStorage.removeItem("fullName");
        localStorage.removeItem("id");
        localStorage.removeItem("organizationId");
    }

};
