<template>
    <div>
        <Button @click="createEvent" label="Dodaj aktywność" @keyup.enter="createEvent" icon="pi pi-plus" class="button button-filled"></Button>
    </div>
</template>

<script>
    import Button from "primevue/button";
    export default {
        name: "AddEvent",
        components: {Button},

        methods: {
            createEvent() {
                this.$router.push({name: "eventCreate"});
            },
        },

    };
</script>

<style lang="less" scoped>
    @import "../../assets/less/image-tile-add";
</style>
