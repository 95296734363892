<template>
    <div class="events-map">
        <GoogleMap
            ref="googleMap"
            :api-key="MapUtils.getGmapsApiKey()"
            :style="'width: 100%; height:' + mapHeight"
            :center="center"
            :zoom="11"
        >
            <!-- Wydarzeń online nie wyświetlamy na mapie -->
            <template v-for="(event, index) of notOnlineEvents" :key="index">
                <Marker :ref="'marker' + index"
                        :options="MapUtils.getMarkerOptions(event)"
                        @click="markerClick(event, index)" />
            </template>

            <Marker v-if="located"
                    :ref="'positionMarker'"
                    :options="getPositionMarkerOptions()"
                    @click="positionMarkerClick()"/>
        </GoogleMap>
    </div>
</template>

<script>
    import {GoogleMap, Marker} from "vue3-google-map";
    import {MapUtils} from "@/utils/MapUtils";
    import {EventUtils} from "@/utils/EventUtils";

    export default {
        name: "EventsMap",
        components: {
            GoogleMap, Marker,
        },
        emits: ["mapEventClicked"],

        props: {
            events: {
                type: Array,
            },
        },

        data() {
            return {
                MapUtils,
                infoWindow: null,
                userLocation: {
                    position: {
                        lat: 54.35,
                        lng: 18.64,
                    },
                },
                center: {
                    lat: 54.35,
                    lng: 18.64,
                },
                located: false,
                mapHeight: "",
            };
        },

        mounted() {
            this.getUserLocation();
            this.$nextTick(() => {
                this.computedMapHeight();
            });
        },

        computed: {
            notOnlineEvents() {
                return this.events.filter((e) => e.disctrict !== "ONLINE");
            },
        },

        methods: {
            computedMapHeight() {
                if (document.getElementsByClassName("search-page-header").length > 0) {
                    const headerHeight = document.getElementsByClassName("search-page-header")[0].clientHeight;
                    this.mapHeight = window.innerHeight - headerHeight;
                    this.mapHeight += "px";
                } else {
                    this.mapHeight += "300px";
                }
            },
            markerClick(event, index) {
                this.$emit("mapEventClicked", event.id);
                const {map} = this.$refs.googleMap;

                /* eslint-disable-next-line */
                const marker = this.$refs["marker" + index].marker.component._value;

                if (this.infoWindow) {
                    this.infoWindow.close();
                }

                this.infoWindow = new this.$refs.googleMap.api.InfoWindow({
                    content: this.getMarkerContent(event),
                });

                this.infoWindow.open({
                    anchor: marker,
                    map,
                    shouldFocus: false,
                });
            },

            positionMarkerClick() {
                const {map} = this.$refs.googleMap;

                // eslint-disable-next-line no-underscore-dangle
                const marker = this.$refs.positionMarker.marker.component._value;

                if (this.infoWindow) {
                    this.infoWindow.close();
                }

                this.infoWindow = new this.$refs.googleMap.api.InfoWindow({
                    content: "<p class='position-marker''>Jesteś tutaj</p>",
                });

                this.infoWindow.open({
                    anchor: marker,
                    map,
                    shouldFocus: false,
                });
            },

            getMarkerContent(event) {
                let content = "<a style='text-decoration: none;' href=\"/event/" + event.id + "\">"
                    + "<div class=\"marker-content\">";
                content += "<h3>" + event.name + "</h3>";

                content += this.getMarkerContentRow("Termin", this.getEventTerm(event));
                // eslint-disable-next-line max-len
                content += this.getMarkerContentRow("Miejsce", this.displayEmptyStringIfNull(event.district === "ONLINE" ? "ONLINE" : event.address));
                // eslint-disable-next-line max-len
                content += this.getMarkerContentRow("Kontakt", this.displayEmptyStringIfNull(event.email) + "<br>" + this.displayEmptyStringIfNull(event.phoneNumber));

                content += "<h4>Kliknij na tekst, aby dowiedzieć się więcej</h4>";

                content += "</div></a>";

                return content;
            },


            displayEmptyStringIfNull(text) {
                if (!text) {
                    return "";
                }
                return text;
            },

            getEventTerm(event) {
                return EventUtils.getTermLabel(event);
            },

            getMarkerContentRow(label, data) {
                return "<div class=\"row\"><div class=\"label\">" + label
                    + "</div><div class=\"value\">" + data + "</div></div>";
            },

            async getUserLocation() {
                await navigator.geolocation.getCurrentPosition((position) => {
                    this.userLocation.position = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };

                    // Najbardziej wysunięte punkty Gdańska
                    // N
                    // 54.4480644989671, 18.454557495734672;
                    // E
                    // 54.36222015926971, 18.948184597870444;
                    // S
                    // 54.27510091344945, 18.635984361092735;
                    // W
                    // 54.38590905643305, 18.42846790100057;

                    // Code to check whether a user browser in Gdańsk
                    // If so - center map on his location

                    if (this.userLocation.position.lat >= 54.27510091344945
                        && this.userLocation.position.lat <= 54.4480644989671
                        && this.userLocation.position.lng >= 18.42846790100057
                        && this.userLocation.position.lng <= 18.948184597870444) {
                        this.center = this.userLocation.position;
                    }
                    this.located = true;
                });
            },

            getPositionMarkerOptions() {
                return {
                    icon: "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|3D6EC9",
                    position: this.userLocation.position,
                    title: "Jesteś tutaj",
                };
            },
        },
    };
</script>

<style lang="less">
    @import "../../../assets/theme/variable";

    .events-map {
        width: 40%;
        .marker-content {
            h3 {
                margin-top: 0;
                text-align: left;
            }

            .row {
                display: flex;
                margin-bottom: 12px;
                font-size: 12px;
                text-align: left;

                .label {
                    width: 55px;
                    color: @color-gray3;
                }
            }
        }
    }

    .position-marker {
        font-size: 18px;
        margin-left: 10px;
    }

    a {
        &:-webkit-any-link {
        }

        &:hover {
            color: @primary-color;
        }

        &.active {
            border-bottom: solid 2px @primary-color;
            color: @primary-color;
        }
    }

    //Selektory dialogu
    .gm-style-iw {
        &:hover {
            background-color: @color-light-gray;
        }
    }

    .gm-style .gm-style-iw-t::after {
        background: @google-maps-marker-color;
        &:hover {
            background: @google-maps-marker-color;
        }
    }

    .gm-style .gm-style-iw-d::-webkit-scrollbar-track, .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece  {
        background-color: transparent;
    }

    .gm-style .gm-style-iw-d::-webkit-scrollbar-track, .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece  {
        background-color: transparent;
    }
</style>
