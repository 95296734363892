<template>
    <div class="p-fluid custom-input-number" :style="'width: ' + width + 'px;'">
        <span class="p-float-label">
            <InputNumber :id="name" v-model="internalValue" :class="computedClasses" :aria-label="name"
                         :mode="mode" :min="min" :max="max" :minFractionDigits="minFractionDigits"
                         :maxFractionDigits="maxFractionDigits" :suffix="suffix" :placeholder="placeholder"/>
        </span>
        <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
    </div>
</template>

<script>
    import InputNumber from "primevue/inputnumber";
    import {useField} from "vee-validate";

    export default {
        name: "CustomInputNumber",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            min: {
                type: Number,
            },
            max: {
                type: Number,
            },
            minFractionDigits: {

            },
            maxFractionDigits: {
                type: Number,
            },
            mode: {
                type: String,
            },
            rules: undefined,
            modelValue: null,
            width: {
                type: String,
            },
            suffix: {
                type: String,
            },
            placeholder: {
                type: String,
            },
        },
        emits: ["update:modelValue"],

        watch: {
            modelValue: {
                handler() {
                    this.inputValue = this.modelValue;
                },
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },

            computedClasses() {
                return this.errorMessage ? "p-invalid" : "";
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleChange,
                handleInput,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                errorMessage,
                inputValue,
                handleChange,
                handleInput,
                meta,
            };
        },

        methods: {
            inputListener(event) {
                this.$emit("update:modelValue", event.target.value);
            },
        },

        components: {InputNumber},
    };
</script>

<style lang="less">
    @import "../../assets/theme/variable";
    .custom-input-number {
        .p-inputnumber-input {
            background-color: @color-white;
            border-radius: 0;
            border-radius: 30px;
            padding: 1rem 1.5rem;

            box-shadow: rgba(0, 0, 0, 8%) 0px 1px 2px, rgba(0, 0, 0, 5%) 0px 4px 12px;
            border: 1px solid rgb(221, 221, 221);
            &:enabled:focus {
                box-shadow: none;
            }

            &:focus {
                box-shadow: none;
            }
            .p-multiselect-label {
                padding: 0;
            }
        }
        label {
            font-size: 12px;
            color: #898989;
            top: -10px;
            background-color: #ffffff;
            padding: 2px 4px;
            margin-left: -4px;
            margin-top: 0;
            position: absolute;
            left: 1rem;
            z-index: 2;
        }

        .p-multiselect {
            width: 100%;
        }
    }
</style>
