/* eslint-disable */

export const MapUtils = {
    getGmapsApiKey() {
        return process.env.VUE_APP_GMAPS_API_KEY;
    },

    getMarkerOptions(event) {
        if (event.latitude === undefined || event.longitude === undefined) {
            return {};
        }
        return {
            position: {
                lat: event.latitude,
                lng: event.longitude,
            },
            title: event.name + "\n" + "Kliknij, aby wyświetlić informacje o wydarzeniu",
        };
    },

    async getCoordinates(address) {
        let  geocoder = new google.maps.Geocoder();
        return geocoder.geocode({address: address}).then((response) => {
            window.console.debug(response);
            if (response.results.length === 0) {
                return null;
            }

            window.console.debug("geo location: " + response.results[0].geometry.location);
            return response.results[0].geometry.location;
        }).catch((error) => window.console.log(error));
    },
};
