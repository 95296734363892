<template>
    <div>
<!--        TODO: ostylować ten komponent-->
        <Dialog v-if="popupSettings" :header="popupSettings.popupHeader"
            v-model:visible="internalShow" :modal="true">
            <div class="content">
                <div class="image">
                    <img :src="popupSettings.popupImageUrl"/>
                </div>
                <div class="text">
                    {{popupSettings.popupText}}
                </div>
            </div>
            <template #footer>
                <Button label="OK" class="button button-filled" icon="pi pi-check" @click="closeModal"/>
            </template>
        </Dialog>
    </div>
</template>

<script>
    import Dialog from "primevue/dialog";
    import {Form} from "vee-validate";
    import CustomInputTextarea from "@/components/form/CustomInputTextArea";
    import Button from "primevue/button";

    export default {
        name: "SearchViewPopup",
        components: {CustomInputTextarea, Dialog, Form, Button},

        emits: ["update:displayModal"],

        props: {
            popupSettings: {
                type: Object,
            },
            displayModal: {
                type: Boolean,
                defaultValue: false,
            }
        },

        data() {
          return {}
        },

        computed: {
            internalShow: {
                get() {
                    return this.displayModal;
                },
                set(value) {
                    this.$emit("update:displayModal", value);
                },
            },
        },

        methods: {
            closeModal() {
              this.internalShow = false;
            //  TODO: save in local storage fact that user has seen this dialog
            },
        }

    }
</script>

<style scoped lang="less">
    .content {
        .image {
            display: flex;
            justify-content: center;
            img {
                width: 30vw;
                object-fit: cover;
            }
        }
        .text {
            padding: 20px;
        }
    }
</style>
