<template>
    <div>
        <div class="register-panel-header">
            <router-link :to="{name: 'search'}">
<!--TODO logo                <img src="../assets/UActiv_logo/PION/UACTIV_PION_kolor-RGB.png" alt="logo"/>-->
            </router-link>
        </div>
        <div class="register-panel-content">
            <div v-if="registrationSuccessful">
                <h2>Dziękujemy za rejestrację</h2>
                <br/>
                <h2>Na podany podczas rejestracji adres email został wysłany link aktywacyjny</h2>
            </div>
            <Form v-else @submit="register">
                <div class="register-panel-column">
                    <div class="p-col-12">
                        <CustomInputText name="name" v-model="registerForm.name" icon="pi pi-user"
                                         :label="$t('message.register.name')" width="400"
                                         rules="required|firstNameLength"/>
                        <CustomInputText name="surname" v-model="registerForm.surname" icon="pi pi-user"
                                         :label="$t('message.register.surname')" width="400"
                                         rules="required|lastNameLength"/>
                        <CustomInputText name="email" v-model="registerForm.email" icon="pi pi-envelope"
                                         :label="$t('message.register.email')" width="400"
                                         rules="required|email|emailLength"/>
                        <CustomInputText name="email2" v-model="registerForm.email2" icon="pi pi-envelope"
                                         :label="$t('message.register.email2')" width="400"
                                         rules="required|confirmed:@email,Adresy email"/>
                        <CustomInputMask name="phone" v-model="registerForm.phone" icon="pi pi-phone"
                                         :label="$t('message.register.phone')"
                                         width="400" mask="(+48) 999 999 999" :no-icon="false"/>
                        <CustomPassword name="password" v-model="registerForm.password"
                                        :label="$t('message.register.password')" width="400"
                                        rules="required|validPassword" :feedback="true"/>
                        <CustomPassword name="password2" v-model="registerForm.password2"
                                        :label="$t('message.register.password2')" width="400"
                                        rules="required|confirmed:@password,Hasła"/>
                    </div>
                </div>
                <div class="register-panel-column">
                    <div class="p-col-12 register-role-switch">
                        {{ $t('message.register.user') }}
                        <InputSwitch v-model="registerForm.organizer" />
                        {{ $t('message.register.organizer') }}
                    </div>
                    <div v-if="registerForm.organizer">
                        <CustomInputText name="organizationName" v-model="registerForm.organizationName"
                                         icon="pi pi-briefcase" :label="$t('message.register.organization')"
                                         width="400" :rules="organizationNameRequired"/>
                    </div>
                    <div v-else>
                        <div class="user-form-specify-item">
                            <span class="register-label">
                                Wybierz co najmniej 1 dzielnicę, w której szukasz aktywności
                            </span>
                        </div>
                        <div class="user-form-specify-item">
                            <DesignedMultiSelectDistrict name="districts" v-model="registerForm.districts"
                                                         width="450" rules="required"/>
                        </div>
                        <div  v-show="showDistrictValidationMessage" class="user-form-specify-item">
                            <span id="district-validate-message" class="p-invalid">
                                Należy wybrać co najmniej jedną dzielnicę
                            </span>
                        </div>
                        <div class="user-form-specify-item">
                            <div class="user-form-specify-item">
                                <span class="register-label">
                                    Wybierz co najmniej 3 typy aktywności, które Cię interesują
                                </span>
                            </div>
                            <div class="p-col-12 register-button">
                                <Button :label="$t('message.register.action.chooseEvents')"
                                        class="button button-filled" :disabled="registering"
                                        @click="openChooseEventsDialog"/>
                            </div>
                            <div v-if="!registering">
                                <span v-show="showActivitiesValidationMessage"
                                      id="activity-validate-message" class="p-invalid">
                                    Należy wybrać co najmniej trzy aktywności
                                </span>
                            </div>
                            <Dialog :header="$t('message.register.action.chooseEventsLabel')"
                                    v-model:visible="displayChooseEventsDialog">
                                <div class="activities">
                                    <CustomCheckbox v-for="activity in registerForm.activities"
                                                    :name="'activity' + activity.id"
                                                    v-bind:key="activity.id" :label="activity.name"
                                                    v-model="activity.interested" class="checkbox"/>
                                </div>
                                <template #footer>
                                    <Button label="Zatwierdź" class="button button-filled" icon="pi pi-check"
                                            @click="closeChooseEventsDialog"/>
                                </template>
                            </Dialog>
                        </div>
                    </div>
                    <div>
                        <CustomCheckbox
                            name="termsAccepted"
                            v-model="registerForm.termsAccepted" class="checkbox"
                            rules="required" no-star>
                            <span>
                                Akceptuję <a href="//uactiv.pl/regulamin/" target="_blank">Regulamin</a>
                                oraz <a href="//uactiv.pl/polityka-prywatnosci/" target="_blank">
                                    Politykę prywatności</a>
                            </span>
                        </CustomCheckbox>
                    </div>
                    <br/>
                    <div>
                        <span class="register-label">{{ $t('message.register.action.loginLabel') }}</span>
                        <router-link :to="{name: 'login'}">
                            {{ $t('message.register.action.login') }}
                        </router-link>
                        <div class="p-col-12 register-button">
                            <Button v-if="!registerForm.organizer" :label="$t('message.register.action.register')"
                                    type="submit" class="button button-filled" :disabled="registering"
                                    @click="checkDistrictsAndActivities"/>
                            <Button v-else :label="$t('message.register.action.register')"
                                    type="submit" class="button button-filled" :disabled="registering"/>
                        </div>
                    </div>

                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import {Form} from "vee-validate";
    import Button from "primevue/button";
    import Dialog from "primevue/dialog";
    import InputSwitch from "primevue/inputswitch";
    import CustomPassword from "../components/form/CustomPassword";
    import CustomInputText from "../components/form/CustomInputText";
    import {
        emailExistUsingGET as emailExist,
        getAllVisibleEventsUsingGET as getAllVisibleEventTypes,
        organizationExistsByNameUsingGET as organizationExists,
        registerUserUsingPOST as registerUser,
    } from "@/swagger/vue-api-client";
    import CustomCheckbox from "@/components/form/CustomCheckbox";
    import DesignedMultiSelectDistrict from "@/components/form/multiselect/DesignedMultiSelectDistrict";
    import CustomInputMask from "@/components/form/CustomInputMask";

    export default {
        name: "RegisterView",
        components: {
            DesignedMultiSelectDistrict,
            CustomCheckbox,
            Form,
            CustomInputText,
            CustomPassword,
            Button,
            Dialog,
            InputSwitch,
            CustomInputMask,
        },

        data() {
            return {
                registerForm: {
                    name: "",
                    surname: "",
                    email: "",
                    email2: "",
                    phone: "",
                    password: "",
                    password2: "",
                    organizer: false,
                    districts: [],
                    organizationName: "",
                    activities: [],
                    termsAccepted: false,
                },
                displayChooseEventsDialog: false,
                registrationSuccessful: false,
                activityList: [],
                showActivitiesValidationMessage: false,
                showDistrictValidationMessage: false,
                registering: false,
            };
        },

        mounted() {
            this.getActivityList();
            this.$watch("registerForm.districts", this.showDistrictsErrorMessage, {deep: true});
        },

        methods: {
            async getActivityList() {
                await getAllVisibleEventTypes().then((response) => {
                    this.registerForm.activities = response.data;
                    this.activityList = response.data;
                    this.$watch("registerForm.activities", this.showActivitiesErrorMessage, {deep: true});
                }).catch(() => {
                    this.showGetActivityListErrorToast();
                });
            },

            async register(value, actions) {
                this.registering = true;
                let valid = true;

                await emailExist({email: this.registerForm.email})
                    .then((response) => {
                        if (response.data) {
                            actions.setFieldError("email", "Istnieje już użytkownik o takim adresie email");
                            valid = false;
                        }
                    }).catch(() => {
                        this.showErrorToast();
                        valid = false;
                    });

                if (!this.registerForm.organizer) {
                    if (!this.activitiesValid(this.registerForm.activities)) {
                        this.showActivitiesErrorMessage(this.registerForm.activities);
                        valid = false;
                    }
                    if (!this.districtsValid(this.registerForm.districts)) {
                        this.showDistrictsErrorMessage(this.registerForm.districts);
                        valid = false;
                    }
                }

                if (this.registerForm.organizer) {
                    await organizationExists({name: this.registerForm.organizationName})
                        .then((response) => {
                            if (response.data) {
                                actions.setFieldError("organizationName", "Istnieje już organizacja o takiej nazwie");
                                valid = false;
                            }
                        }).catch(() => {
                            this.showErrorToast();
                            valid = false;
                        });
                }

                if (!valid) {
                    this.registering = false;
                    return;
                }

                // Wysyłamy na backend wybrane aktywności
                // Backupujemy wszystkie, żeby w przypadku błędu lista wciąż działała
                this.registerForm.activities = this.registerForm.activities
                    .filter((activity) => activity.interested === true)
                    .map((activity) => activity.id);

                grecaptcha.ready(() => {
                    grecaptcha.execute(process.env.VUE_APP_CAPTCHA_KEY, {action: "submit"}).then((token) => {
                        this.setToken(token);
                        this.sendRegistrationRequestAndHandleResponse();
                    });
                });
            },

            setToken(token) {
                this.registerForm.captchaToken = token;
            },

            sendRegistrationRequestAndHandleResponse() {
                registerUser({registrationData: this.registerForm})
                    .then(() => {
                        this.registrationSuccessful = true;
                    }).catch((error) => {
                        this.registerForm.activities = this.activityList;
                        this.registering = false;
                        if (error.response.status === 400) {
                            this.showValidationErrorToast();
                        } else {
                            this.showErrorToast();
                        }
                    });
            },

            openChooseEventsDialog() {
                this.displayChooseEventsDialog = true;
            },
            closeChooseEventsDialog() {
                this.showActivitiesErrorMessage(this.registerForm.activities);
                this.displayChooseEventsDialog = false;
            },

            showErrorToast() {
                this.$toast.add({
                    severity: "error",
                    summary: "Wystąpił nieoczekiwany błąd",
                    detail: "Prosimy o kontakt z administratorem",
                    life: 3000,
                });
            },

            showValidationErrorToast() {
                this.$toast.add({
                    severity: "warn",
                    summary: "Wystąpił błąd związany z walidacją",
                    detail: "Proszę sprawdzić poprawność wprowadzonych danych",
                    life: 3000,
                });
            },

            showGetActivityListErrorToast() {
                this.$toast.add({
                    severity: "info",
                    summary: "Nie można pobrać listy aktywności",
                    detail: "Prosimy o kontakt z administratorem",
                    life: 3000,
                });
            },

            activitiesValid(activities) {
                return activities.filter((activity) => activity.interested === true).length >= 3;
            },
            districtsValid(districts) {
                return districts.length >= 1;
            },

            showActivitiesErrorMessage(activities) {
                this.showActivitiesValidationMessage = !this.activitiesValid(activities);
            },
            showDistrictsErrorMessage(districts) {
                this.showDistrictValidationMessage = !this.districtsValid(districts);
            },
            checkDistrictsAndActivities() {
                this.showActivitiesErrorMessage(this.registerForm.activities);
                this.showDistrictsErrorMessage(this.registerForm.districts);
            },
        },

        computed: {
            organizationNameRequired() {
                return this.registerForm.organizer ? "required|organizationNameLength" : "";
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "assets/less/register.less";
    .activities {
        width: 100%;
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .checkbox {
            display: flex;
            margin-bottom: 20px;
        }
    }
    .user-form-specify-item {
        margin-bottom: 25px;
    }
</style>
