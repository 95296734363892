<template>
    <div class="p-fluid custom-input-textarea">
        <span class="p-float-label">
            <Textarea :id="name" :rows="rows" cols="30" v-model="internalValue" :aria-label="name"
                      :class="computedClasses" @input="handleChange" @blur="handleBlur"
                      :placeholder="internalValue ? '' : label" :autoResize="autoResize"/>
        </span>
        <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
    </div>
</template>

<script>
    import Textarea from "primevue/textarea";
    import {useField} from "vee-validate";

    export default {
        name: "CustomInputTextarea",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            width: {
                type: String,
            },
            rows: {
                type: Number,
                default: 5,
            },
            rules: undefined,
            modelValue: null,
            autoResize: false,
        },
        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },

            computedClasses() {
                return this.errorMessage ? "p-invalid" : "";
            },
        },

        methods: {
            inputListener(event) {
                this.$emit("update:modelValue", event.target.value);
            },
        },

        watch: {
            modelValue() {
                this.inputValue = this.modelValue;
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

        components: {Textarea},
    };
</script>

<style lang="less" scoped>
    @import "../../assets/theme/variable";

    .custom-input-textarea {
        width: 100%;

        .p-inputtext {
            background-color: @color-white;
            border: 1px solid rgb(221, 221, 221);
            border-radius: 30px;
            padding: 1rem 1.5rem;
            box-shadow: rgba(0, 0, 0, 8%) 0px 1px 2px, rgba(0, 0, 0, 5%) 0px 4px 12px;
            &:focus {
                 box-shadow: none;
            }
        }

        .p-float-label input:focus ~ label,
        .p-float-label input.p-filled ~ label {
            background: unset;
            color: @color-gray3;
            margin-bottom: 35px;
        }

        .p-float-label > label {
            margin-bottom: 14px;
        }

        .pi {
            font-size: 20px;
        }

        margin-bottom: 20px;
    }
</style>
