<template>
    <div class="user-table">
        <DataTable ref="userTable" :value="users" :lazy="true" :paginator="true" v-model:rows="searchParams.page.limit"
                   v-model:first="searchParams.page.offset"
                   removableSort :totalRecords="totalRecords" :loading="loading" @page="onPage"
                   @sort="onPage" :rowsPerPageOptions="[5,10,20,50]">
            <Column field="firstName" header="Imię" :sortable="true"></Column>
            <Column field="lastName" header="Nazwisko" :sortable="true"></Column>
            <Column field="email" header="Email" :sortable="true"></Column>
            <Column field="phoneNumber" header="Telefon" :sortable="true"></Column>
            <Column field="favoriteActivities" header="Zainteresowania"
                    style="display: flex; justify-content: center; align-items: center">
                <template #body="{data, index}">
                    {{data.favoriteActivities ? data.favoriteActivities.length : 0}}
                    <Button icon="pi pi-eye" v-tooltip.top="'Zobacz zainteresowania'"
                            @click="openUserFavoriteActivitiesDialog(index)" aria-label="Zobacz zainteresowania"
                            class="p-button-rounded p-button-text p-button-lg add-user-button"/>
                </template>
            </Column>
            <Dialog :header="'Aktywności, którymi jest zainteresowany użytkownik ' + getSelectedUserName()"
                    v-model:visible="displayUserEventsDialog" :draggable="false">
                <div class="selected-user-activities">
                    <div v-for="activity in users[selectedUser].favoriteActivities" v-bind:key="activity.id"
                         class="selected-user-activity">{{activity.name}}</div>
                </div>
                <template #footer>
                    <Button label="Zamknij" icon="pi pi-close" class="button button-filled"
                            @click="closeUserFavoriteActivitiesDialog"/>
                </template>
            </Dialog>
            <Column field="emailVerified" header="Status" :sortable="true">
                <template #body="slotProps">
                    {{getStatus(slotProps.data)}}
                </template>
            </Column>
            <Column field="userDistricts" header="Dzielnica"
                    style="display: flex; justify-content: center; align-items: center">
                <template #body="{data, index}">
                    {{data.userDistricts ? data.userDistricts.length : 0}}
                    <Button icon="pi pi-eye" v-tooltip.top="'Zobacz dzielnice'"
                            @click="openUserDistrictsDialog(index)" aria-label="Zobacz dzielnice"
                            class="p-button-rounded p-button-text p-button-lg add-user-button"/>
                </template>
            </Column>
            <Dialog :header="'Dzielnice, w których aktywności szuka użytkownik ' + getSelectedUserName()"
                    v-model:visible="displayUserDistrictsDialog" :draggable="false">
                <div class="selected-user-districts">
                    <div v-for="district in users[selectedUser].userDistricts" v-bind:key="district.id"
                         class="selected-user-district">{{district}}</div>
                </div>
                <template #footer>
                    <Button label="Zamknij" icon="pi pi-close" class="button button-filled"
                            @click="closeUserDistrictsDialog"/>
                </template>
            </Dialog>
            <Column header="Edycja">
                <template #body="slotProps" v-if="Role.isAdmin()">
                    <router-link :to="{name: 'userEdit', params: {'id': slotProps.data.id}}">
                        <Button icon="pi pi-pencil" v-tooltip.top="'Edytuj'" aria-label="Edytuj"
                                class="p-button-rounded p-button-text p-button-lg add-user-button"/>
                    </router-link>
                    <span/>
                </template>
            </Column>
            <template #empty>
                Nie znaleziono użytkowników.
            </template>
        </DataTable>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Tooltip from "primevue/tooltip";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Dialog from "primevue/dialog";
    import {
        searchUserCountUsingPOST as searchUserCount,
        searchUserUsingPOST as searchUser,
    } from "@/swagger/vue-api-client";
    import {SystemRole} from "@/utils/SystemRole";
    import {SearchCriteria} from "../../../utils/SearchCriteria";

    export default {
        name: "UserTable",
        components: {
            Button, Column, DataTable, Dialog,
        },

        directives: {
            tooltip: Tooltip,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        emits: ["update:searchCriteria"],

        data() {
            return {
                loading: false,
                totalRecords: 0,
                users: [],
                displayUserEventsDialog: false,
                displayUserDistrictsDialog: false,
                selectedUser: null,
                Role: SystemRole,
            };
        },

        computed: {
            searchParams: {
                get() {
                    return this.searchCriteria;
                },
                set(value) {
                    this.$emit("update:searchCriteria", value);
                },
            },

            status() {
                if (this.data.emailVerified) {
                    return "aktywny";
                }
                return "nowy";
            },
        },

        mounted() {
            this.updateTotalRecords();
            this.onPage(this.getFirstPage());
        },

        methods: {
            search() {
                this.selectedUser = null;
                this.onPage(this.getFirstPage());
                this.updateTotalRecords();
            },

            onPage(event) {
                this.loading = true;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = event.sortField;
                this.searchParams.page.sortOrder = event.sortOrder;

                searchUser({searchCriteria: this.searchCriteria}).then((response) => {
                    this.users = response.data;
                    this.loading = false;
                });
                SearchCriteria.updateUrl(this);
            },

            updateTotalRecords() {
                searchUserCount({searchCriteria: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                });
            },

            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                };
            },
            openUserFavoriteActivitiesDialog(index) {
                this.displayUserEventsDialog = true;
                this.selectedUser = index;
            },
            closeUserFavoriteActivitiesDialog() {
                this.displayUserEventsDialog = false;
                this.selectedUser = null;
            },
            openUserDistrictsDialog(index) {
                this.displayUserDistrictsDialog = true;
                this.selectedUser = index;
            },
            closeUserDistrictsDialog() {
                this.displayUserDistrictsDialog = false;
                this.selectedUser = null;
            },
            getSelectedUserName() {
                if (!this.selectedUser) {
                    return "";
                }
                return this.users[this.selectedUser].firstName + " " + this.users[this.selectedUser].lastName;
            },

            getStatus(data) {
                if (data.emailVerified) {
                    return "aktywny";
                }
                return "nowy";
            },
        },

    };
</script>

<style lang="less" scoped>
    @import "../../../assets/theme/variable";
    .user-table {
        margin-bottom: 40px
    }
    .events {
        i {
            font-size: 25px;
        }

        display: flex;
        justify-content: space-evenly;
    }

    .selected-user-activities {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 15px;
        grid-column-gap: 15px;

        .selected-user-activity {
            background-color: @secondary-color;
            padding: 20px;

        }
    }

    .selected-user-districts {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 15px;
        grid-column-gap: 15px;

        .selected-user-district {
            background-color: @secondary-color;
            padding: 20px;

        }
    }
</style>
