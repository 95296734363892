<template>
    <div class="form-view admin-form-view">
        <Form @submit="saveUser">
            <div class="admin-form-view-header">
                <div class="admin-form-header-text">
                    <h1>{{ this.title }}</h1>
                    <SaveButton type="submit" class="button button-filled-white" :disabled="registering"/>
                </div>
            </div>
            <div class="admin-form-view-content general-container">
                <div class="data">
                    <div class="row">
                        <div class="label">Imię</div>
                        <CustomInputText name="firstName"
                                         v-model="user.firstName"
                                         no-icon
                                         label="imię"
                                         width="500"
                                         rules="required|firstNameLength"/>
                    </div>
                    <div class="row">
                        <div class="label">Nazwisko</div>
                        <CustomInputText name="lastName"
                                         v-model="user.lastName"
                                         no-icon
                                         label="nazwisko"
                                         width="500"
                                         rules="required|lastNameLength"/>
                    </div>
                    <div class="row">
                        <div class="label">Email</div>
                        <CustomInputText name="email"
                                         v-model="user.email"
                                         no-icon label="adres email"
                                         width="500"
                                         rules="required|emailLength"
                                         disabled/>
                    </div>
                    <div>
                        <div class="row">
                            <div class="label">Zmiana hasła</div>
                            <CustomPassword name="password" v-model="changedPassword"
                                            :label="$t('message.register.password')" width="400"
                                            :rules="computedPasswordRules" :feedback="true"/>
                        </div>
                        <div class="row">
                            <div class="label">Powtórz hasło</div>
                            <CustomPassword name="password2" v-model="changedRepeatedPassword"
                                            :label="$t('message.register.password2')" width="400"
                                            :rules="computedRepeatPasswordRules"/>
                        </div>
                    </div>
                </div>
                <div class="admin-form-view-buttons">
                    <SaveButton type="submit" :disabled="registering"/>
                    <DeleteAccountButton @click="deleteAdmin(user.id, $event)" on-bottom :disabled="registering"/>
                </div>
            </div>
        </Form>
    </div>
</template>

<script>
    import {Form} from "vee-validate";
    import {
        createOrUpdateUserUsingPOST as createOrUpdateUser,
        deleteUserUsingDELETE as deleteUser,
        emailExistUsingGET as emailExist,
        getUserUsingGET as getUser,
    } from "@/swagger/vue-api-client";
    import CustomInputText from "../../components/form/CustomInputText";
    import {SystemRole} from "../../utils/SystemRole";
    import SaveButton from "@/components/form/SaveButton";
    import DeleteAccountButton from "@/components/form/DeleteButton";
    import CustomPassword from "@/components/form/CustomPassword";

    export default {
        name: "AdminFormView",

        components: {
            SaveButton,
            CustomInputText,
            DeleteAccountButton,
            CustomPassword,
            Form,
        },

        props: {
            value: {},
        },

        data() {
            return {
                user: {
                    id: null,
                    firstName: null,
                    lastName: null,
                    email: null,
                    password: null,
                    emailVerified: false,
                    systemRole: SystemRole.ADMIN,
                },
                changedPassword: null,
                changedRepeatedPassword: null,
                loaded: false,
                edit: true,
                registering: false,
                title: "Edycja konta administratora",
            };
        },

        beforeMount() {
            if (this.$route.path.endsWith("create")) {
                this.title = "Tworzenie konta administratora";
                this.edit = false;
            }
            if (this.$route.params.id) {
                getUser({id: this.$route.params.id}).then((response) => {
                    this.user.id = response.data.id;
                    this.user.firstName = response.data.firstName;
                    this.user.lastName = response.data.lastName;
                    this.user.email = response.data.email;
                    this.user.password = response.data.password;
                    this.user.emailVerified = response.data.emailVerified;
                    this.user.systemRole = response.data.systemRole;
                    this.loaded = "true";
                });
            } else {
                this.loaded = "true";
            }
        },

        computed: {
            computedPasswordRules() {
                return (this.changedPassword != null) && (this.changedPassword !== "")
                    ? "required|validOptionalPassword" : "validOptionalPassword";
            },
            computedRepeatPasswordRules() {
                return (this.changedPassword != null) && (this.changedPassword !== "")
                    ? "required|confirmed:@password,Hasła" : "confirmed:@password,Hasła";
            },
        },

        methods: {
            async saveUser(value, actions) {
                let valid = true;
                this.registering = true;

                if (!this.edit) {
                    await emailExist({email: this.organizer.email})
                        .then((response) => {
                            if (response.data) {
                                actions.setFieldError("email", "Istnieje już użytkownik o takim adresie email");
                                valid = false;
                            }
                        }).catch(() => {
                            this.$toast.add({
                                severity: "error",
                                summary: "Błąd",
                                detail: "Wystąpił nieoczekiwany błąd",
                                life: 3000,
                            });
                            valid = false;
                        });

                    if (!valid) {
                        this.registering = false;
                        return;
                    }
                }

                if (this.changedPassword != null && this.changedPassword !== "") {
                    this.user.password = this.changedPassword;
                }

                createOrUpdateUser({userDto: this.user}).then(() => {
                    this.registering = false;
                    this.$toast.add({
                        severity: "success",
                        summary: "Sukces",
                        detail: "Zapisano dane użytkownika",
                        life: 3000,
                    }).catch(() => {
                        this.registering = false;
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Nie masz wystarczających uprawnień",
                            life: 3000,
                        });
                    });
                });
            },

            deleteAdmin(id, event) {
                this.$confirm.require({
                    target: event.currentTarget,
                    header: "Czy na pewno usunąć konto?",
                    message: "Tej operacji nie będzie można cofnąć.",
                    acceptLabel: "Tak",
                    rejectLabel: "Nie",
                    position: "topright",
                    accept: () => {
                        deleteUser({id})
                            .then(() => {
                                this.$toast.add({
                                    severity: "success",
                                    summary: "Sukces",
                                    detail: "Pomyślnie usunięto konto",
                                    life: 3000,
                                });
                                this.$router.push("/");
                            })
                            .catch((error) => {
                                if (error.response.status === 403) {
                                    this.$toast.add({
                                        severity: "error",
                                        summary: "Błąd",
                                        detail: "Nie masz wystarczających uprawnień",
                                        life: 3000,
                                    });
                                } else {
                                    this.$toast.add({
                                        severity: "error",
                                        summary: "Błąd",
                                        detail: "Wystąpił nieoczekiwany błąd",
                                        life: 3000,
                                    });
                                }
                            });
                    },
                });
            },
        },
    };
</script>

<style lang="less" scoped>
@import "src/views/assets/less/form-view";
</style>
