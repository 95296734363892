<template>
    <div class="acc-menu-outer">
        <div class="show-acc acc-icon" v-if="showAccIcon" @click="showAcccMenu"></div>
        <div class="show-acc close-icon" v-if="closeAccIcon" @click="closeAcccMenu"></div>
        <div class="acc-menu" v-if="accMenuVisible">
            <div class="acc-font-size">
                <div class="smaller clickable" tabindex="0" @keyup.enter="decreaseFontSize" @click="decreaseFontSize">
                    <span class="visually-hidden">Zmniejsz rozmiar czcionki</span>
                    <span class="arrow-down">
                    </span>
                </div>
                <span class="acc-font-aa">Aa</span>
                <div class="greater clickable" tabindex="0" @keyup.enter="increaseFontSize" @click="increaseFontSize">
                    <span class="visually-hidden">Zwiększ rozmiar czcionki</span>
                    <span class="arrow-up"></span>
                </div>
            </div>
            <div class="acc-contrast-high-black clickable" tabindex="0" @click="setBlackContrastForAllApp()"
                 @keyup.enter="setBlackContrastForAllApp()" >
                <span class="visually-hidden">Wysoki kontrast - czarne tło</span>
            </div>
            <div class="acc-contrast-high-yellow clickable" tabindex="0" @click="setYellowContrastForAllApp()"
                 @keyup.enter="setYellowContrastForAllApp()">
                <span class="visually-hidden">Wysoki kontrast - żółte tło</span>
            </div>
            <div class="acc-reset clickable" tabindex="0" @click="setNormalModeAnfFontSizeForAllApp()"
                 @keyup.enter="setNormalModeAnfFontSizeForAllApp()">
                <span class="visually-hidden">Resetuj ustawienia wielkości czcionki i kontrastu</span>
            </div>
        </div>
    </div>
</template>

<script>
    import {ThemeSettingsHelper} from "@/mixin/themeSettings";

    export default {
        name: "AccMenu",

        data() {
            return {
                showAccIcon: false,
                closeAccIcon: false,
                accMenuVisible: true,
            };
        },
        beforeMount() {
            this.responsiveAccMenu();
            window.addEventListener("resize", () => {
                this.responsiveAccMenu();
            });
        },
        methods: {
            setBlackContrastForAllApp() {
                ThemeSettingsHelper.setBlackContrastForAllApp();
            },
            setYellowContrastForAllApp() {
                ThemeSettingsHelper.setYellowContrastForAllApp();
            },
            setNormalModeAnfFontSizeForAllApp() {
                ThemeSettingsHelper.setNormalModeAnfFontSizeForAllApp();
            },

            showAcccMenu() {
                this.accMenuVisible = !this.accMenuVisible;
                this.closeAccIcon = true;
                this.showAccIcon = false;
            },
            closeAcccMenu() {
                this.accMenuVisible = !this.accMenuVisible;
                this.closeAccIcon = false;
                this.showAccIcon = true;
            },
            responsiveAccMenu() {
                const windowWidth = window.innerWidth;
                if (windowWidth <= 645) {
                    this.showAccIcon = true;
                    this.accMenuVisible = false;
                } else {
                    this.showAccIcon = false;
                    this.accMenuVisible = true;
                }
            },
            increaseFontSize() {
                ThemeSettingsHelper.increaseFontSize();
            },
            decreaseFontSize() {
                ThemeSettingsHelper.decreaseFontSize();
            },
            showAccIcons(show) {
                this.showAcc = show;
            },
            toggleDisplayAccIcons(event) {
                this.showAcc = !this.showAcc;
                this.closeAccIcons();
                if (this.showAcc && event.keyCode === 13) {
                    this.$nextTick(() => {
                        document.getElementsByClassName("smaller")[0].focus();
                    });
                }
            },
            closeAccIcons() {
                if (this.showAcc) {
                    document.addEventListener("click", () => {
                        this.showAccIcons(false);
                    });
                    document.getElementsByClassName("acc-menu")[0].addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                }
            },
        },
    };
</script>

<style lang="less">
    @import "@/components/assets/less/acc-menu.less";
</style>
