<template>
    <div>
        <GoogleMap
            ref="googleMap"
            :api-key="MapUtils.getGmapsApiKey()"
            style="width: 100%; height: 500px"
            :center="center"
            :zoom="10"
            @click="onMapClick"
        >
            <Marker ref="'marker'" :options="MapUtils.getMarkerOptions(event)"/>
        </GoogleMap>
    </div>
</template>

<script>
    import {GoogleMap, Marker} from "vue3-google-map";
    import {MapUtils} from "@/utils/MapUtils";

    export default {
        name: "EventInputPlace",
        components: {
            GoogleMap, Marker,
        },

        props: {
            event: {
                type: Object,
            },
        },

        emits: ["update:event"],

        data() {
            return {
                MapUtils,
                center: {
                    lat: 54.35,
                    lng: 18.64,
                },
            };
        },

        methods: {
            onMapClick(e) {
                const newEvent = this.event;
                newEvent.latitude = e.latLng.lat();
                newEvent.longitude = e.latLng.lng();
                this.$emit("update:event", newEvent);
            },
        },

    };
</script>

<style lang="less">
    @import "../../../assets/theme/variable";
</style>
