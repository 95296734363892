import {createRouter, createWebHistory} from "vue-router";
import LoginView from "./views/LoginView";
import UserListView from "./views/user/UserListView";
import UserFormView from "./views/user/UserFormView";
import {SystemRole} from "./utils/SystemRole";
import LayoutSimple from "./views/layout/LayoutSimple";
import ForbiddenView from "./views/ForbiddenView";
import NotFoundView from "./views/NotFoundView";
import ErrorView from "./views/ErrorView";
import PasswordRemindView from "./views/PasswordRemindView";
import ConfirmEmailView from "@/views/ConfirmEmailView";
import ChangePasswordView from "./views/ChangePasswordView";
import LayoutPublic from "./views/layout/LayoutPublic";
import EventView from "@/views/event/EventView";
import SearchView from "@/views/public/SearchView";
import OrganizationView from "@/views/organization/OrganizationView";
import RegisterView from "@/views/RegisterView";
import OrganizerAndOrganizationListView from "@/views/organizer/OrganizerAndOrganizationListView";
import SystemSettingsView from "@/views/systemSettings/SystemSettingsView";
import OrganizationFormView from "@/views/organization/OrganizationFormView";
import OrganizerFormView from "@/views/organizer/OrganizerFormView";
import EventFormView from "@/views/event/EventFormView";
import AdminFormView from "@/views/admin/AdminFormView";
import ImportedEventAcceptanceListView from "@/views/admin/ImportedEventAcceptanceListView";
import EventEditionListView from "@/views/admin/EventEditionListView";
import TermsOfUse from "@/views/TermsOfUse";
import PrivacyPolicy from "@/views/PrivacyPolicy";

const routes = [
        {
            component: LayoutPublic,
            path: "/",
            children: [
                {
                    path: "/",
                    name: "search",
                    component: SearchView,
                },
                {
                    path: "/event/:id",
                    name: "event",
                    component: EventView,
                },
                {
                    path: "/event/create",
                    name: "eventCreate",
                    component: EventFormView,
                    props: {create: true},
                    meta: {requiresAuth: true, roles: [SystemRole.ADMIN, SystemRole.ORGANIZER]},
                },
                {
                    path: "/event/edit/:id",
                    name: "eventEdit",
                    component: EventFormView,
                    meta: {requiresAuth: true, roles: [SystemRole.ADMIN, SystemRole.ORGANIZER]},
                },
                {
                    path: "/event/acceptance",
                    name: "importedEventAcceptanceListView",
                    component: ImportedEventAcceptanceListView,
                    meta: {requiresAuth: true, roles: [SystemRole.ADMIN]},
                },
                {
                    path: "/event/edition",
                    name: "eventEditionListView",
                    component: EventEditionListView,
                    meta: {requiresAuth: true, roles: [SystemRole.ADMIN]},
                },
                {
                    path: "/organization/:id",
                    name: "organization",
                    component: OrganizationView,
                },
                {
                    path: "/organization/create",
                    name: "organizationCreate",
                    component: OrganizationFormView,
                    props: {create: true},
                    meta: {requiresAuth: true, roles: [SystemRole.ADMIN]},
                },
                {
                    path: "/organization/edit/:id",
                    name: "organizationEdit",
                    component: OrganizationFormView,
                    props: {create: false},
                    meta: {requiresAuth: true, roles: [SystemRole.ADMIN, SystemRole.ORGANIZER]},
                },
                {
                    path: "/user/list",
                    name: "userList",
                    component: UserListView,
                    meta: {requiresAuth: true, roles: [SystemRole.ADMIN]},

                },
                {
                    path: "/user/create",
                    name: "userCreate",
                    component: UserFormView,
                    props: {create: true},
                    meta: {requiresAuth: true, roles: [SystemRole.ADMIN]},
                },
                {
                    path: "/user/edit/:id",
                    name: "userEdit",
                    component: UserFormView,
                    props: {create: false},
                    meta: {requiresAuth: true, roles: [SystemRole.ADMIN, SystemRole.USER]},
                },
                {
                    path: "/admin/edit/:id",
                    name: "adminEdit",
                    component: AdminFormView,
                    meta: {requiresAuth: true, roles: [SystemRole.ADMIN]},
                },
                {
                    path: "/organizer/list",
                    name: "organizerList",
                    component: OrganizerAndOrganizationListView,
                    meta: {requiresAuth: true, roles: [SystemRole.ADMIN]},
                },
                {
                    path: "/organizer/create",
                    name: "organizerCreate",
                    component: OrganizerFormView,
                    props: {create: true},
                    meta: {requiresAuth: true, roles: [SystemRole.ADMIN]},
                },
                {
                    path: "/organizer/edit/:id",
                    name: "organizerEdit",
                    component: OrganizerFormView,
                    props: {create: false},
                    meta: {requiresAuth: true, roles: [SystemRole.ADMIN, SystemRole.ORGANIZER]},
                },
                {
                    path: "/system/settings",
                    name: "systemSettings",
                    component: SystemSettingsView,
                    meta: {requiresAuth: true, roles: [SystemRole.ADMIN]},
                },
                {
                    path: "/privacy-policy",
                    name: "privacyPolicy",
                    component: PrivacyPolicy,
                    meta: {requiresAuth: false},
                },
                {
                    path: "/terms-of-use",
                    name: "termsOfUse",
                    component: TermsOfUse,
                    meta: {requiresAuth: false},
                },
            ],
        },
        {
            name: "layoutSimple",
            component: LayoutSimple,
            path: "/",
            children: [
                {
                    path: "/login",
                    name: "login",
                    component: LoginView,
                },
                {
                    path: "/register",
                    name: "register",
                    component: RegisterView,
                },
                {
                    path: "/confirm-email",
                    name: "confirmEmail",
                    component: ConfirmEmailView,
                },
                {
                    path: "/remind",
                    name: "remindPassword",
                    component: PasswordRemindView,
                },
                {
                    path: "/change-password",
                    name: "changePassword",
                    component: ChangePasswordView,
                },
                {
                    path: "/forbidden",
                    name: "forbidden",
                    component: ForbiddenView,
                },
                {
                    path: "/error",
                    name: "error",
                    component: ErrorView,
                },
                {
                    path: "/:pathMatch(.*)*",
                    name: "notFound",
                    component: NotFoundView,
                },
            ],
        },
    ];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
