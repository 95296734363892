<template>
    <div class="system-settings-view">
        <div class="system-settings-view-header">
            <div class="system-settings-view-header-text">
                <CustomTitle title="Konfiguracja"/>
            </div>
        </div>
        <Form @submit="updateSystemParameter" v-slot="{ isSubmitting }" class="general-container">
            <Toast />

            <Accordion :multiple="true" :activeIndex="activeIndex">
                <AccordionTab>
                    <template #header>
                        <i class="pi pi-image"></i>
                        <div class="tab-header">Instagram</div>
                    </template>
                    <div class="row">
                        <div class="label">Instagram Name</div>
                        <CustomInputText name="instagramName" label="Instagram Name" no-icon width="500"
                                         v-model="systemSettings.instagramName" rules="required"/>
                    </div>
                    <div class="row">
                        <div class="label">Instagram Access Token</div>
                        <CustomInputText name="instagramAccessToken" label="Instagram Access Token" no-icon
                                         width="500" v-model="systemSettings.instagramAccessToken"
                                         rules="required"/>
                    </div>
                    <div class="row">
                        <div class="label">Instagram Client Id</div>
                        <CustomInputText name="instagramClientId" label="Instagram Client Id" no-icon
                                         width="500" v-model="systemSettings.instagramClientId"
                                         rules="required"/>
                    </div>
                    <div class="row">
                        <div class="label">Instagram Client Secret</div>
                        <CustomInputText name="instagramClientSecret" label="Instagram Client Secret"
                                         no-icon width="500" v-model="systemSettings.instagramClientSecret"
                                         rules="required"/>
                    </div>
                </AccordionTab>
                <AccordionTab>
                    <template #header>
                        <i class="pi pi-google"></i>
                        <div class="tab-header">Google</div>
                    </template>
                    <div class="row">
                        <div class="label">Google Analytics Id</div>
                        <CustomInputText name="googleAnalyticsId" label="Google Analytics Id" no-icon width="500"
                                         v-model="systemSettings.googleAnalyticsId"
                                         rules="required|googleAnalyticsId|max:12"/>
                    </div>
                    <div class="row">
                        <div class="label">Google Api Key</div>
                        <CustomInputText name="googleApiKey" label="Google Api Key" no-icon width="500"
                                         v-model="systemSettings.googleApiKey" rules="required"/>
                    </div>
                    <div class="row">
                        <div class="label">Google Tag Manager Id</div>
                        <CustomInputText name="googleTagManagerId" label="Google Tag Manager Id" no-icon width="500"
                                         v-model="systemSettings.googleTagManagerId" rules="required"/>
                    </div>
                </AccordionTab>
                <AccordionTab>
                    <template #header>
                        <i class="pi pi-facebook"></i>
                        <div class="tab-header">Facebook</div>
                    </template>
                    <div class="row">
                        <div class="label">Facebook Id</div>
                        <CustomInputText name="facebookId" label="Facebook Id" no-icon width="500"
                                         v-model="systemSettings.facebookId" rules="required"/>
                    </div>
                </AccordionTab>
                <AccordionTab>
                    <template #header>
                        <i class="pi pi-th-large"></i>
                        <div class="tab-header">Pozostałe dane</div>
                    </template>
                    <div class="row">
                        <div class="label">Adres</div>
                        <CustomInputTextArea name="addressData" label="Adres" no-icon width="500"
                                             v-model="systemSettings.addressData" rules="required|addressLength"/>
                    </div>
                    <div class="row">
                        <div class="label">Email do powiadomień</div>
                        <CustomInputText name="systemInfoEmail" label="Email do powiadomień" no-icon width="500"
                                         v-model="systemSettings.systemInfoEmail" rules="required|email|emailLength"/>
                    </div>
                    <div class="row">
                        <div class="label">Email o nadużyciach</div>
                        <CustomInputText name="abuseInfoEmail" label="Email o nadużyciach" no-icon width="500"
                                         v-model="systemSettings.abuseInfoEmail" rules="required|email|emailLength"/>
                    </div>
                    <div class="row">
                        <div class="label">Telefon</div>
                        <CustomInputMask name="phoneNumber" label="Telefon" no-icon width="500" mask="(+48) 999 999 999"
                                         v-model="systemSettings.phoneNumber" rules="required|phoneNumber"/>
                    </div>
                    <div class="row">
                        <div class="label">Treść powiadomienia cookies</div>
                        <CustomInputTextArea name="cookiesPolicyText" label="Adres" no-icon width="500"
                                             v-model="systemSettings.cookiesPolicyText" rules="required"/>
                    </div>
                </AccordionTab>
                <AccordionTab>
                    <template #header>
                        <i class="pi pi-window-maximize"></i>
                        <div class="tab-header">Okno popup</div>
                    </template>
                    <div class="row">
                        <div class="label">
                            Pokazuj
                            <div>
                                <i align="middle" class="pi pi-question-circle ml-2"
                                   v-tooltip="'Jeśli to pole jest zaznaczone użytkownik zobaczy' +
                                       ' okno popup po wejściu na stronę główną'"/>
                            </div>
                        </div>
                        <CustomCheckbox name="displayText"
                                        v-model="systemSettings.popupSettings.displayPopup"/>
                    </div>
                    <div class="row">
                        <div class="label">Nagłówek</div>
                        <CustomInputText label="Wpisz treść nagłówka" :rules="popupTextRequired"
                                         name="popupHeaderRequired" no-icon width="500"
                                         v-model="systemSettings.popupSettings.popupHeader"/>
                    </div>
                    <div class="row">
                        <div class="label">Grafika (Opcjonalnie)</div>
                        <div class="image-upload-container">
                            <SettingsFileUpload :label="systemSettings.popupSettings.popupImageUrl ?
                                                    'Zmień Grafikę' : 'Dodaj Grafikę'"
                                                v-model="file" icon="pi pi-plus" main-class="panoramic"
                                                v-model:logo-url="systemSettings.popupSettings.popupImageUrl"
                                                :accepted-file-formats="acceptedFileFormats" name="logo-file"
                                                rules="size:1|image"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="label">Treść</div>
                        <CustomInputTextArea label="Wpisz treść powiadomienia" :rules="popupTextRequired"
                                             name="popupText" no-icon width="500"
                                             v-model="systemSettings.popupSettings.popupText"/>
                    </div>
                </AccordionTab>
                <AccordionTab>
                    <template #header>
                        <i class="pi pi-user"></i>
                        <div class="tab-header">Polityka prywatności</div>
                    </template>
                    <div class="row">
                        <CustomEditor name="content-editor" label="treść" v-model="systemSettings.privacyPolicy"
                                      rules="required" :height="800"/>
                    </div>
                </AccordionTab>
                <AccordionTab>
                    <template #header>
                        <i class="pi pi-user"></i>
                        <div class="tab-header">Regulamin</div>
                    </template>
                    <div class="row">
                        <CustomEditor name="content-editor" label="treść" v-model="systemSettings.termsOfUse"
                                      rules="required" :height="800"/>
                    </div>
                </AccordionTab>
            </Accordion>
            <SaveButton :disabled="isSubmitting" type="submit" on-bottom/>
        </Form>
    </div>
</template>

<script>
    import Toast from "primevue/toast";
    import Accordion from "primevue/accordion";
    import AccordionTab from "primevue/accordiontab";
    import Tooltip from "primevue/tooltip";
    import {Form} from "vee-validate";
    import {
        getSystemSettingsUsingGET as getSystemSettings,
        updateSystemParameterUsingPOST,
    } from "@/swagger/vue-api-client";
    import CustomInputText from "../../components/form/CustomInputText";
    import CustomTitle from "@/components/CustomTitle";
    import SaveButton from "@/components/form/SaveButton";
    import CustomInputTextArea from "@/components/form/CustomInputTextArea";
    import CustomInputMask from "@/components/form/CustomInputMask";
    import {ToastUtils} from "@/utils/ToastUtils";
    import CustomCheckbox from "@/components/form/CustomCheckbox";
    import SettingsFileUpload from "@/components/form/inner/SettingsFileUpload";
    import {FileUtils} from "@/utils/FileUtils";
    import CustomEditor from "@/components/form/CustomEditor";

    export default {
        name: "SystemSettingsView",

        components: {
            CustomEditor,
            SettingsFileUpload,
            Form,
            CustomCheckbox,
            SaveButton,
            CustomTitle,
            CustomInputText,
            CustomInputTextArea,
            Toast,
            Accordion,
            AccordionTab,
            CustomInputMask,
        },

        directives: {
            tooltip: Tooltip,
        },

        props: {
            value: {},
        },

        data() {
            return {
                systemSettings: {
                    addressData: null,
                    systemInfoEmail: null,
                    abuseInfoEmail: null,
                    googleAnalyticsId: null,
                    googleApiKey: null,
                    googleTagManagerId: null,
                    facebookId: null,
                    instagramName: null,
                    instagramAccessToken: null,
                    instagramClientId: null,
                    instagramClientSecret: null,
                    popupSettings: {
                        displayPopup: false,
                        popupHeader: null,
                        popupText: null,
                        popupImageUrl: null,
                    },
                    privacyPolicy: null,
                    termsOfUse: null,
                },
                loaded: false,
                activeIndex: null,
                file: undefined,
                acceptedFileFormats: FileUtils.acceptedImageFormats.map((val) => "." + val),
            };
        },

        beforeMount() {
            getSystemSettings()
                .then((response) => {
                    this.systemSettings = response.data;
                    this.loaded = true;
                })
                .catch((error) => console.log(error));
        },

        methods: {
            async updateSystemParameter() {
                if (this.file) {
                    this.systemSettings.popupSettings.popupImageFile = {};
                    this.systemSettings.popupSettings.popupImageFile.filename = this.file.name;
                    this.systemSettings.popupSettings.popupImageFile.name = this.file.name;
                    this.systemSettings.popupSettings.popupImageFile.encodedData = await FileUtils.fileToBase64(this.file);
                } else {
                    // this.systemSettings.popupSettings.popupImageFile = null;
                }

                await Promise.resolve(
                    updateSystemParameterUsingPOST(
                        {systemSettingsDto: this.systemSettings},
                    ),
                ).then(() => {
                    ToastUtils.showSuccess(this, "Zapisano konfigurację");
                }).catch(() => {
                    ToastUtils.showError(this);
                });
            },
        },

        computed: {
            popupTextRequired() {
                return (this.systemSettings.popupSettings.displayPopup === true ? "required|" : "") + "max:512";
            },
            popupHeaderRequired() {
                return (this.systemSettings.popupSettings.displayPopup === true ? "required|" : "") + "max:128";
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "../../assets/theme/variable.less";
    @import "src/views/assets/less/system-settings-view";
    .sys
    .tab-header {
        margin-left: 10px;
        font-size: 20px;
    }
    .row {
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-left: 60px;
        min-height: 70px;

        .label {
            display: flex;
            align-items: flex-start;
            margin-top: 10px;
            width: 250px;
            min-width: 250px;
            text-align: left;
            color: var(--color-gray3);
            font-size: 18px;
            margin-bottom: 20px;
        }

        .edit-value {
            color: @color-black;
            font-weight: normal;
            font-size: 25px;
            text-align: justify;
            text-justify: inter-word;
        }
    }
</style>
