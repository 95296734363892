<template>
    <div class="custom-multi-select">
        <MultiSelect :id="name" :filter="true" :options="items" :optionLabel="itemLabel" :optionValue="itemValue"
                     v-model="internalValue" :placeholder="internalValue === null ? '' : label" :loading="loading"
                     :scrollHeight="scrollHeight"/>
    </div>
</template>

<script>
    import MultiSelect from "primevue/multiselect";

    export default {
        name: "DesignedMultiSelect",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            itemLabel: {
                type: String,
            },
            itemValue: {
                type: String,
            },
            emptyValue: {
                type: Boolean,
                default: true,
            },
            items: {
                type: Array,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            modelValue: null,
            scrollHeight: {
                type: String,
                default: undefined,
            },
        },

        emits: ["update:modelValue"],

        data() {
            return {};
        },

        watch: {
            items() {
                if (this.emptyValue) {
                    return [{value: null, label: ""}, ...this.items];
                }
                return this.items;
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        components: {MultiSelect},
    };
</script>

<style lang="less">
@import "../../../assets/theme/variable";
.custom-multi-select {
    .p-multiselect {
        background-color: @color-white;
        color: @color-black;
        border-radius: 30px;
        padding: 1rem 1.5rem;
        box-shadow: rgba(0, 0, 0, 8%) 0 1px 2px, rgba(0, 0, 0, 5%) 0 4px 12px !important;
        border: 1px solid rgb(221, 221, 221);
        .p-multiselect-label {
            padding: 0;
        }
    }

    .p-multiselect:not(.p-disabled).p-focus {
        box-shadow: none;
    }

    .p-multiselect .p-multiselect-label {
        text-align: left;
    }
}
</style>
