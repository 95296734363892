/* eslint-disable */
import {DateUtils} from "@/utils/DateUtils";

export const EventUtils = {

    getTermLabel(event) {
        if (!event) {
            return "";
        }

        if ((event.hasOwnProperty("type") && event.type === "SINGLE_EVENT") ||
            (event.hasOwnProperty("periodical") && !event.periodical)) {

            return this.getTermForSingleEvent(event);

        } else if ((event.hasOwnProperty("type") && event.type === "PERIODICAL") ||
            (event.hasOwnProperty("periodical") && event.periodical)) {

            let periodicalStartDate = "";
            if (event.periodicalStartDate) {
                periodicalStartDate = periodicalStartDate.concat("Od ", DateUtils.displayDateOnlyInPolishFormat(event.periodicalStartDate), ", ");
            }

            //Nie powinno się zdarzyć, ale na wypadek błędów
            if (!event.startTime || !event.endTime) {
                return "";
            }

            return periodicalStartDate +
                "co tydzień, " + DateUtils.displayDayOfTheWeekByValue(event.dayOfTheWeek) + ", od "
                + event.startTime.substring(0, 5) + " do " + event.endTime.substring(0, 5);
        }
    },

    getTermForEventView: function (event) {
        if (!event) {
            return "";
        }

        if ((event.hasOwnProperty("type") && event.type === "SINGLE_EVENT") ||
            (event.hasOwnProperty("periodical") && !event.periodical)) {

            const startDate = new Date(event.eventStartDayTime);
            const endDate = new Date(event.eventEndDayTime);
            return DateUtils.displayDateOnlyInPolishFormat(startDate) + " " + DateUtils.getTimeOnly(startDate) +
                " - " + DateUtils.displayDateOnlyInPolishFormat(endDate) + " " + DateUtils.getTimeOnly(endDate);

        } else if ((event.hasOwnProperty("type") && event.type === "PERIODICAL") ||
            (event.hasOwnProperty("periodical") && event.periodical)) {

            let eventTerm = "Co tydzień ( " + DateUtils.displayDateOnlyInPolishFormat(event.periodicalStartDate) +
                " - " + DateUtils.displayDateOnlyInPolishFormat(event.periodicalEndDate) + " ):\n";
            event.calendarEntry.forEach((calendar) => {
                eventTerm += DateUtils.displayDayOfTheWeekByValue(calendar.dayWeek) + ", od "
                    + calendar.timeBegin + " do " + calendar.timeEnd + "\n";
            });

            return eventTerm;
        }
        return "";
    },

    getTermForSingleEvent(event) {
        const startDate = new Date(event.eventStartDayTime);
        const endDate = new Date(event.eventEndDayTime);
        return DateUtils.displayDateOnlyInPolishFormat(startDate) + " " + DateUtils.getTimeOnly(startDate) +
            " - " + DateUtils.displayDateOnlyInPolishFormat(endDate) + " " + DateUtils.getTimeOnly(endDate);
    }

}
