<template>
    <div class="organization-search-criteria flex" @keyup.enter="search">
        <CustomInputText name="name" label="Nazwa wydarzenia" v-model="internalValue.eventName" fluid width="250"/>
        <CustomInputText name="name" label="Nazwa organizacji" v-model="internalValue.organizationName" width="250"/>
    </div>
</template>

<script>
    import CustomInputText from "../../../components/form/CustomInputText";

    export default {
        name: "ImportedEventAcceptanceViewSearchCriteria",

        props: {
            modelValue: null,
        },

        methods: {
            search() {
                this.$emit("search");
            },
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        components: {CustomInputText},
    };
</script>

<style lang="less" scoped>

</style>
