<template>
    <div id="vue-app">
        <Toast/>
        <ConfirmDialog/>
        <router-view/>
        <div v-if="loaded">
            <Dialog :header="'Polityka plików cookies'" v-model:visible="cookiesDialogVisible"
                    :modal="true" :closable="false" :draggable="false">
                {{ cookiesPolicyText }}
                <template #footer>
                    <Button label="Akceptuj" icon="pi pi-check"
                            class="button button-filled" @click="hideCookiesDialog"/>
                </template>
            </Dialog>
        </div>
    </div>
</template>

<script>
    import Toast from "primevue/toast";
    import ConfirmDialog from "primevue/confirmdialog";
    import Dialog from "primevue/dialog";
    import Button from "primevue/button";
    import {getSystemSettingsCookiesPolicyTextUsingGET as getCookiesPolicyText} from "@/swagger/vue-api-client";
    import {ThemeSettingsHelper} from "@/mixin/themeSettings";

    export default {
        name: "LayoutSimpleLogo",
        components: {
            Dialog,
            Toast,
            ConfirmDialog,
            Button,
        },

        data() {
            return {
                cookiesDialogVisible: false,
                cookiesPolicyText: "",
                loaded: false,
            };
        },

        beforeCreate() {
            ThemeSettingsHelper.checkLocalStorage();
        },

        mounted() {
            if (localStorage.getItem("cookiesAccepted") == null
                || localStorage.getItem("cookiesAccepted") === "false") {
                this.getCookiesPolicy();
                localStorage.setItem("cookiesAccepted", "false");
                this.cookiesDialogVisible = true;
            }
        },

        methods: {
            hideCookiesDialog() {
                localStorage.setItem("cookiesAccepted", "true");
                this.cookiesDialogVisible = false;
            },

            getCookiesPolicy() {
                getCookiesPolicyText().then((response) => {
                    this.cookiesPolicyText = response.data.cookiesPolicyText;
                    this.loaded = true;
                }).catch((error) => console.log(error));
            },
        },

    };
</script>

<style lang="less">
    @import "assets/theme/global-styles";
</style>
