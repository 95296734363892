<template>
    <div class="search-page">
        <div class="search-page-header">
            <div class="search-page-header-text">
                <CustomTitle :title="Role.isAdmin() ? 'Znajdź aktywność' : 'Znajdź aktywność dla siebie'"/>
                <p>
                    Zgodnie z nowymi wytycznymi Światowej Organizacji Zdrowia (WHO), aby zrównoważyć
                    szkody wynikające z siedzącego trybu życia, potrzebna jest umiarkowana lub intensywna,
                    ale przede wszystkim regularna aktywność fizyczna. W naszej wyszukiwarce znajdziesz
                    zajęcia i wydarzenia sportowe w Twojej okolicy.
                </p>
            </div>
        </div>
        <div class="search-inner" >
            <div class="event-filters">
                <h3>{{totalActiveEvents}} aktywności w bazie.<br>
                    Uzupełnij kryteria i znajdź aktywność, której szukasz.</h3>
                <EventSearchCriteria ref="userSearchCriteria" v-model="searchCriteria"
                                     :user-prefs="computedUserPreferences" @search="search"/>
                <Button label="Szukaj" icon="pi pi-search" class="button button-filled" @click="search"/>
            </div>
            <EventsMap :events="events" @map-event-clicked="setHighlightedEventId"/>
            <div class="search-results" :style="'height:' + eventListHeight">
                <EventList :events="events" :highlightedEventId="highlightedEventId"
                           :totalRecords="searchTotal" @update:offset="updateOffset"
                           :rows-per-page="100"/>
            </div>
        </div>
        <div class="search-view-section-outer">
            <div class="paragraph search-view-section" v-if="Role.isAdmin()">
                <CustomTitle title="Wydarzenia"/>
                <div class="flex justify-content-center">
                    <Button class="button button-filled" type="button" label="Wszystkie wydarzenia"
                            @click="pushToEventEditionView"/>
                    <Button class="button button-filled" type="button" label="Wydarzenia do akceptacji:"
                            :key="numberOfImportedEvents"
                            :badge="numberOfImportedEvents.toString()" @click="pushToEventAcceptanceView"/>
                </div>
            </div>
            <div class="paragraph search-view-section" v-if="Role.isAdmin()">
                <CustomTitle title="Typy aktywności"/>
                <EventTypes @event-changed="triggerEventTypeFetch"/>
            </div>
            <div class="paragraph search-view-section" v-if="Role.isAdmin()">
                <CustomTitle title="Minione aktywności"/>
                <div class="general-container event-list-archive">
                    <EventList :events="archiveEvents" v-if="archiveEvents.length > 0" :rows-per-page="12"
                               :totalRecords="searchArchivedTotal" @update:offset="updateOffset"
                               :name="'archivedEvents'"/>
                    <div v-else>Brak minionych aktywności</div>
                </div>
            </div>
            <div class="paragraph search-view-section" v-if="Role.isAdmin()">
                <CustomTitle title="Zablokowane aktywności"/>
                <div class="general-container event-list-blocked">
                    <EventList :events="blockedEvents"  v-if="blockedEvents.length > 0" :rows-per-page="12"
                               :totalRecords="searchBlockedTotal" @update:offset="updateOffset"
                               :name="'blockedEvents'"/>
                    <div v-else>Brak zablokowanych aktywności</div>
                </div>
            </div>
        </div>
        <SearchViewPopup v-model:display-modal="showPopup" v-model:popupSettings="popupSettings"/>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import EventList from "@/views/event/components/EventList";
    import EventsMap from "@/views/event/components/EventsMap";
    import EventSearchCriteria from "@/views/event/components/EventSearchCriteria";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {SystemRole} from "@/utils/SystemRole";
    import CustomTitle from "@/components/CustomTitle";
    import EventTypes from "@/views/event/components/EventTypes";
    import {
        countArchivedEventsUsingPOST as countArchivedEvents,
        countBlockedEventsUsingPOST as countBlockedEvents,
        countEventsUsingPOST as countEvents,
        getArchivedEventsUsingPOST as getArchivedEvents,
        getBlockedEventsUsingPOST as getBlockedEvents,
        getPopupUsingGET as getPopup,
        getUserPreferencesUsingGET as getUserPreferences,
        searchEventsUsingPOST as searchEvents,
        searchImportedEventAcceptanceViewCountUsingPOST as countImportedEvents,
    } from "@/swagger/vue-api-client";
    import SearchViewPopup from "@/views/event/components/SearchViewPopup";
    import {CookiesUtils} from "@/utils/CookiesUtils";

    export default {
        name: "SearchView",
        components: {
            Button,
            EventTypes,
            CustomTitle,
            EventSearchCriteria,
            EventsMap,
            EventList,
            SearchViewPopup,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(100),
                blockedEventsSearchCriteria: this.getClearSearchCriteria(12),
                archivedEventsSearchCriteria: this.getClearSearchCriteria(12),
                Role: SystemRole,
                events: [],
                searchTotal: null,
                searchBlockedTotal: null,
                searchArchivedTotal: null,
                totalActiveEvents: null,
                archiveEvents: [],
                blockedEvents: [],
                showFilters: false,
                userPreferences: {
                    favoriteActivities: null,
                    userDistricts: null,
                },
                eventListHeight: "",
                showPopup: false,
                popupSettings: null,
                highlightedEventId: null,
                numberOfImportedEvents: 0,
                importedEventSearchCriteria: {
                    eventName: "",
                    organizationName: "",
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                },
            };
        },

        async mounted() {
            this.displayPopupIfNeeded();
            if (SystemRole.isUser()) {
                await this.getUserPrefs();
            }
            this.search(true);
            if (SystemRole.isAdmin()) {
                this.searchBlockedEvents();
                this.searchArchivedEvents();
                this.searchNumberOfImportedEvents();
            }
            this.computedEventListHeight();
        },

        watch: {
            "searchCriteria.page.offset": function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.search();
                }
            },
            "blockedEventsSearchCriteria.page.offset": function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.searchBlockedEvents();
                }
            },
            "archivedEventsSearchCriteria.page.offset": function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.searchArchivedEvents();
                }
            },
        },

        computed: {
            computedUserPreferences() {
                return this.userPreferences.favoriteActivities == null || this.userPreferences.userDistricts == null
                    ? null : this.userPreferences;
            },
        },

        methods: {
            pushToEventAcceptanceView() {
                this.$router.push({name: "importedEventAcceptanceListView"});
            },

            pushToEventEditionView() {
                this.$router.push({name: "eventEditionListView"});
            },

            triggerEventTypeFetch() {
                // eslint-disable-next-line no-unused-expressions
                this.$refs.userSearchCriteria.fetchEventTypes();
            },

            computedEventListHeight() {
                const headerHeight = document.getElementsByClassName("search-page-header")[0].clientHeight;
                this.eventListHeight = window.innerHeight - headerHeight;
                this.eventListHeight += "px";
            },
            displayPopupIfNeeded() {
                if (CookiesUtils.getCookie("popup_displayed") === "1") {
                    return;
                }
                getPopup().then(((response) => {
                    if (response.data && response.data.displayPopup === true) {
                        this.showPopup = true;
                        this.popupSettings = response.data;
                        CookiesUtils.setCookie("popup_displayed", 1, 7);
                    }
                }));
            },

            updateOffset(event) {
                switch (event.source) {
                    case "archivedEvents":
                        this.archivedEventsSearchCriteria.page.offset = event.first;
                        this.archivedEventsSearchCriteria.page.limit = event.rows;
                        this.archivedEventsSearchCriteria.page.sortField = event.sortField;
                        this.archivedEventsSearchCriteria.page.sortOrder = event.sortOrder;

                        break;
                    case "blockedEvents":
                        this.blockedEventsSearchCriteria.page.offset = event.first;
                        this.blockedEventsSearchCriteria.page.limit = event.rows;
                        this.blockedEventsSearchCriteria.page.sortField = event.sortField;
                        this.blockedEventsSearchCriteria.page.sortOrder = event.sortOrder;

                        break;
                    default:
                        this.searchCriteria.page.offset = event.first;
                        this.searchCriteria.page.limit = event.rows;
                        this.searchCriteria.page.sortField = event.sortField;
                        this.searchCriteria.page.sortOrder = event.sortOrder;

                        break;
                }
            },

            search(initial = false) {
                SearchCriteria.updateUrl(this);
                this.searchCriteria.eventTypesIds = this.$refs.userSearchCriteria
                    .$refs.designedMultiSelectEvents.eventsToSearch;
                if (this.searchCriteria === null) {
                    this.searchCriteria = this.getClearSearchCriteria(100);
                }
                searchEvents({eventSearchCriteria: this.searchCriteria}).then((response) => {
                    this.events = response.data;
                }).catch((error) => {
                    window.console.log(error);
                });
                countEvents({eventSearchCriteria: this.searchCriteria}).then((response) => {
                    this.searchTotal = response.data;
                    if (initial) {
                        this.totalActiveEvents = response.data;
                    }
                });
            },

            searchBlockedEvents() {
                getBlockedEvents({eventSearchCriteria: this.blockedEventsSearchCriteria}).then((response) => {
                    this.blockedEvents = response.data;
                });
                countBlockedEvents({eventSearchCriteria: this.blockedEventsSearchCriteria}).then((response) => {
                    this.searchBlockedTotal = response.data;
                });
            },

            searchArchivedEvents() {
                getArchivedEvents({eventSearchCriteria: this.archivedEventsSearchCriteria}).then((response) => {
                    this.archiveEvents = response.data;
                });
                countArchivedEvents({eventSearchCriteria: this.blockedEventsSearchCriteria}).then((response) => {
                    this.searchArchivedTotal = response.data;
                });
            },

            searchNumberOfImportedEvents() {
                countImportedEvents({searchCriteria: this.importedEventSearchCriteria}).then((response) => {
                    this.numberOfImportedEvents = response.data;
                }).catch((error) => window.console.log(error));
            },

            async getUserPrefs() {
                await getUserPreferences().then((response) => {
                    this.userPreferences.favoriteActivities = response.data.favoriteActivities;
                    this.userPreferences.userDistricts = response.data.userDistricts;
                });
            },

            createUser() {
                this.$router.push({name: "userCreate"});
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
            },

            getClearSearchCriteria(limit) {
                return {
                    districts: undefined,
                    phrase: undefined,
                    eventTypesIds: undefined,
                    dates: undefined,
                    page: {
                        // eslint-disable-next-line object-shorthand
                        limit: limit,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
            setHighlightedEventId(id) {
                this.highlightedEventId = id;
            },
        },
    };
</script>

<style lang="less">
    //noinspection CssUnknownTarget
    @import "@/views/assets/less/search-view.less";
    lord-icon {
        display: block;
        width: 35px;
        height: 35px;
    }
</style>
