<template>
    <div>
        <Dialog header="Zgłoś nadużycie" v-model:visible="internalShow" :modal="true">
            <Form @submit="sendReportClicked">
            <CustomInputTextarea label="Wpisz treść zgłoszenia" v-model="abuseInfo.abuseText"
                        rules="required|min:32|max:1000|badWords" name="abuse-text"/>
            <input type="submit" style="display: none" ref="reportAbuseButton">
            </Form>
            <template #footer>
                <Button label="Wyślij" class="button button-filled" icon="pi pi-send" @click="$refs.reportAbuseButton.click()"/>
                <Button label="Anuluj" class="button button-outlined" icon="pi pi-times" @click="closeModal"/>
            </template>
        </Dialog>
    </div>
</template>

<script>
    import Dialog from "primevue/dialog";
    import {Form} from "vee-validate";
    import {reportAbuseUsingPOST as reportAbuse} from "@/swagger/vue-api-client";
    import {ToastUtils} from "@/utils/ToastUtils";
    import CustomInputTextarea from "@/components/form/CustomInputTextArea";
    import Button from "primevue/button";

    export default {
        name: "ReportAbuseDialog",
        components: {CustomInputTextarea, Dialog, Form, Button},

        emits: ["update:displayModal"],

        props: {
            eventId: {
                type: Number,
            },
            displayModal: {
                type: Boolean,
                defaultValue: false,
            }
        },

        data() {
          return {
              abuseInfo: {
                  eventId: this.eventId,
                  abuseText: null,
                  captchaToken: null,
              }
          }
        },

        computed: {
            internalShow: {
                get() {
                    return this.displayModal;
                },
                set(value) {
                    this.$emit("update:displayModal", value);
                },
            },
        },

        methods: {
            closeModal() {
              this.internalShow = false;
            },
            sendReportClicked() {
                grecaptcha.ready(function() {
                    grecaptcha.execute(process.env.VUE_APP_CAPTCHA_KEY, {action: 'submit'}).then(function(token) {
                        this.setToken(token);
                        this.sendReport();
                    }.bind(this))
                }.bind(this));
            },
            sendReport() {
                reportAbuse({reportAbuseDto: this.abuseInfo}).then(() => {
                    this.closeModal();
                    this.abuseInfo.abuseText = null;
                    ToastUtils.showSuccess(this, "Twoje zgłoszenie zostało wysłane",
                        "Dziękujemy", 5000);
                }).catch((error) => {
                    ToastUtils.showError(this);
                })
            },
            setToken(token) {
                this.abuseInfo.captchaToken = token;
            },
        }

    }
</script>

<style scoped>

</style>
