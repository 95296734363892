import store from "../store";
/**
 * Uwaga zmienne, metody, watcher'y itp z tego pliku zostaną dodane do wszystkich komponentów w aplikacji
 */
/* eslint-disable */
export const ThemeSettings = {

    computed: {
        isNormalModeSelected() {
            return store.state.theme === ThemeSettingsHelper.normalMode
        },

        isDarkModeSelected() {
            return store.state.theme === ThemeSettingsHelper.darkMode;
        },

    }
}

/**
 * Metody pomocnicze, które nie mają być widoczne w komponentach
 */
export let ThemeSettingsHelper = {

    blackContrast: "black",
    yellowContrast: "yellow",
    normalContrast: "normal",
    normalFontSize: 0,
    bigFontSize: 1,
    // biggerFontSize: 2,
    fontSize: "fontSize",
    mode: "mode",

    setBlackContrastForAllApp() {
        localStorage.setItem(ThemeSettingsHelper.mode, ThemeSettingsHelper.blackContrast);
        document.getElementById("bodyapp").classList.remove("contrast-yellow");
        document.getElementById("bodyapp").classList.add("contrast-black");
        store.commit('setBlackContrast');
    },
    setYellowContrastForAllApp() {
        localStorage.setItem(ThemeSettingsHelper.mode, ThemeSettingsHelper.yellowContrast);
        document.getElementById("bodyapp").classList.remove("contrast-black");
        document.getElementById("bodyapp").classList.add("contrast-yellow");
        store.commit('setYellowContrast');
    },
    setNormalModeAnfFontSizeForAllApp() {
        localStorage.setItem(ThemeSettingsHelper.mode, ThemeSettingsHelper.normalContrast);
        document.getElementById("bodyapp").classList.remove("contrast-black");
        document.getElementById("bodyapp").classList.remove("contrast-yellow");
        store.commit('setNormalContrast');
        store.commit('setNormalFontSize');
    },
    increaseFontSize() {
        let currentFontSize = parseInt(document.getElementById("bodyapp")
            .getAttribute("data-font-size"), 10);
        if (currentFontSize < 1) {
            currentFontSize += 1;
            if (currentFontSize === 1) {
                localStorage.setItem(ThemeSettingsHelper.fontSize, currentFontSize);
                document.getElementById("bodyapp").setAttribute("data-font-size", currentFontSize);
                store.commit('setBigFontSize');
            }
            /* if (currentFontSize === 2) {
                localStorage.setItem(ThemeSettingsHelper.fontSize, currentFontSize);
                document.getElementById("bodyapp").setAttribute("data-font-size", currentFontSize);
                store.commit('setBiggerFontSize');
            }
             */
        }
    },
    decreaseFontSize() {
        let currentFontSize = parseInt(document.getElementById("bodyapp")
            .getAttribute("data-font-size"), 10);
        if (currentFontSize > 0) {
            currentFontSize -= 1;
            /* if (currentFontSize === 1) {
                localStorage.setItem(ThemeSettingsHelper.fontSize, currentFontSize);
                document.getElementById("bodyapp").setAttribute("data-font-size", currentFontSize);
                store.commit('setBigFontSize');
            }
             */
            if (currentFontSize === 0) {
                localStorage.setItem(ThemeSettingsHelper.fontSize, currentFontSize);
                document.getElementById("bodyapp").setAttribute("data-font-size", currentFontSize);
                store.commit('setNormalFontSize');
            }
        }
    },
    checkLocalStorageMode() {
        const mode = localStorage.getItem(ThemeSettingsHelper.mode);
        if (mode === ThemeSettingsHelper.blackContrast) {
            ThemeSettingsHelper.setBlackContrastForAllApp();
            return;
        }
        if (mode === ThemeSettingsHelper.yellowContrast) {
            ThemeSettingsHelper.setYellowContrastForAllApp();
            return;
        }
    },
    checkLocalStorageFontSize() {
        const fontSize = parseInt(localStorage.getItem(ThemeSettingsHelper.fontSize));
        if (fontSize === ThemeSettingsHelper.normalFontSize) {
            document.getElementById("bodyapp").setAttribute("data-font-size", fontSize);
            store.commit('setNormalFontSize');
            return;
        }
        if (fontSize === ThemeSettingsHelper.bigFontSize) {
            document.getElementById("bodyapp").setAttribute("data-font-size", fontSize);
            store.commit('setBigFontSize');
            return;
        }
        /* if (fontSize === ThemeSettingsHelper.biggerFontSize) {
            document.getElementById("bodyapp").setAttribute("data-font-size", fontSize);
            store.commit('setBigggerFontSize');
            return;
        }
         */
    },
    checkLocalStorage() {
        this.checkLocalStorageMode();
        this.checkLocalStorageFontSize();
    }
}
