<template>
    <div class="tile" :class="classes" :id="'eventTile_' + event.id">
        <div class="tile-inner" @click="goToEvent" >
            <div class="img-outer">
                <img :alt="'Tło' + event.id" class="background event" loading="lazy"
                     :src="event.coverImageUrl ? event.coverImageUrl : altPic">
                <div class="darkening"/>
                <div class="darkening-background"/>
                <div class="header">{{event.name}}</div>
            </div>
            <div class="details">
                <div class="item"><span class="calendar-icon event-icon"></span><span>{{getTermLabel()}}</span></div>
                <div class="item">
                    <span class="pin-icon event-icon"></span>
                    <span>{{event.district === 'ONLINE' ? 'ONLINE' : event.address}}</span>
                </div>
                <div class="button-box">
                    <Button class="button button-white" label="Czytaj dalej" @click="goToEvent" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import {SystemRole} from "@/utils/SystemRole";
    import {EventUtils} from "@/utils/EventUtils";

    export default {
        name: "Event",
        components: {
            Button,
        },

        data() {
            return {
                SystemRole,
                // eslint-disable-next-line
                altPic: require("@/assets/notfoundphoto.png"),
            };
        },

        props: {
            event: {
                type: Object,
            },
            highlight: {
                type: Boolean,
                default: false,
            },
        },

        methods: {
            goToEvent() {
                if (SystemRole.isAdmin()) {
                    this.$router.push({name: "eventEdit", params: {id: this.event.id}});
                } else if (SystemRole.isOrganizer()
                    && this.event.organizationId === parseInt(localStorage.getItem("organizationId"), 10)) {
                    this.$router.push({name: "eventEdit", params: {id: this.event.id}});
                } else {
                    this.$router.push({name: "event", params: {id: this.event.id}});
                }
            },
            getTermLabel() {
                return EventUtils.getTermLabel(this.event);
            },
        },

        computed: {
            classes() {
                return this.highlight ? "highlight" : "";
            },
        },

    };
</script>

<style lang="less" scoped>
    @import "../../assets/less/image-tile";
    .promoted {
        position: absolute;
        top: 3%;
        right: 4%;
        max-height: fit-content;
        transition: all 1s;
        color: white;
    }
    .tile.highlight {
        transition: 1s ease-out;
        border-radius: 18px;
        border-color: @secondary-color;
        border-width: 2px;
        border-style: solid;
    }
</style>
