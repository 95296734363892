<template>
    <div class="event-search-criteria">
        <div class="event-search-criteria-row" @keyup.enter="emitSearch">
            <CustomInputText id="phraseSearch" name="phraseSearch" icon="pi pi-search"
                             v-model="internalValue.phrase" label="czego szukasz?"
                             icon-position="right"/>
            <DesignedMultiSelectEvents id="events" name="events" label="Dyscypliny"
                                       v-model="internalValue.eventTypesIds"
                                       :user-prefs="computedUserEvents" scroll-height="350px"
                                       ref="designedMultiSelectEvents"/>
            <CustomCalendar id="calendar" name="date-picker" label="Data"
                            v-model="internalValue.dates" selection-mode="range"
                            :min-date="minDate" icon="pi pi-calendar"
                            :showButtonBar="true"/>
            <DesignedMultiSelectDistrict id="district" name="district" label="Dzielnica"
                                         v-model="internalValue.districts" scroll-height="350px"
                                         :user-prefs="computedUserDistricts"/>
        </div>
    </div>
</template>

<script>
    import DesignedMultiSelectDistrict
        from "../../../components/form/multiselect/DesignedMultiSelectDistrict";
    import DesignedMultiSelectEvents from "@/components/form/multiselect/DesignedMultiSelectEvents";
    import CustomCalendar from "@/components/form/inner/CustomCalendar";
    import CustomInputText from "@/components/form/CustomInputText";

    export default {
        name: "EventSearchCriteria",

        data() {
            return {
                minDate: new Date(),
            };
        },

        props: {
            modelValue: null,
            userPrefs: {
                type: Object,
                default: null,
                required: false,
            },
        },

        emits: ["update:modelValue", "search"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
            computedUserEvents() {
                return this.userPrefs == null ? null : this.userPrefs.favoriteActivities;
            },
            computedUserDistricts() {
                return this.userPrefs == null ? null : this.userPrefs.userDistricts;
            },
        },

        methods: {
            emitSearch() {
                this.$emit("search");
            },

            fetchEventTypes() {
                // eslint-disable-next-line no-unused-expressions
                this.$refs.designedMultiSelectEvents.fetchEventTypes();
            },
        },

        components: {
            CustomInputText,
            CustomCalendar,
            DesignedMultiSelectEvents,
            DesignedMultiSelectDistrict,
        },
    };
</script>

<style lang="less">

@import "@/assets/theme/variable.less";

.event-search-criteria {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    .p-multiselect {
        width: 100%;
    }
    .event-search-criteria-row {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .event-search-criteria-row.centered {
        justify-content: center;
    }
}
</style>
