<template>
    <div class="title-container">
        <h1 class="title-text">{{title}}</h1>
    </div>
</template>

<script>
    export default {
        name: "CustomTitle",

        props: {
            title: {
                type: String,
            },
        },
    };
</script>

<style lang="less" scoped>
@import "../assets/theme/variable";

.title-text {
    font-size: 25px;
    font-weight: bold;
}
</style>
