import moment from "moment";

/* eslint-disable */
export const DateUtils = {
    defaultDateFormat: "YYYY-MM-DD",
    defaultDateTimeFormat: "YYYY-MM-DD HH:mm:ss",
    timestampFormat: "YYYY-MM-DD-HH-mm-ss",
    dateFormat: "YYYY-MM-DD",
    defaultTimeOnlyFormat: "HH:mm",
    polishDateFormat: "DD MMMM YYYY",
    nameOfTheWeek: "dddd",

    daysOfTheWeek: [
        {value: "MONDAY", labelPlural: "poniedziałki", labelSingular: "poniedziałek", dayOfTheWeek: 1},
        {value: "TUESDAY", labelPlural: "wtorki", labelSingular: "wtorek", dayOfTheWeek: 2},
        {value: "WEDNESDAY", labelPlural: "środy", labelSingular: "środa", dayOfTheWeek: 3},
        {value: "THURSDAY", labelPlural: "czwartki", labelSingular: "czwartek", dayOfTheWeek: 4},
        {value: "FRIDAY", labelPlural: "piątki", labelSingular: "piątek", dayOfTheWeek: 5},
        {value: "SATURDAY", labelPlural: "soboty", labelSingular: "sobota", dayOfTheWeek: 6},
        {value: "SUNDAY", labelPlural: "niedziele", labelSingular: "niedziela", dayOfTheWeek: 7},
    ],

    datesToStrings(dates, format = this.defaultDateTimeFormat) {
        return dates.map((d) => moment(d).format(format));
    },

    dateToString(date, format) {
        return moment(date).format(format);
    },

    displayDateTime(dateString) {
        return moment(dateString).format(this.defaultDateTimeFormat);
    },

    displayDate(dateString) {
        return moment(dateString).format(this.dateFormat);
    },

    isInPast(dateString) {
        return moment().isSameOrAfter(dateString);
    },

    isInFuture(dateString) {
        return moment().isSameOrBefore(dateString);
    },

    dateAsString(date) {
        return moment(date).format(this.dateFormat);
    },

    getTimeOnly(date) {
        return moment(date).format(this.defaultTimeOnlyFormat);
    },

    displayDateOnlyInPolishFormat(date) {
        return moment(date).locale('pl').format(this.polishDateFormat)
    },

    displayDayOfTheWeek(numberOfDay) {
        return moment(numberOfDay).locale('pl').format('nameOfTheWeek');
    },

    displayDayOfTheWeekByValue(val) {
        if (!val) {
            return "";
        }
        return DateUtils.daysOfTheWeek.find(day => day.value === val).labelSingular;
    }

};
