<template>
    <div class="event-types-outer general-container">
        <div class="event-types-outer-header">
            <h3>Widoczne</h3>
            <div class="event-type add-event-type">
                <div class="event-type-inner event">
                    Dodaj typ aktywności
                    <Button icon="pi pi-plus" class="p-button-rounded p-button-secondary"
                            @click="openAddOrEditEventDialog" aria-label="Dodaj"/>
                </div>
            </div>
        </div>
        <div class="event-types">
            <div v-for="(type) of shownActivities" v-bind:key="type.id" class="event-type">
                <div class="event-type-inner event">
                    {{type.name}}
                    <div class="event-type-buttons">
                        <Button style="margin-right: 5px;" icon="pi pi-table" class="p-button-rounded p-button-info"
                                @click="openGroupEventDialog(type)" aria-label="Dodaj typ aktywności do kategorii"
                                v-tooltip.top="'Grupuj aktywność'"/>
                        <Button style="margin-right: 5px;" icon="pi pi-pencil" class="p-button-rounded"
                                @click="openAddOrEditEventDialog(type)" aria-label="Edytuj typ aktywności"
                                v-tooltip.top="'Edytuj aktywność'"/>
                        <Button icon="pi pi-eye-slash" class="p-button-rounded" @click="hideOrShowEvent(type.id)"
                                aria-label="Pokaż albo ukryj" v-tooltip.top="'Ukryj aktywność'"/>
                    </div>
                </div>
            </div>
            <div class="event-type add-event-type">
                <div class="event-type-inner event">
                    Dodaj typ aktywności
                    <Button icon="pi pi-plus" class="p-button-rounded p-button-secondary"
                            @click="openAddOrEditEventDialog" aria-label="Dodaj typ aktywności"/>
                </div>
            </div>
        </div>

        <br>
        <div class="event-types-outer-header">
            <h3>Ukryte</h3>
        </div>
        <div class="event-types">
            <div v-for="(type) of hiddenActivities" v-bind:key="type.id" class="event-type event-type-hidden">
                <div class="event-type-inner event">
                    {{type.name}}
                    <div class="event-type-buttons">
                        <Button style="margin-right: 5px;" icon="pi pi-table" class="p-button-rounded p-button-info"
                                @click="openGroupEventDialog(type)"
                                v-tooltip.top="'Grupuj aktywność'"/>
                        <Button style="margin-right: 5px;" icon="pi pi-pencil" class="p-button-rounded p-button-info"
                                @click="openAddOrEditEventDialog(type)"
                                v-tooltip.top="'Edytuj aktywność'"/>
                        <Button icon="pi pi-eye" class="p-button-rounded p-button-info"
                                @click="hideOrShowEvent(type.id)" v-tooltip.top="'Pokaż aktywność'"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="event-types-outer-header">
            <CustomTitle title="Kategorie aktywności"/>
            <div class="event-type add-event-type">
                <div class="event-type-inner event">
                    Dodaj typ kategorii
                    <Button icon="pi pi-plus" class="p-button-rounded p-button-secondary"
                            @click="openAddOrEditEventCategoryDialog" aria-label="Dodaj"/>
                </div>
            </div>
        </div>
        <div class="event-types">
            <div v-for="(type) of eventCategory" v-bind:key="type.id" class="event-type">
                <div class="event-type-inner event">
                    {{type.name}}
                    <div class="event-type-buttons">
                        <Button style="margin-right: 5px;" icon="pi pi-table" class="p-button-rounded p-button-info"
                                @click="openGroupEventDialog(type)"
                                v-tooltip.top="'Dodaj aktywności'"/>
                        <Button style="margin-right: 5px;" icon="pi pi-pencil" class="p-button-rounded p-button-info"
                                @click="openAddOrEditEventCategoryDialog(type)"
                                v-tooltip.top="'Edytuj kategorię'"/>
                        <Button style="margin-right: 5px;" icon="pi pi-minus" class="p-button-rounded p-button-info"
                                @click="deleteEventCategoryDialog(type.id, type)"
                                v-tooltip.top="'Usuń kategorię'"/>
                    </div>
                </div>
            </div>
            <div class="event-type add-event-type">
                <div class="event-type-inner event">
                    Dodaj typ kategorii
                    <Button icon="pi pi-plus" class="p-button-rounded p-button-secondary"
                            @click="openAddOrEditEventCategoryDialog" aria-label="Dodaj"/>
                </div>
            </div>
        </div>

        <div>
            <Dialog :header="computedHeaderForEvent"
                    v-model:visible="displayAddOrEditEventDialog" :draggable="false">
                <Form @submit="addOrEditEvent" v-slot="{ isSubmitting }">
                    <div class="p-grid form-wrapper">
                        <div class="p-col-12">
                            <CustomInputText name="name" v-model="eventTypeData.name"
                                             label="Nazwa aktywności"
                                             rules="required|eventTypeNameLength"
                                             no-icon/>
                        </div>
                    </div>
                    <div style="text-align: right;">
                        <Button label="Zapisz" icon="pi pi-save"
                                type="submit" :disabled="isSubmitting" />
                    </div>
                </Form>
            </Dialog>
        </div>
        <div>
            <Dialog :header="computedHeaderForCategory"
                    v-model:visible="displayAddOrEditEventCategoryDialog" :draggable="false">
                <Form @submit="addOrEditEventTypeCategory" v-slot="{ isSubmitting }">
                    <div class="p-grid form-wrapper">
                        <div class="p-col-12">
                            <CustomInputText name="name" v-model="eventTypeData.name"
                                             label="Nazwa kategorii"
                                             rules="required|eventTypeNameLength"
                                             no-icon/>
                        </div>
                    </div>
                    <div style="text-align: right;">
                        <Button label="Zapisz" icon="pi pi-save"
                                type="submit" :disabled="isSubmitting" />
                    </div>
                </Form>
            </Dialog>
        </div>
        <div>
            <Dialog header="Przydziel aktywność do kategorii"
                    v-model:visible="displayGroupEventDialog" :draggable="false">
                <Form @submit="addEventToCategory(eventTypeData.id)" v-slot="{ isSubmitting }">
                    <div class="p-grid form-wrapper">
                        <div class="p-col-12 header font-bold">
                            {{eventTypeData.name}}
                        </div>
                    </div>
                    <DesignedSelectOne v-if="isEventChosen" id="EventsOrCategories"
                                       name="EventsOrCategories" label="Kategoria"
                                       item-label="name" item-value="id"
                                       :empty-value="false" :items="eventCategory"
                                       width="350" v-model="selectedCategory"/>
                    <DesignedMultiSelect v-else id="EventsOrCategories"
                                         name="EventsOrCategories" label="Aktywność"
                                         item-label="name" item-value="id"
                                         :empty-value="false"
                                         :items="[...shownActivities, ...hiddenActivities]"
                                         scroll-height="300px"
                                         width="350" v-model="selectedEvents"/>
                    <div style="text-align: right;">
                        <Button label="Zapisz" icon="pi pi-save"
                                type="submit" :disabled="isSubmitting" />
                    </div>
                </Form>
            </Dialog>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Tooltip from "primevue/tooltip";
    import Dialog from "primevue/dialog";
    import {Form} from "vee-validate";
    import {SystemRole} from "@/utils/SystemRole";
    import CustomInputText from "@/components/form/CustomInputText";
    import CustomTitle from "@/components/CustomTitle";
    import DesignedMultiSelect from "@/components/form/inner/DesignedMultiSelect";
    import DesignedSelectOne from "@/components/form/inner/DesignedSelectOne";
    import {
        createOrUpdateEventTypeUsingPOST as addOrEditEventType,
        eventTypeExistsUsingGET as eventTypeExists,
        getAllEventTypesUsingGET as getAllEventTypes,
        hideOrShowEventTypeUsingPOST as hideOrShowEventType,
        eventTypeCategoryExistsUsingGET as eventTypeCategoryExists,
        createOrUpdateEventTypeCategoryUsingPOST as addOrEditEventTypeCategory,
        deleteEventTypeCategoryUsingDELETE as deleteEventTypeCategory,
        getAllEventTypeCategoriesUsingGET as getAllEventTypeCategories,
        addEventTypeToCategoryUsingPOST as addEventToCategory,
    } from "@/swagger/vue-api-client";

    export default {
        name: "EventTypes",
        components: {
            Button,
            Dialog,
            CustomInputText,
            Form,
            CustomTitle,
            DesignedMultiSelect,
            DesignedSelectOne,
        },

        emits: ["event-changed"],

        data() {
            return {
                loading: false,
                totalRecords: 0,
                Role: SystemRole,
                shownActivities: [],
                hiddenActivities: [],
                eventCategory: [],
                displayAddOrEditEventCategoryDialog: false,
                displayGroupEventDialog: false,
                displayAddOrEditEventDialog: false,
                selectedCategory: false,
                selectedEvents: [],
                eventTypeData: {
                    id: null,
                    name: null,
                    hidden: false,
                },
                eventTypeStartingName: null,
                isEventChosen: false,
            };
        },

        directives: {
            tooltip: Tooltip,
        },

        beforeMount() {
            this.fetchActivities();
        },

        computed: {
            computedHeaderForEvent() {
                return this.eventTypeStartingName == null ? "Dodaj nowy typ aktywności" : "Edytuj typ aktywności";
            },

            computedHeaderForCategory() {
                return this.eventTypeStartingName == null ? "Dodaj nową kategorię" : "Edytuj kategorię";
            },
        },

        methods: {
            fetchActivities() {
                getAllEventTypes().then((response) => {
                    this.shownActivities = response.data.filter((activity) => activity.hidden === false);
                    this.hiddenActivities = response.data.filter((activity) => activity.hidden === true);
                });

                getAllEventTypeCategories().then((response) => {
                    this.eventCategory = response.data;
                });
            },

            hideOrShowEvent(id) {
                hideOrShowEventType({id}).then(() => {
                    this.fetchActivities();
                    this.$toast.add({
                        severity: "success",
                        summary: "Sukces",
                        detail: "Pomyślnie zmieniono widoćzność aktywności",
                        life: 3000,
                    });
                }).catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: "Nie udało się ukryć aktywności",
                        life: 3000,
                    });
                });
            },

            async addOrEditEvent(value, actions) {
                let valid = true;
                await eventTypeExists({name: this.eventTypeData.name})
                    .then((response) => {
                        if (response.data) {
                            actions.setFieldError("name", "Istnieje już aktywność o takiej nazwie");
                            valid = false;
                        }
                    }).catch(() => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd w walidacji nazwy aktywności",
                            detail: "Jeżeli będzie się powtarzał skontaktuj się z administratorem",
                            life: 3000,
                        });
                        valid = false;
                    });

                if (valid === false) {
                    return;
                }

                addOrEditEventType({eventTypeDto: this.eventTypeData}).then(() => {
                    this.closeAddOrEditEventDialog();
                    this.fetchActivities();
                    this.$toast.add({
                        severity: "success",
                        summary: "Sukces",
                        detail: "Pomyślnie zapisano aktywność",
                        life: 3000,
                    });
                }).catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: "Nie udało się zapisać aktywności",
                        life: 3000,
                    });
                });
            },

            async addOrEditEventTypeCategory(value, actions) {
                let valid = true;
                await eventTypeCategoryExists({name: this.eventTypeData.name})
                    .then((response) => {
                        if (response.data) {
                            actions.setFieldError("name", "Istnieje już aktywność o takiej nazwie");
                            valid = false;
                        }
                    }).catch(() => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd w walidacji nazwy aktywności",
                            detail: "Jeżeli będzie się powtarzał skontaktuj się z administratorem",
                            life: 3000,
                        });
                        valid = false;
                    });

                if (valid === false) {
                    return;
                }

                addOrEditEventTypeCategory({eventTypeCategoryDto: this.eventTypeData}).then(() => {
                    this.closeAddOrEditCategoryDialog();
                    this.fetchActivities();
                    this.$toast.add({
                        severity: "success",
                        summary: "Sukces",
                        detail: "Pomyślnie zapisano aktywność",
                        life: 3000,
                    });
                }).catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: "Nie udało się zapisać aktywności",
                        life: 3000,
                    });
                });
            },

            async addEventToCategory(dataId) {
                let categoryId;
                let eventList = new Set();
                if (this.isEventChosen) {
                    categoryId = this.selectedCategory;

                    eventList = this.shownActivities
                        .filter((activity) => activity.eventTypeCategory !== null
                            && activity.eventTypeCategory.id === categoryId)
                        .map((activity) => activity.id);
                    eventList = [...eventList, ...this.hiddenActivities
                        .filter((activity) => activity.eventTypeCategory !== null
                            && activity.eventTypeCategory.id === categoryId)
                        .map((activity) => activity.id)];

                    eventList.push(dataId);
                } else {
                    categoryId = dataId;
                    eventList = this.selectedEvents;
                }

                addEventToCategory({categoryId, eventsId: eventList}).then(() => {
                    this.closeAddEventToCategoryDialog();
                    this.fetchActivities();
                    this.$emit("event-changed");
                    this.$toast.add({
                        severity: "success",
                        summary: "Sukces",
                        detail: "Pomyślnie dodano aktywność do kategorii",
                        life: 3000,
                    });
                }).catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: "Nie udało się dodać aktywności do kategorii",
                        life: 3000,
                    });
                });
            },

            openAddOrEditEventDialog(eventType) {
                this.eventTypeData.id = eventType.id;
                this.eventTypeData.name = eventType.name;
                this.eventTypeStartingName = eventType.name;
                this.displayAddOrEditEventDialog = true;
            },

            openAddOrEditEventCategoryDialog(eventType) {
                this.eventTypeData.id = eventType.id;
                this.eventTypeData.name = eventType.name;
                this.eventTypeStartingName = eventType.name;
                this.displayAddOrEditEventCategoryDialog = true;
            },

            openGroupEventDialog(item) {
                this.isEventChosen = !this.eventCategory.includes(item);
                this.eventTypeData.id = item.id;
                this.eventTypeData.name = item.name;
                this.eventTypeStartingName = "Dodaj aktywność do kategorii";
                if (this.isEventChosen) {
                    if (item.eventTypeCategory !== null) {
                        this.selectedCategory = item.eventTypeCategory.id;
                        item = item.eventTypeCategory;
                    } else {
                        this.selectedCategory = null;
                    }
                } else {
                    this.selectedEvents = this.shownActivities
                        .filter((activity) => activity.eventTypeCategory !== null
                            && activity.eventTypeCategory.id === item.id)
                        .map((activity) => activity.id);
                    this.selectedEvents = [...this.selectedEvents, ...this.hiddenActivities
                        .filter((activity) => activity.eventTypeCategory !== null
                            && activity.eventTypeCategory.id === item.id)
                        .map((activity) => activity.id)];
                }
                this.displayGroupEventDialog = true;
            },

            closeAddOrEditEventDialog() {
                this.displayAddOrEditEventDialog = false;
            },

            closeAddOrEditCategoryDialog() {
                this.displayAddOrEditEventCategoryDialog = false;
            },

            closeAddEventToCategoryDialog() {
                this.displayGroupEventDialog = false;
            },

            async deleteEventCategoryDialog(id, event) {
                this.$confirm.require({
                    target: event.currentTarget,
                    header: "Czy na pewno chcesz usunąć wydarzenie?",
                    message: "Tej operacji nie będzie można cofnąć.",
                    acceptLabel: "Tak",
                    rejectLabel: "Nie",
                    accept: () => {
                        deleteEventTypeCategory({id}).then(() => {
                            this.fetchActivities();
                            this.$toast.add({
                                severity: "success",
                                summary: "Sukces",
                                detail: "Pomyślnie usunięto kategorię",
                                life: 3000,
                            });
                            this.$router.push("/");
                        }).catch((error) => {
                            if (error.response.status === 500) {
                                this.$toast.add({
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił błąd po stronie serwera. Skontaktuj się z administratorem",
                                    life: 3000,
                                });
                            } else {
                                this.$toast.add({
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd",
                                    life: 3000,
                                });
                            }
                        });
                    },
                });
            },
        },
    };
</script>

<style lang="less" scoped>
@import "../../../assets/theme/variable";
.header {
    font-size: 20px;
}
.event-types-outer {
    margin: 0 auto;
    .event-types-outer-header {
        border-bottom: 1px solid #ddd;
        padding-bottom: 15px;
        display: flex;
        justify-content: center;
        position: relative;
        margin-top: 23px;
        .add-event-type {
            position: absolute;
            right: 0;
            top: -10px
        }
    }
    .add-event-type {
        width: 33.3%;
        .event-type-inner {
            background-color: @primary-color;
            color: @color-white;
            .p-button.p-button-icon-only.p-button-rounded {
                background-color: @color-white;
                color: @primary-color;
                margin-left: 10px;
            }
        }
    }
    .event-type {
        flex: 0 0 33.3%;
        .event-type-inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // background-color: @secondary-color;
            border: 1px solid @primary-color;
        }
    }
    .event {
            border-radius: 15px;
            padding: 12px;
            margin: 4px
    }
    .event-types {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;

        .event-type-hidden {
            .event {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: var(--color-dark-gray);
            }
        }

        .event-type-buttons {
            display: inline-block;
            white-space: nowrap;
            margin-left: 20px;
        }
    }
    @media screen and (max-width: @media-breakpoint-down-lg) {
        .add-event-type {
            width: 33.3%;
        }
        .event-type {
            flex: 0 0 50%;
        }
    }
    @media screen and (max-width: @media-breakpoint-down-md) {
        .event-types-outer-header {
            flex-direction: column;
            align-items: center;
            .add-event-type {
                width: 50%;
                position: initial;
            }
            h3 {
                order: 2;
            }
        }
        .event-type {
            flex: 0 0 50%;
        }
    }
    @media screen and (max-width: @media-breakpoint-down-sm) {
        .event-types {
            padding: 15px;
        }
        .add-event-type {
            width: 50%;
        }
        .event-type {
            flex: 0 0 100%;
        }
    }
    @media screen and (max-width: @media-breakpoint-down-xs) {
        .add-event-type {
            width: 100%;
        }
        .event-type {
            flex: 0 0 100%;
        }
    }
}
.contrast-yellow {
    .add-event-type {
        .event-type-inner {
            background-color: @color-black;
            .p-button.p-button-icon-only.p-button-rounded {
                color: @color-black;
            }
        }
    }
}
.contrast-black {
    .add-event-type {
        .event-type-inner {
            background-color: @color-yellow;
            color: @color-black;
            .p-button.p-button-icon-only.p-button-rounded {
                background-color: @color-black;
            }
        }
    }
}
</style>
